import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes, PublicRoutes, PublicTicketsSubRoutes, urlPath } from '@remberg/global/ui';
import {
  AuthGuardService,
  forceRerenderResolver,
  InitializationGuard,
  NotFoundGuardService,
  useCasesOrTickets2Guard,
} from '@remberg/ui-core/core';
import {
  PageNotFoundComponent,
  UpdateRequiredComponent,
} from '@remberg/ui-core/utility-pages-shared';

const publicRoutes: Routes = [
  {
    path: PublicRoutes.ASSETS,
    loadChildren: () =>
      import('@remberg/assets/ui/assets-main-public').then((m) => m.AssetsPublicModule),
  },
  {
    // delete after tickets2 migration
    path: PublicRoutes.REQUEST,
    canMatch: [useCasesOrTickets2Guard({ isPublic: true, isTickets2: false })],
    loadChildren: () =>
      import('@remberg/tickets/ui/tickets-main-public').then((m) => m.TicketsPublicModule),
  },
  {
    // delete `useCasesTickets2Guard` guard file after tickets2 migration
    // use `canMatch` with correct feature flag after tickets2 migration
    path: PublicRoutes.REQUEST,
    canMatch: [useCasesOrTickets2Guard({ isPublic: true, isTickets2: true })],
    // canMatch: [hasAccessGuard({ featureFlags: [FeatureFlagEnum.TICKETS] })],
    loadChildren: () =>
      import('@remberg/tickets2/ui/main-public').then((m) => m.Tickets2PublicModule),
  },
  {
    // backwards compatibility for older email links
    path: `${PublicRoutes.CASES}/${PublicTicketsSubRoutes.DETAIL}/:caseId`,
    redirectTo: ({ params }): string => `${PublicRoutes.REQUEST}/detail/${params['caseId']}`,
  },
  {
    path: `${PublicRoutes.TICKETS}/${PublicTicketsSubRoutes.DETAIL}/:ticketId`,
    redirectTo: ({ params }): string => `${PublicRoutes.REQUEST}/detail/${params['ticketId']}`,
  },
  {
    path: '**',
    redirectTo: urlPath([MainRoutes.PORTAL, MainRoutes.LOGIN]),
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: MainRoutes.WELCOME,
    pathMatch: 'full',
  },
  {
    path: MainRoutes.PUBLIC,
    children: publicRoutes,
  },
  {
    path: '',
    loadChildren: () => import('@remberg/ui-core/landing-shell').then((m) => m.LANDING_ROUTES),
  },
  {
    path: MainRoutes.PORTAL,
    loadChildren: () =>
      import('@remberg/ui-core/landing-shell').then((m) => m.LANDING_PORTAL_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('@remberg/ui-core/navigation-shell').then((m) => m.NAVIGATION_SHELL_ROUTES),
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
  },
  // Utility Routes
  { path: MainRoutes.UPDATE_REQUIRED, component: UpdateRequiredComponent },
  { path: MainRoutes.NOT_FOUND, pathMatch: 'full', component: PageNotFoundComponent },
  // catch all remaining routes (logged in)
  {
    path: '**',
    canActivate: [NotFoundGuardService],
    component: PageNotFoundComponent,
    pathMatch: 'full',
  },
  // catch all remaining routes (not logged in)
  { path: '**', redirectTo: MainRoutes.LOGIN, pathMatch: 'full' },
];

// in order to perform mobile migrations before any route is loaded,
// we must have a parent rootRoute with a guard that delays further navigation until the
// migration is complete. It also takes care of the appState initialization
const rootRoute: Routes = [
  {
    path: '',
    canActivate: [InitializationGuard],
    children: routes,
    resolve: { forceRerender: forceRerenderResolver },
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(rootRoute, {
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
