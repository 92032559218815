import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import {
  Subscription,
  combineLatest,
  delay,
  filter,
  firstValueFrom,
  map,
  tap,
  withLatestFrom,
} from 'rxjs';
import { InfiniteListContainerStore } from './infinite-list-container.store';

@Component({
  selector: 'app-infinite-list-container',
  templateUrl: './infinite-list-container.component.html',
  styleUrls: ['./infinite-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteListContainerComponent<T> implements OnInit, OnDestroy {
  @Input() public disableFetching: boolean = false;
  @Input() public templateCacheSize: number = 20;
  @Input() public trackByFn: TrackByFunction<T> = (index: number, item: T): number => index;
  @Input() protected batchSize: number = 5;
  @Input() protected isFullScreen: boolean = false;
  @Input() protected itemSize: number = 50;
  @Input() protected containerMaxHeight?: number;

  @ViewChild(CdkVirtualScrollViewport)
  private viewport?: CdkVirtualScrollViewport;

  @ContentChild(TemplateRef)
  protected templateRef = null;

  protected dataSource$ = this.infiniteListContainerStore.dataSource$;
  protected itemSize$ = this.infiniteListContainerStore.itemSize$;
  protected totalCount$ = this.infiniteListContainerStore.totalCount$;
  protected isLoading$ = this.infiniteListContainerStore.isLoading$;
  protected currentBatch$ = this.infiniteListContainerStore.currentBatch$;
  protected fetchNextItems$ = this.infiniteListContainerStore.totalCount$;
  protected virtualScrollHeight$ = combineLatest([
    this.infiniteListContainerStore.batchSize$,
    this.itemSize$,
    this.dataSource$,
  ]).pipe(
    map(([batchSize, itemSize, dataSource]) => {
      const containerMinHeight = dataSource.length * itemSize;
      if (this.isFullScreen) return Math.min(window.innerHeight, containerMinHeight);
      if (dataSource.length < batchSize) return containerMinHeight;
      return this.containerMaxHeight ?? `${batchSize * itemSize}`;
    }),
  );
  private readonly subscriptions = new Subscription();

  // If a user zooms out, dispatch a loading call to fill up the rest of the viewport
  @HostListener('window:resize', ['$event'])
  public async resize(): Promise<void> {
    const [totalCount, isLoading, currentBatch] = await firstValueFrom(
      combineLatest([
        this.infiniteListContainerStore.totalCount$,
        this.infiniteListContainerStore.isLoading$,
        this.infiniteListContainerStore.currentBatch$,
      ]),
    );
    this.viewport?.checkViewportSize();
    this.handleFetch(currentBatch, totalCount, isLoading);
  }

  constructor(protected readonly infiniteListContainerStore: InfiniteListContainerStore<T>) {}

  public ngOnInit(): void {
    this.infiniteListContainerStore.initialize$({
      batchSize: this.batchSize,
      itemSize: this.itemSize,
      disableFetching: this.disableFetching,
    });
    // This subscription is necessary to make sure that if the user's viewport
    // is larger than the amount of items fetched by the specified batchSize,
    // another set of items is fetched to fill the missing space.
    this.subscriptions.add(
      this.infiniteListContainerStore.dataSource$
        .pipe(
          delay(100),
          withLatestFrom(
            this.infiniteListContainerStore.totalCount$,
            this.infiniteListContainerStore.isLoading$,
            this.infiniteListContainerStore.currentBatch$,
          ),
          filter(([, , isLoading]) => !isLoading),
          tap(([, totalCount, , currentBatch]) => {
            this.viewport?.checkViewportSize();
            this.handleFetch(currentBatch, totalCount, false);
          }),
        )
        .subscribe(),
    );
  }

  protected async fetchNextBatch(): Promise<void> {
    const totalCount = await firstValueFrom(this.totalCount$);
    const isLoading = await firstValueFrom(this.isLoading$);
    const currentBatch = await firstValueFrom(this.currentBatch$);
    // Handle scenario in which
    this.handleFetch(currentBatch, totalCount, isLoading);
  }

  protected handleFetch(currentBatch: number, totalCount: number, isLoading: boolean): void {
    if (!this.viewport) return;

    const totalFetchedItems = this.viewport.getDataLength();
    const renderedRange = this.viewport.getRenderedRange().end;

    // Handle scenario in which some items are missing
    if (currentBatch * this.batchSize >= totalCount) return;

    if (totalCount === totalFetchedItems || renderedRange < totalFetchedItems || isLoading) return;

    this.infiniteListContainerStore.dispatchLoading$();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
