import { ActivityFieldTypeEnum } from '@remberg/activities/common/main';

export enum Ticket2SpecificFieldTypeEnum {
  STATUS = 'status',
  PRIORITY = 'priority',
  SUMMARY = 'summary',
  RELATED_PARTS = 'relatedParts',
  AI_KNOWLEDGE_BASE_STATUS = 'aiKnowledgeBaseStatus',
}

export type Ticket2ActivityFieldTypeEnum = ActivityFieldTypeEnum | Ticket2SpecificFieldTypeEnum;
