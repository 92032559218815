import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TrackingCategoryEnum, TrackingEventEnum } from '@remberg/analytics/common/main';
import { AnalyticsTrackingServiceInterface, TRACKING_SERVICE } from '@remberg/analytics/ui/clients';
import {
  GlobalSelectors,
  IntercomService,
  RootGlobalState,
  RouterSelectors,
} from '@remberg/ui-core/core';
import { firstValueFrom } from 'rxjs';
import { SanitizeUrlPipe } from '../../pipes/sanitize-url.pipe';

@Component({
  selector: 'app-feature-disabled',
  templateUrl: './feature-disabled.component.html',
  styleUrls: ['./feature-disabled.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, SanitizeUrlPipe],
})
export class FeatureDisabledComponent {
  protected readonly isIntercomInitialized$ = this.store.select(
    GlobalSelectors.selectIsIntercomInitialized,
  );

  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly intercomService: IntercomService,
    @Inject(TRACKING_SERVICE)
    private readonly analyticsTrackingService: AnalyticsTrackingServiceInterface,
  ) {}

  protected async onContactUsClick(): Promise<void> {
    await this.intercomService.showIntercom();

    const routeData = await firstValueFrom(this.store.select(RouterSelectors.selectRouteData));
    const trackingEvent: TrackingEventEnum | undefined =
      routeData?.['requestIntendedTrackingEvent'];
    if (trackingEvent) {
      void this.analyticsTrackingService.trackEvent(trackingEvent, {
        category: TrackingCategoryEnum.ACCOUNT_SETTINGS,
      });
    }
  }
}
