/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createAction, props } from '@ngrx/store';

export const createSharedTableActionsFor = <T extends { _id: string }, P, C = void>(
  identifier: string,
) => ({
  initialize: createAction(
    `[${identifier} Shared Table] Initialize`,
    props<{
      id: string; // T enum;
      params?: P;
      config?: C;
    }>(),
  ),
  updateParams: createAction(
    `[${identifier} Shared Table] Update table params`,
    props<{
      id: string;
      params?: Partial<P>;
    }>(),
  ),
  refresh: createAction(
    `[${identifier} Shared Table] Refresh`,
    props<{
      id: string;
    }>(),
  ),
  load: createAction(
    `[${identifier} Shared Table] Load`,
    props<{
      id: string;
      params?: P;
    }>(),
  ),
  loadSuccess: createAction(
    `[${identifier} Shared Table] LoadSuccess`,
    props<{
      id: string;
      params?: P;
      items: T[];
      count: number;
    }>(),
  ),
  loadError: createAction(
    `[${identifier} Shared Table] LoadError`,
    props<{
      id: string;
      error: string;
    }>(),
  ),
  selectOne: createAction(
    `[${identifier} Shared Table] Select One`,
    props<{
      id: string;
      itemId: string;
    }>(),
  ),
  toggleAllSelectedItems: createAction(
    `[${identifier} Shared Table] Toggle All Selected Items`,
    props<{
      id: string;
    }>(),
  ),
  toggleSelectedItem: createAction(
    `[${identifier} Shared Table] Toggle Selected Item`,
    props<{
      id: string;
      itemId: string;
    }>(),
  ),
  clearSelectedItems: createAction(
    `[${identifier} Shared Table] Clear Selected Items`,
    props<{
      id: string;
    }>(),
  ),
});
