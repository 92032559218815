export enum FormInstanceTableColumnEnum {
  STATUS = 'status',
  FORM_TEMPLATE_ID = 'formTemplateId',
  FORM_TEMPLATE_VERSION_ID = 'formTemplateVersionId',
  RELATED_ASSET_IDS = 'relatedAssetIds',
  RELATED_ORGANIZATION_IDS = 'relatedOrganizationIds',
  CREATED_BY_ID = 'createdById',
  ASSIGNEE_ID = 'assigneeId',
  UPDATED_BY_ID = 'updatedById',
  DATE_MODIFIED = 'dateModified',
  FINALIZED_AT = 'finalizedAt',
  COUNTER = 'counter',
  NAME = 'name',
  RELATED_WORK_ORDER_ID_2 = 'relatedWorkOrderId2',
  PDF_LANGUAGE = 'pdfLanguage',
  CREATED_AT = 'createdAt',
  CREATION_TYPE = 'creationType',
}
