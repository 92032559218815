export enum Ticket2ActivityFieldEnum {
  SUBJECT = 'subject',
  STATUS = 'status',
  ASSIGNED_CONTACT = 'assignedContact',
  ASSIGNED_USER_GROUP = 'assignedUserGroup',
  PRIORITY = 'priority',
  SOLUTION = 'solution',
  CUSTOM_PROPERTY_VALUES = 'customPropertyValues',
  CATEGORY = 'category',
  IS_MOVE_PREVENTED = 'isMovePrevented',
  IS_MOVE_UNDO_PREVENTED = 'isMoveUndoPrevented',
  SUMMARY = 'summary',
  FOLLOWERS = 'followers',
  COUNTER = 'counter',
  RELATED_ORGANIZATIONS = 'relatedOrganizations',
  RELATED_ASSETS = 'relatedAssets',
  RELATED_PARTS = 'relatedParts',
  PORTAL_ACCESS_ORGANIZATIONS = 'portalAccessOrganizations',
  CONTACTS = 'contactIds',
  TICKETS2 = 'tickets2',
  SUPPORT_EMAIL_ADDRESS = 'supportEmailAddress',
  AI_KNOWLEDGE_BASE_STATUS = 'aiKnowledgeBaseStatus',
}
