import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate', standalone: true })
export class TruncatePipe implements PipeTransform {
  public transform(value: string, limit: number, endLimit: number = 0): string {
    if (value.length <= limit) {
      return value;
    }

    const ellipsisLength = 3;
    const leftLimit = limit - ellipsisLength - endLimit; // make space for ellipsis and suffix

    return value.slice(0, leftLimit) + '...' + value.slice(value.length - endLimit, value.length);
  }
}
