<div
  class="hide-gt-sm my-mat-form-field-outline mat-form-field-outline mat-form-field-wrapper"
  attr.data-test-id="mobile-{{ dataTestId }}"
>
  <div class="select-wrapper" (click)="openDialogSelect()">
    <div class="content-wrapper">
      <mat-label
        class="label"
        attr.data-test-id="label-{{ selectedOption?.identifier }}"
        [ngClass]="{ 'label-selected': !selectedOption }"
      >
        {{ selectedOption?.label ?? fieldLabel }}
      </mat-label>
      <mat-icon class="arrow-icon" aria-hidden="false" aria-label="right arrow"
        >arrow_right</mat-icon
      >
    </div>
  </div>
</div>

<mat-form-field
  appearance="outline"
  class="hide-lt-md my-mat-form-field my-mat-form-field-outline my-mat-form-field-narrow"
  attr.data-test-id="{{ dataTestId }}"
>
  <mat-label>{{ fieldLabel }}</mat-label>
  <mat-select
    [value]="selectedOption?.identifier"
    attr.data-test-id="value-select-{{ selectedOption?.identifier }}"
    disableOptionCentering
    [panelClass]="'dropdown-select-panel-set-width'"
    (selectionChange)="onOptionChanged.emit($event.value)"
  >
    @for (option of options; track option) {
      <mat-option
        [value]="option.identifier"
        attr.data-test-id="option-key-{{ option.identifier }}"
      >
        {{ option.label }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
