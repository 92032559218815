import { IsoLanguageCodesEnum } from '@remberg/global/common/core';

export enum FormTemplateOfflinePopulateEnum {
  FORM_TEMPLATE_VERSION = 'formTemplateVersion',
  IN_PROGRESS_COUNT = 'inProgressCount',
}

export const FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX = 'name_';

export type FormTemplateTableColumnName =
  `${typeof FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${IsoLanguageCodesEnum}`;
