import {
  endOfDay as endOfDayFns,
  endOfMonth as endOfMonthFns,
  endOfWeek as endOfWeekFns,
  startOfDay as startOfDayFns,
  startOfMonth as startOfMonthFns,
  startOfWeek as startOfWeekFns,
  StartOfWeekOptions,
} from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { TimeZoneEnum } from '../enums/timezone.enum';

function wrapTimeZone(date: Date, tz: TimeZoneEnum, fn: (d: Date) => Date): Date {
  return fromZonedTime(fn(toZonedTime(date, tz)), tz);
}

export function startOfDay(localDate: Date, tz?: TimeZoneEnum): Date {
  return tz ? wrapTimeZone(localDate, tz, startOfDayFns) : startOfDayFns(localDate);
}

export function endOfDay(localDate: Date, tz?: TimeZoneEnum): Date {
  return tz ? wrapTimeZone(localDate, tz, endOfDayFns) : endOfDayFns(localDate);
}

const START_OF_WEEK_OPTIONS: StartOfWeekOptions = { weekStartsOn: 1 };

export function startOfWeek(localDate: Date, tz?: TimeZoneEnum): Date {
  return tz
    ? wrapTimeZone(localDate, tz, (date) => startOfWeekFns(date, START_OF_WEEK_OPTIONS))
    : startOfWeekFns(localDate, START_OF_WEEK_OPTIONS);
}

export function endOfWeek(localDate: Date, tz?: TimeZoneEnum): Date {
  return tz
    ? wrapTimeZone(localDate, tz, (date) => endOfWeekFns(date, START_OF_WEEK_OPTIONS))
    : endOfWeekFns(localDate, START_OF_WEEK_OPTIONS);
}

export function startOfMonth(localDate: Date, tz?: TimeZoneEnum): Date {
  return tz ? wrapTimeZone(localDate, tz, startOfMonthFns) : startOfMonthFns(localDate);
}

export function endOfMonth(localDate: Date, tz?: TimeZoneEnum): Date {
  return tz ? wrapTimeZone(localDate, tz, endOfMonthFns) : endOfMonthFns(localDate);
}
