import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';
import { updateRecordsWithSpecifiedProperties } from './updateRecordsWithSpecifiedProperties';

export async function migrateToV32(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V32...');

  await migrateOrganizationsAddEmptyCustomTagsArray(db, logger);
  await migrateWorkOrdersRenameExternalReferenceToReference(db, logger);
  await migrateAssetsRenameExternalReferenceToReference(db, logger);

  await db.executeSql('PRAGMA user_version = 32;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V32.');
  return;
}

async function migrateOrganizationsAddEmptyCustomTagsArray(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  await updateRecordsWithSpecifiedProperties(db, logger, SyncDataTypesEnum.ORGANIZATIONS, {
    customTagIds: [],
  });
}

async function migrateWorkOrdersRenameExternalReferenceToReference(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  await renameExternalReferenceToReference(db, logger, SyncDataTypesEnum.WORKORDERS2);
}

async function migrateAssetsRenameExternalReferenceToReference(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  await renameExternalReferenceToReference(db, logger, SyncDataTypesEnum.ASSETS2);
}

async function renameExternalReferenceToReference(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  dataType: SyncDataTypesEnum,
): Promise<void> {
  const tableExistsQuery = await db.executeSql(
    `SELECT name FROM sqlite_master WHERE type='table' AND name='${dataType}';`,
    [],
  );

  if (tableExistsQuery.rows?.item(0)?.name !== dataType) {
    logger.error()(
      `[updateRecordsWithSpecifiedProperties]: Specified SyncDataTypesEnum: ${dataType} not found`,
    );
    return;
  }

  logger.debug()(`[updateRecordsWithSpecifiedProperties]: Found ${dataType} table`);
  const numOfRecordsToMigrate =
    (await db.executeSql(`SELECT COUNT(_id) FROM ${dataType}`, []))?.rows.item(0)?.['COUNT(_id)'] ??
    0;

  if (numOfRecordsToMigrate === 0) {
    logger.warn()(
      `[updateRecordsWithSpecifiedProperties]: No records found for migration; Aborting!`,
    );
    return;
  }

  logger.debug()(
    `[updateRecordsWithSpecifiedProperties]: Number of records to migrate: ${numOfRecordsToMigrate}`,
  );
  const batchSize = 100;
  const numBatches = Math.ceil(numOfRecordsToMigrate / batchSize);

  for (let i = 0; i < numBatches; i++) {
    const records = await db.executeSql(
      `SELECT * FROM ${dataType} LIMIT ${batchSize} OFFSET ${i * batchSize}`,
      [],
    );

    const updatePromises = [];
    for (let x = 0; x < records.rows.length; x++) {
      const record = JSON.parse(records.rows.item(x).instance);
      let hasChanged = false;

      if (record.customPropertyValues?.length) {
        for (const customProperty of record.customPropertyValues) {
          customProperty.reference = customProperty.externalReference;
          delete customProperty.externalReference;
          hasChanged = true;
        }
      }

      if (hasChanged) {
        updatePromises.push(
          db.executeSql(`UPDATE ${dataType} SET instance = ? WHERE _id = ?`, [
            JSON.stringify(record),
            record._id,
          ]),
        );
      }
    }

    await Promise.all(updatePromises);
  }
}
