<div class="header-wrapper">
  <div>
    <mat-icon class="mat-icon notranslate mat-icon-no-color icon-primary">{{
      headerIcon
    }}</mat-icon>
  </div>
</div>

<div>
  @if (headerText) {
    <div>
      <h2 class="header-text">
        {{ headerText }}
      </h2>
    </div>
  }
</div>

<div>
  @if (contentText) {
    <div>
      <p class="content-text">
        {{ contentText }}
      </p>
    </div>
  }
  @if (htmlContent) {
    <div [innerHTML]="htmlContent | safeHtml" class="html-content"></div>
  }
</div>

<div class="checkbox-item-list">
  @for (checkboxItem of checkboxItems; track checkboxItem) {
    <div>
      <mat-checkbox
        class="mat-checkbox-top-vertical-align checkbox-item"
        color="accent"
        [id]="checkboxItem.id"
        [(ngModel)]="checkboxItem.isChecked"
      >
        {{ checkboxItem.text }}
      </mat-checkbox>
    </div>
  }
</div>

<div class="buttons-container">
  @if (cancelButtonText) {
    <button mat-stroked-button color="accent" (click)="close()" data-test-id="dialog-cancel-button">
      {{ cancelButtonText }}
    </button>
  }
  @if (confirmButtonText) {
    <button
      class="button-confirm"
      color="primary"
      mat-raised-button
      (click)="confirm()"
      cdkFocusInitial
      data-test-id="dialog-confirm-button"
    >
      @if (confirmButtonIcon) {
        <mat-icon>{{ confirmButtonIcon }}</mat-icon>
      }
      {{ confirmButtonText }}
    </button>
  }
</div>

@if (footerText) {
  <div class="footer-container">
    @if (footerIcon) {
      <mat-icon class="material-icons-filled footer-icon">{{ footerIcon }}</mat-icon>
    }
    <p class="footer-text">{{ footerText }}</p>
  </div>
}
