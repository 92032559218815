import { MaintenancePlanPopulateEnum } from './enums';

export const MAINTENANCE_PLAN_FIND_ONE_POPULATED_FIELDS = [
  MaintenancePlanPopulateEnum.WORK_ORDER_TEMPLATE_STATUS,
  MaintenancePlanPopulateEnum.WORK_ORDER_TEMPLATE_TYPE,
  MaintenancePlanPopulateEnum.WORK_ORDER_TEMPLATE_RESPONSIBLE_CONTACT,
  MaintenancePlanPopulateEnum.WORK_ORDER_TEMPLATE_RESPONSIBLE_GROUP,
  MaintenancePlanPopulateEnum.WORK_ORDER_TEMPLATE_ASSIGNED_CONTACTS,
  MaintenancePlanPopulateEnum.WORK_ORDER_TEMPLATE_ASSIGNED_GROUPS,
  MaintenancePlanPopulateEnum.ASSOCIATIONS_RELATED_ASSETS,
  MaintenancePlanPopulateEnum.ASSOCIATIONS_RELATED_CONTACTS,
  MaintenancePlanPopulateEnum.ASSOCIATIONS_RELATED_ORGANIZATIONS,
  MaintenancePlanPopulateEnum.ASSOCIATIONS_LAST_GENERATED_WORK_ORDER,
  MaintenancePlanPopulateEnum.CREATED_BY,
  MaintenancePlanPopulateEnum.UPDATED_BY,
];
