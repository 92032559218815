import { Injectable } from '@angular/core';
import { Actions, CreateEffectMetadata, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, MemoizedSelector, Store } from '@ngrx/store';
import { LocalStorageKeys, LogService } from '@remberg/global/ui';
import isEqual from 'lodash/isEqual';
import { Observable, firstValueFrom, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, scan, tap } from 'rxjs/operators';
import { AppStateService } from '../services/app-state.service';
import { GlobalActions } from '../store/global/global.actions';

export type SelectorLocalStorageKeyPairs<T> = {
  key: LocalStorageKeys;
  selector: MemoizedSelector<T, any, unknown>;
}[];

@Injectable({
  providedIn: 'root',
})
export class PersistedStateService<T> {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: LogService,
    private readonly appState: AppStateService,
    private readonly store: Store<T>,
  ) {}

  public registerPersistSubscriptions(keyPairs: SelectorLocalStorageKeyPairs<T>): void {
    this.getChangeEventsStream(keyPairs)
      .pipe(
        mergeMap(async ([localStorageKey, updatedValue]) => {
          try {
            await this.persistSingleChangedValue(localStorageKey, updatedValue);
            this.store.dispatch(
              GlobalActions.statePropertyPersisted({ localStorageKey, updatedValue }),
            );
          } catch (error) {
            this.logger.error()('Error persisting state', error);
          }
        }),
      )
      .subscribe();
  }

  public createPersistedStateEffect(
    action: ActionCreator,
    entries: SelectorLocalStorageKeyPairs<T>,
  ): Observable<number> & CreateEffectMetadata {
    return createEffect(
      () =>
        this.actions$.pipe(
          ofType(action),
          scan((acc) => acc + 1, 0),
          tap((count) => {
            if (count > 1) {
              this.logger.error()(`Received more than one ${action.name} action!`);
              return;
            }
            this.registerPersistSubscriptions(entries);
          }),
        ),
      { dispatch: false },
    );
  }

  /**
   *
   * @param selectorLocalStorageKeyPairs pairs of LocalStorageKeys and selectors
   * @returns an observable that emits a new pair of a changed value together with the associated LocalStorageKey whenever one of the selectors changes its value
   */
  private getChangeEventsStream(
    selectorLocalStorageKeyPairs: SelectorLocalStorageKeyPairs<T>,
  ): Observable<readonly [LocalStorageKeys, unknown]> {
    const observables = selectorLocalStorageKeyPairs.map(({ key, selector }) =>
      this.store.select(selector).pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        map((value) => [key, value] as const),
      ),
    );
    return merge(...observables);
  }

  private async persistSingleChangedValue(
    localStorageKey: LocalStorageKeys,
    updatedValue: unknown,
  ): Promise<[LocalStorageKeys, unknown]> {
    await this.appState.setValue(localStorageKey, this.stringifyValue(updatedValue));
    return [localStorageKey, updatedValue];
  }

  private stringifyValue(value: unknown): string {
    return value !== undefined && value !== null
      ? typeof value === 'object'
        ? JSON.stringify(value)
        : String(value)
      : '';
  }
}

export async function awaitPropertyPersisted(
  key: LocalStorageKeys,
  value: unknown,
  actions$: Actions,
): Promise<void> {
  await firstValueFrom(
    actions$.pipe(
      ofType(GlobalActions.statePropertyPersisted),
      filter((action) => key === action.localStorageKey && isEqual(value, action.updatedValue)),
    ),
  );
}
