import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';
import { EXPANSION_PANEL_ANIMATION_TIMING } from '@angular/material/expansion';

export const getFadeInAnimation = (triggerName: string): AnimationTriggerMetadata =>
  trigger(triggerName, [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(EXPANSION_PANEL_ANIMATION_TIMING, style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(EXPANSION_PANEL_ANIMATION_TIMING, style({ opacity: 0 })),
    ]),
  ]);

export const getBottomUpSlideInAnimation = (triggerName: string): AnimationTriggerMetadata =>
  trigger(triggerName, [
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate(EXPANSION_PANEL_ANIMATION_TIMING, style({ transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0)' }),
      animate(EXPANSION_PANEL_ANIMATION_TIMING, style({ transform: 'translateY(100%)' })),
    ]),
  ]);
