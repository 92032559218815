import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { MaintenancePlanBasic } from '@remberg/work-orders/common/base';
import {
  MaintenancePlan,
  MaintenancePlanAssociationsUpdateBody,
  MaintenancePlanCreateOneBody,
  MaintenancePlanCreateOneResponse,
  MaintenancePlanFindManyByIdsBody,
  MaintenancePlanFindManyQuery,
  MaintenancePlanFindManyResponse,
  MaintenancePlanFindOneQuery,
  MaintenancePlanPopulateEnum,
  MaintenancePlanScheduling,
  MaintenancePlanUpdateOnePropertiesBody,
  MaintenancePlanUpdateOneTemplateBody,
} from '@remberg/work-orders/common/main';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenancePlanService {
  private readonly MAINTENANCE_PLAN_URL = `${API_URL_PLACEHOLDER}/maintenance-plans/v2`;

  constructor(private readonly http: HttpClient) {}

  public findOnePopulated(id: string): Observable<MaintenancePlan<true>> {
    let params = new HttpParams();
    for (const p of Object.values(MaintenancePlanPopulateEnum)) {
      params = params.append('populate', p);
    }

    return this.http.get<MaintenancePlan<true>>(`${this.MAINTENANCE_PLAN_URL}/${id}`, { params });
  }

  public findOne(id: string, options: MaintenancePlanFindOneQuery): Observable<MaintenancePlan> {
    let params = new HttpParams();

    for (const p of options?.populate || []) {
      params = params.append('populate', p);
    }

    return this.http.get<MaintenancePlan>(`${this.MAINTENANCE_PLAN_URL}/${id}`, { params });
  }

  public findMany(
    options?: MaintenancePlanFindManyQuery,
  ): Observable<MaintenancePlanFindManyResponse> {
    let params = new HttpParams();

    if (options?.page !== undefined) {
      params = params.set('page', String(options.page));
    }
    if (options?.limit !== undefined) {
      params = params.set('limit', String(options.limit));
    }
    if (options?.sortDirection !== undefined) {
      params = params.set('sortDirection', options.sortDirection);
    }
    if (options?.sortField !== undefined) {
      params = params.set('sortField', options.sortField);
    }
    if (options?.search !== undefined) {
      params = params.set('search', options.search);
    }
    if (options?.filterObject !== undefined) {
      params = params.set('filterObject', JSON.stringify(options.filterObject));
    }
    if (options?.staticFilters?.length) {
      params = params.set('staticFilters', JSON.stringify(options.staticFilters));
    }
    for (const p of options?.populate || []) {
      params = params.append('populate', p);
    }

    return this.http.get<MaintenancePlanFindManyResponse>(this.MAINTENANCE_PLAN_URL, {
      params,
    });
  }

  public findManyBasicByIds(
    body: MaintenancePlanFindManyByIdsBody,
  ): Observable<MaintenancePlanBasic[]> {
    return this.http.put<MaintenancePlanBasic[]>(`${this.MAINTENANCE_PLAN_URL}`, body);
  }

  public deleteOne(id: string): Observable<void> {
    return this.http.delete<void>(`${this.MAINTENANCE_PLAN_URL}/${id}`);
  }

  public create(body: MaintenancePlanCreateOneBody): Observable<MaintenancePlanCreateOneResponse> {
    return this.http.post<MaintenancePlanCreateOneResponse>(this.MAINTENANCE_PLAN_URL, body);
  }

  public updateOneProperties(
    id: string,
    body: MaintenancePlanUpdateOnePropertiesBody,
  ): Observable<MaintenancePlan<true>> {
    return this.http.patch<MaintenancePlan<true>>(`${this.MAINTENANCE_PLAN_URL}/${id}`, body);
  }

  public updateOneTemplate(
    id: string,
    body: MaintenancePlanUpdateOneTemplateBody,
  ): Observable<MaintenancePlan<true>> {
    return this.http.patch<MaintenancePlan<true>>(
      `${this.MAINTENANCE_PLAN_URL}/${id}/template`,
      body,
    );
  }
  public updateOneAssociations(
    id: string,
    body: MaintenancePlanAssociationsUpdateBody,
  ): Observable<MaintenancePlan<true>> {
    return this.http.patch<MaintenancePlan<true>>(
      `${this.MAINTENANCE_PLAN_URL}/${id}/associations`,
      body,
    );
  }

  public updateOneScheduling(
    id: string,
    body: MaintenancePlanScheduling,
  ): Observable<MaintenancePlan<true>> {
    return this.http.patch<MaintenancePlan<true>>(
      `${this.MAINTENANCE_PLAN_URL}/${id}/schedule`,
      body,
    );
  }
}
