<div class="translation-flags">
  @for (countryCode of countryCodes; track countryCode) {
    <img
      class="flag"
      [src]="MAP_ISO_LANGUAGE_TO_FLAG_SVG[countryCode]"
      [class.faded]="!translations?.[countryCode]"
      [matTooltip]="translations?.[countryCode] ?? ''"
      [matTooltipDisabled]="!translations?.[countryCode]"
    />
  }
</div>
