import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomTag, CustomTagModelEnum } from '@remberg/custom-tags/common/main';
import {
  CustomTagRawOffline,
  CustomTagsOfflineServiceInterface,
  mapCustomTagRawOfflineToCustomTag,
} from '@remberg/custom-tags/ui/clients';
import { LogService, SQLQueryParams, SyncDataTypesEnum } from '@remberg/global/ui';
import { RootGlobalState } from '../../store/core-ui.definitions';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

const PARAMS: SQLQueryParams = {
  idString: '_id',
  tableName: SyncDataTypesEnum.CUSTOMTAGS,
};

@Injectable()
export class CustomTagssOfflineService
  extends BaseOfflineService<CustomTagRawOffline, never>
  implements CustomTagsOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, PARAMS, logger, store);
  }

  public async findAll(model: CustomTagModelEnum): Promise<CustomTag[]> {
    const customTags = await this.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      `model = '${model}'`,
    );
    return mapCustomTagRawOfflineToCustomTag(customTags);
  }
}
