import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { assertDefined, createUnknownObject, UnknownOr } from '@remberg/global/common/core';
import {
  BaseModel,
  getStringID,
  isObjectID,
  LogService,
  OfflineService,
  SyncDataTypesEnum,
} from '@remberg/global/ui';

export const dataTypeToServiceMap: {
  [dataType in SyncDataTypesEnum]: OfflineService<
    BaseModel,
    AdvancedFilterQuery<string>,
    AdvancedFilter<string>
  >;
} = {} as any;

export async function populateArray(
  dataType: SyncDataTypesEnum,
  arrayValue: string[] | BaseModel[] | (string | BaseModel)[] | undefined,
  shouldFallbackToUnknownObject: boolean,
  logger: LogService,
): Promise<UnknownOr<BaseModel>[] | (BaseModel | string)[]> {
  const result: (BaseModel | string)[] = [];
  const service = dataTypeToServiceMap[dataType];
  assertDefined(service, `Service for the dataType ${dataType} is not found`);

  if (arrayValue) {
    const populatedInstances: Record<string, boolean> = {};
    for (const element of arrayValue) {
      const id = getStringID(element);
      if (isObjectID(id)) {
        // skip array elements that are duplicates and were already populated
        if (!populatedInstances[id]) {
          try {
            result.push(await service.getInstance(id, undefined));
          } catch (error) {
            logger.warn()('Failed to populate array instance: ' + element);
            result.push(element as BaseModel | string);
          }
          populatedInstances[id] = true;
        }
      } else {
        result.push(element as BaseModel | string);
      }
    }
  }

  /**
   * The populateArray function returns unknown values as-is, in our case as string IDs.
   * In order to comply with properties who don't have a string fallback we need to map them to unknown objects.
   */
  if (shouldFallbackToUnknownObject) {
    return result.map((item) => {
      if (typeof item === 'string') {
        return createUnknownObject(item);
      }

      return item;
    });
  }
  return result;
}
