import { isDefined } from './is-defined';
import { isEnumValue } from './is-enum-value';

export function assertEnum<E extends object>(
  obj: unknown,
  emumType: E,
  message?: string,
): asserts obj is E[keyof E] {
  if (!isDefined(obj) || !isEnumValue(obj, emumType)) {
    throw new Error(message || 'Assert: object is not a valid enum value');
  }
}
