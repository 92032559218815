import { Pipe, PipeTransform } from '@angular/core';
import { isUnknownObject, UnknownOr } from '@remberg/global/common/core';
import { BaseModel } from '../definitions';

@Pipe({
  name: 'filterOutUnknownObjects',
  standalone: true,
})
export class FilterOutUnknownObjectsPipe implements PipeTransform {
  public transform<T extends BaseModel>(arr: UnknownOr<T>[]): T[] {
    return arr.filter((item) => !isUnknownObject(item)) as T[];
  }
}
