import { Pipe, PipeTransform } from '@angular/core';
import { CreationTypeEnum } from '@remberg/global/common/core';

export const CREATION_TYPE_ENUM_TRANSLATIONS: Record<CreationTypeEnum, string> = {
  [CreationTypeEnum.USER]: $localize`:@@user:User`,
  [CreationTypeEnum.PORTAL]: $localize`:@@portal:Portal`,
  [CreationTypeEnum.API_KEY]: $localize`:@@api:API`,
  [CreationTypeEnum.SYSTEM]: $localize`:@@import:Import`,
  [CreationTypeEnum.EMAIL]: $localize`:@@email:Email`,
  [CreationTypeEnum.MAINTENANCE_PLAN]: $localize`:@@maintenancePlan:Maintenance Plan`,
  [CreationTypeEnum.MAINTENANCE_PLAN_2]: $localize`:@@maintenancePlan:Maintenance Plan`,
  [CreationTypeEnum.PUBLIC]: $localize`:@@publicRequest:Public Request`,
};

@Pipe({
  name: 'translateCreationTypeEnum',
  standalone: true,
})
export class TranslateCreationTypeEnumPipe implements PipeTransform {
  public transform(value?: CreationTypeEnum): string {
    return value ? CREATION_TYPE_ENUM_TRANSLATIONS[value] : '';
  }
}
