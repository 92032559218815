import { Activity, MovedActivity } from '../definitions';
import { TicketActivityType } from '../enums';

export const getActivityContentWithExpansionInfo = ({
  plainText,
  strippedPlainText,
  html,
  strippedHtml,
}: {
  plainText?: string;
  strippedPlainText?: string;
  html?: string;
  strippedHtml?: string;
}): {
  defaultMessage: string;
  extendedMessage: string | undefined;
  hasExtendedMessage: boolean;
  isExtendedMessageTextOnly: boolean;
  isDefaultMessageTextOnly: boolean;
} => {
  let extendedMessage: string | undefined;
  let defaultMessage: string;
  let isExtendedMessageTextOnly = false;
  let isDefaultMessageTextOnly = false;

  if (html && strippedHtml && html !== strippedHtml) {
    extendedMessage = html;
    defaultMessage = strippedHtml;
    // 2. We only have the full html, so we show this, in order not to loose formatting.
  } else if (html) {
    extendedMessage = html;
    defaultMessage = html;
    // 3. Plain E-Mail case (as strippedHtml also exists for plain emails)
    // We show the stripped version per default, & the complete message after pressing show more.
  } else if (plainText && strippedHtml) {
    extendedMessage = plainText;
    defaultMessage = strippedHtml;
    isExtendedMessageTextOnly = true;
    // 4. Same as 3. bus with the "strippedPlainText" instead of strippedHtml
  } else if (plainText && strippedPlainText) {
    extendedMessage = plainText;
    defaultMessage = strippedPlainText;
    isExtendedMessageTextOnly = true;
    isDefaultMessageTextOnly = true;
    // 5. Last resort fallback option.
  } else {
    extendedMessage = plainText ?? strippedPlainText ?? '';
    defaultMessage = plainText ?? strippedPlainText ?? '';
    isExtendedMessageTextOnly = true;
    isDefaultMessageTextOnly = true;
  }

  const hasExtendedMessage = extendedMessage !== defaultMessage;

  return {
    defaultMessage,
    extendedMessage: hasExtendedMessage ? extendedMessage : undefined,
    hasExtendedMessage,
    isExtendedMessageTextOnly: hasExtendedMessage ? isExtendedMessageTextOnly : false,
    isDefaultMessageTextOnly,
  };
};

export const areActivitiesLimited = (serviceCase?: {
  activities: unknown[];
  activityCount?: number;
}): boolean => serviceCase?.activities?.length !== serviceCase?.activityCount;

export const getMoveActivitiesByDestinationTicketId = (
  ticket2Id: string,
  activities: Activity[] = [],
): MovedActivity[] =>
  activities
    .filter(
      (activity): activity is MovedActivity => activity.kind === TicketActivityType.CASE_MOVED,
    )
    .filter(({ destinationTicketId }) => destinationTicketId?.toString() === ticket2Id);

export const getMovedActivityIdsByDestinationTicketId = (
  ticket2Id: string,
  activities: Activity[] = [],
): Set<string> => {
  const moveActivities = getMoveActivitiesByDestinationTicketId(ticket2Id, activities);
  return getMovedActivityIdsOfMoveActivities(moveActivities);
};

export const getMovedActivityIdsOfMoveActivities = (
  moveActivities: MovedActivity[] = [],
): Set<string> =>
  new Set(
    moveActivities.flatMap(
      (moveActivity) => moveActivity?.movedActivityIds?.map((id) => id?.toString()) ?? [],
    ),
  );
