import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LogService, SimulateIonicPlatformEnum } from '@remberg/global/ui';
import { CssColorConverterPipe } from '../helpers/css-color-converter.pipe';

import { MatIconRegistry } from '@angular/material/icon';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { getFontColorDependingOnBackground } from '@remberg/global/common/core';
import {
  DarkColorCalculationFactor,
  DefaultColors,
  LightColorCalculationFactor,
  hexToRgb,
} from '@remberg/global/ui';
import { ThemeSettings } from '@remberg/tenants/common/main';
import isEqual from 'lodash/isEqual';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  withLatestFrom,
} from 'rxjs';
import { RootGlobalState } from '../store/core-ui.definitions';
import { DeviceTypeState } from '../store/global/global.definitions';
import { GlobalSelectors } from '../store/global/global.selectors';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private htmlRef = document.documentElement;
  private colorConverter = new CssColorConverterPipe();
  private deviceType?: DeviceTypeState;
  private isInitialized$ = new BehaviorSubject(false);
  private renderer: Renderer2;

  constructor(
    private readonly logger: LogService,
    private readonly title: Title,
    private readonly platform: Platform,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly store: Store<RootGlobalState>,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public setTitle({
    workspaceName,
    displayedNotificationsCount,
  }: {
    workspaceName: string;
    displayedNotificationsCount?: string;
  }): void {
    const notifications = displayedNotificationsCount ? `(${displayedNotificationsCount}) ` : '';

    this.title.setTitle(notifications + workspaceName);
  }

  public async initialize(deviceType: DeviceTypeState): Promise<void> {
    await this.platform.ready();
    this.deviceType = deviceType;

    this.registerMatIcons();
    this.fixViewHeightForMobileWeb();
    this.addIonicCSS();

    await this.setupThemeSubscription();
  }

  private async setupThemeSubscription(): Promise<void> {
    this.store
      .select(GlobalSelectors.selectTheme)
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        withLatestFrom(this.store.select(GlobalSelectors.selectTenantId)),
      )
      .subscribe(([theme, tenantId]) => {
        if (theme && tenantId) {
          this.applyTheme(theme);
        } else {
          this.applyDefaultTheme();
        }
        this.isInitialized$.next(true);
      });

    await firstValueFrom(this.isInitialized$.pipe(filter(Boolean)));
  }

  private applyTheme(theme: ThemeSettings): void {
    this.logger.debug()('Theme Service: Applying new Theme.', theme);

    this.setTitle({ workspaceName: theme.workspaceName });
    if (theme.faviconUrl) {
      this._document.getElementById('themeFaviconIco')?.setAttribute('href', theme.faviconUrl);
    }

    const primaryThemeColor = theme.primaryThemeColor;
    const backgroundThemeColor = theme.backgroundThemeColor;
    if (primaryThemeColor && backgroundThemeColor) {
      this.logger.info()(
        // eslint-disable-next-line max-len
        `Primary: ${primaryThemeColor} and ${hexToRgb(primaryThemeColor)?.r}, ${
          hexToRgb(primaryThemeColor)?.g
        }, ${hexToRgb(primaryThemeColor)?.b}`,
      );
      this.logger.info()(
        `Secondary: ${backgroundThemeColor} and ` +
          `${hexToRgb(backgroundThemeColor)?.r}, ${hexToRgb(backgroundThemeColor)?.g}, ${
            hexToRgb(backgroundThemeColor)?.b
          }`,
      );

      // Primary Colors
      const primaryThemeColorLight =
        this.colorConverter.transform(
          primaryThemeColor,
          'hexToLightenHex',
          LightColorCalculationFactor,
        ) ?? undefined;
      const primaryThemeColorDark =
        this.colorConverter.transform(
          primaryThemeColor,
          'hexToLightenHex',
          DarkColorCalculationFactor,
        ) ?? undefined;

      const textColorOnPrimaryBackground = getFontColorDependingOnBackground(
        primaryThemeColor,
        'hex',
      );

      if (this.htmlRef) {
        this.htmlRef.style.setProperty('--primaryThemeColor', primaryThemeColor);
        this.htmlRef.style.setProperty('--primaryThemeColorDark', primaryThemeColorDark ?? null);
        this.htmlRef.style.setProperty('--primaryThemeColorLight', primaryThemeColorLight ?? null);
        this.htmlRef.style.setProperty(
          '--textColorOnPrimaryBackground',
          textColorOnPrimaryBackground,
        );
      }

      // Background Colors
      const backgroundThemeColorLight =
        this.colorConverter.transform(
          backgroundThemeColor,
          'hexToLightenHex',
          LightColorCalculationFactor,
        ) ?? undefined;
      const backgroundThemeColorDark =
        this.colorConverter.transform(
          backgroundThemeColor,
          'hexToLightenHex',
          DarkColorCalculationFactor,
        ) ?? undefined;

      if (this.htmlRef) {
        this.htmlRef.style.setProperty('--backgroundThemeColor', backgroundThemeColor);
        this.htmlRef.style.setProperty(
          '--backgroundThemeColorDark',
          backgroundThemeColorDark ?? null,
        );
        this.htmlRef.style.setProperty(
          '--backgroundThemeColorLight',
          backgroundThemeColorLight ?? null,
        );
      }

      // Set Statusbar Colors on Android and ios only (the StatusBar plugin is not available on Web)
      const isRealAndroidOrIOSMobileApp =
        this.deviceType?.isIonic &&
        !this.deviceType.simulatedIonicType &&
        (this.deviceType.isAndroid || this.deviceType.isIos);

      if (isRealAndroidOrIOSMobileApp && Capacitor.isPluginAvailable('StatusBar')) {
        if (textColorOnPrimaryBackground === DefaultColors.textColorOnPrimaryBackground) {
          StatusBar.setStyle({ style: Style.Dark });
        } else {
          StatusBar.setStyle({ style: Style.Light });
        }
        // Android only
        if (this.deviceType?.isAndroid && backgroundThemeColor) {
          StatusBar.setBackgroundColor({ color: backgroundThemeColor });
        }
      }
    }
  }

  private applyDefaultTheme(): void {
    this.logger.debug()('Theme Service: Removing theme styles and apply default Theme.');

    this.htmlRef.style.removeProperty('--primaryThemeColor');
    this.htmlRef.style.removeProperty('--primaryThemeColorDark');
    this.htmlRef.style.removeProperty('--primaryThemeColorLight');

    this.htmlRef.style.removeProperty('--backgroundThemeColor');
    this.htmlRef.style.removeProperty('--backgroundThemeColorDark');
    this.htmlRef.style.removeProperty('--backgroundThemeColorLight');

    this.htmlRef.style.removeProperty('--textColorOnPrimaryBackground');

    this.setTitle({ workspaceName: 'remberg' });
    this._document.getElementById('themeFaviconIco')?.setAttribute('href', 'favicon.ico');

    // Android only
    const isRealAndroidMobileApp =
      this.deviceType?.isIonic && !this.deviceType.simulatedIonicType && this.deviceType.isAndroid;

    if (isRealAndroidMobileApp && Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setBackgroundColor({ color: DefaultColors.BackgroundThemeColor });
    }
  }

  private registerMatIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'qrcode',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/remberg-qr-code.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'skype',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icons8-skype.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'whatsapp',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icons8-whatsapp.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icons8-hangout.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'leaderboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icons8-leaderboard.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'asset',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon-asset.svg'),
    );
  }

  private fixViewHeightForMobileWeb(): void {
    // 100vh not working for mobile web browsers
    // https://www.bram.us/2016/09/12/making-viewport-units-work-properly-in-mobile-safari/
    if (!this.deviceType?.isIonic && this.deviceType?.isMobileWeb) {
      this.htmlRef.style.setProperty('--vh', `${window.innerHeight / 100}px`);
      window.addEventListener('resize', () => {
        this.htmlRef.style.setProperty('--vh', `${window.innerHeight / 100}px`);
      });
    }
  }

  private addIonicCSS(): void {
    if (this.deviceType?.isIonic) {
      const htmlElement = document.getElementsByTagName('html')[0];
      this.renderer.addClass(htmlElement, 'isIonic');
    }

    if (this.deviceType?.simulatedIonicType === SimulateIonicPlatformEnum.ANDROID) {
      const htmlElement = document.getElementsByTagName('html')[0];
      this.renderer.addClass(htmlElement, 'ios');
      this.renderer.addClass(htmlElement, 'plt-ios');
      this.renderer.removeClass(htmlElement, 'plt-mobile');
      this.renderer.removeClass(htmlElement, 'plt-mobileweb');
    }

    if (this.deviceType?.simulatedIonicType === SimulateIonicPlatformEnum.IOS) {
      const htmlElement = document.getElementsByTagName('html')[0];
      this.renderer.addClass(htmlElement, 'android');
      this.renderer.addClass(htmlElement, 'plt-android');
      this.renderer.removeClass(htmlElement, 'plt-mobile');
      this.renderer.removeClass(htmlElement, 'plt-mobileweb');
    }
  }
}
