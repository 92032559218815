<div
  [ngClass]="{
    'toggle-left': togglePosition === 'left',
    'toggle-right': togglePosition === 'right',
  }"
  class="main-container"
>
  <mat-slide-toggle
    #toggleRef
    class="toggle"
    [color]="materialColor"
    [formControl]="formControl"
    (change)="onToggleChanged($event.checked)"
  >
  </mat-slide-toggle>
  <div class="texts-container" (click)="onTextClick()">
    <p class="label">{{ label }}</p>
    @if (description) {
      <p class="description">
        {{ description }}
      </p>
    }
  </div>
</div>
