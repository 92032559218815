<mat-chip
  class="preview-indicator-chip"
  [class.small-size]="smallSize"
  [ngClass]="{
    'padding-correction': hideLabel,
    'transparent-backdrop': !isBackdropOnHoverVisible,
  }"
  disableRipple
  [removable]="false"
  (click)="handleClick($event)"
  (mouseup)="handleMouseup($event)"
>
  @if (icon && !thumbnailId) {
    <div matChipAvatar class="icon-wrapper">
      @if (!isSvgIcon) {
        <mat-icon
          class="smaller-icon"
          [ngClass]="{ 'material-icons-filled': materialIconsFilled }"
          >{{ icon }}</mat-icon
        >
      } @else {
        <mat-icon class="smaller-icon" [svgIcon]="icon" />
      }
    </div>
  }
  @if (!icon && !thumbnailId && thumbnailFallback) {
    <img matChipAvatar class="thumbnail-image" [src]="thumbnailFallback" />
  }
  @if (icon && icon === 'user' && !thumbnailId) {
    <img matChipAvatar class="thumbnail-image" [src]="IMAGE_FALLBACK_URL.user" />
  }
  @if (thumbnailId) {
    <img
      class="thumbnail-image"
      matChipAvatar
      [src]="thumbnailId | fileUrl: thumbnailSource : 'small' : false | async"
      [src-fallback]="thumbnailFallback"
    />
  }
  @if (!hideLabel) {
    <div
      class="chip-text"
      [matTooltipDisabled]="!isTooltipVisible"
      [matTooltip]="title ?? ''"
      [class.chip-text--isLink]="isLink || linkUrl"
      [class.chip-text--isBold]="boldLabel"
      [attr.data-test-id]="'title-' + title"
    >
      {{ title }}
    </div>
  }
</mat-chip>
