export enum MaintenancePlanFilterFieldEnum {
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',

  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',

  STATUS = 'status',

  ASSOCIATIONS_RELATED_ASSET = 'associationsRelatedAsset',
  ASSOCIATIONS_RELATED_ORGANIZATION = 'associationsRelatedOrganization',
  ASSOCIATIONS_RELATED_CONTACT = 'associationsRelatedContact',

  START_DATE = 'startDate',
  END_DATE = 'endDate',

  WORK_ORDER_TEMPLATE_PRIORITY = 'workOrderTemplatePriority',
  WORK_ORDER_TEMPLATE_STATUS = 'workOrderTemplateStatus',
  WORK_ORDER_TEMPLATE_TYPE = 'workOrderTemplateType',
  WORK_ORDER_TEMPLATE_RESPONSIBLE_GROUP = 'workOrderTemplateResponsibleGroup',
  WORK_ORDER_TEMPLATE_RESPONSIBLE_CONTACT = 'workOrderTemplateResponsibleContact',
  WORK_ORDER_TEMPLATE_ASSIGNED_CONTACTS = 'workOrderTemplateAssignedContacts',
  WORK_ORDER_TEMPLATE_ASSIGNED_GROUPS = 'workOrderTemplateAssignedGroups',
}
