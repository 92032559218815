export enum IsPageActiveEnum {
  IS_ADMIN_TENANT_USERS_OVERVIEW_PAGE = 'isAdminTenantUsersOverviewPage',
  IS_ADMIN_EMAIL_INBOUND_OVERVIEW_PAGE = 'isAdminEmailInboundOverviewPage',
  IS_ADMIN_EMAIL_OUTBOUND_OVERVIEW_PAGE = 'isAdminEmailOutboundOverviewPage',
  IS_ASSET_OVERVIEW_PAGE = 'isAssetOverviewPage',
  IS_ASSET_DETAIL_PAGE = 'isAssetDetailPage',
  IS_ASSET_TYPE_OVERVIEW_PAGE = 'isAssetTypeOverviewPage',
  IS_CASE_OVERVIEW_PAGE = 'isCaseOverviewPage',
  IS_CASE_DETAIL_PAGE = 'isCaseDetailPage',
  IS_TICKET2_OVERVIEW_PAGE = 'isTicket2OverviewPage',
  IS_TICKET2_DETAIL_PAGE = 'isTicket2DetailPage',
  IS_CONTACT_DETAIL_PAGE = 'isContactDetailPage',
  IS_WORK_ORDER_OVERVIEW_PAGE = 'isWorkOrderOverviewPage',
  IS_WORK_ORDER_DETAILS_PAGE = 'isWorkOrderDetailsPage',
  IS_WORK_ORDER_2_OVERVIEW_PAGE = 'isWorkOrder2OverviewPage',
  IS_WORK_ORDER_2_SCHEDULING_BOARD_OVERVIEW_PAGE = 'isWorkOrder2SchedulingBoardOverviewPage',
  IS_WORK_ORDER_2_DETAIL_PAGE = 'isWorkOrder2DetailPage',
  IS_WORK_ORDER_2_DETAIL_PAGE_IONIC = 'isWorkOrder2DetailPageIonic',
  IS_MAINTENANCE_PLAN_OVERVIEW_PAGE = 'isMaintenancePlanOverviewPage',
  IS_MAINTENANCE_PLAN_DETAIL_PAGE = 'isMaintenancePlanDetailPage',
  IS_TENANT_CUSTOMERS_OVERVIEW_PAGE = 'isTenantCustomersOverviewPage',
  IS_FORM_TEMPLATE_OVERVIEW_PAGE = 'isFormTemplateOverviewPage',
  IS_FORM_INSTANCE_DETAIL_PAGE = 'isFormInstanceDetailPage',
  IS_FORM_INSTANCE_OVERVIEW_PAGE = 'isFormInstanceOverviewPage',
  IS_FILES_OVERVIEW_PAGE = 'isFilesOverviewPage',
  IS_IMPORT_OVERVIEW_PAGE = 'isImportOverviewPage',
  IS_ORGANIZATIONS_OVERVIEW_PAGE = 'isOrganizationsOverviewPage',
  IS_ORGANIZATIONS_DETAIL_PAGE = 'isOrganizationsDetailPage',
  IS_CONTACTS_OVERVIEW_PAGE = 'isContactsOverviewPage',
  IS_PARTS_OVERVIEW_PAGE = 'isPartsOverviewPage',
  IS_APPOINTMENTS_OVERVIEW_PAGE = 'isAppointmentsOverviewPage',
  IS_REMBERG_USERS_OVERVIEW_PAGE = 'isRembergUsersOverviewPage',
  IS_USER_ROLES_SETTINGS_PAGE = 'isUserRolesSettingsPage',
  IS_TENANTS_OVERVIEW_PAGE = 'isTenantsOverviewPage',
  IS_QRCODES_OVERVIEW_PAGE = 'isQRCodesOverviewPage',
  IS_INGESTION_JOBS_OVERVIEW_PAGE = 'isIngestionJobsOverviewPage',
  IS_TASK_OVERVIEW_PAGE = 'isTaskOverviewPage',
}
