import {
  AllColors,
  ColorPaletteOption,
  CreationTypeEnum,
  MultiLanguageText,
  RembergTimestamp,
  UnknownOr,
} from '@remberg/global/common/core';
import { CustomTagModelEnum } from './enums';

export interface CustomTag {
  _id: string;
  tenantId: string;
  reference: string; // unique per model
  order: number;
  label: MultiLanguageText;
  description?: string;
  model: CustomTagModelEnum;
  color: ColorPaletteOption<AllColors>;
  isPredefined?: boolean;
  createdAt: RembergTimestamp;
  createdById?: string;
  createdByType: CreationTypeEnum;
  updatedAt?: RembergTimestamp;
  updatedById?: string;
}

export function isCustomTag(argument?: string | UnknownOr<CustomTag>): argument is CustomTag {
  return !!argument && !!(argument as CustomTag).model;
}
