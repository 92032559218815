import { formatToRembergNumberString } from '@remberg/global/common/core';
import { Ticket2Basic } from '@remberg/tickets2/common/base';

export const getTicket2ID = (ticket2: Ticket2Basic): string =>
  getTicket2IDFromCounter(ticket2.counter);

export const getTicket2IDFromCounter = (counter: number): string =>
  `TK-${formatToRembergNumberString(counter, true)}`;

export const getTicket2Subject = (ticket2: Ticket2Basic): string =>
  `${getTicket2ID(ticket2)} (${ticket2.subject})`;
