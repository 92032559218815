<div class="header-wrapper">
  <div>
    <mat-icon class="mat-icon notranslate mat-icon-no-color icon-primary">{{
      headerIcon
    }}</mat-icon>
  </div>
</div>

<div>
  @if (headerText) {
    <div>
      <h2 class="header-text">
        {{ headerText }}
      </h2>
    </div>
  }
</div>

<div>
  @if (contentText) {
    <div>
      <p class="content-text">
        {{ contentText }}
      </p>
    </div>
  }

  <mat-form-field
    cdkFocusInitial
    class="preview-url-number-input my-mat-form-field my-mat-form-field-full-width"
    appearance="outline"
  >
    <mat-label>{{ label }}</mat-label>
    <input type="text" matInput [formControl]="previewServerNumFormControl" [placeholder]="label" />

    @if (previewServerNumFormControl.errors?.['required']) {
      <mat-error i18n="@@previewNumberIsRequired"> Preview Number is required </mat-error>
    }
    @if (previewServerNumFormControl.errors?.['pattern']) {
      <mat-error i18n="@@previewNumberIsNotCorrect"> Preview number is not correct </mat-error>
    }
  </mat-form-field>

  @if (inputIsValid) {
    <p @expandIn class="preview-url-text mat-small">
      Will result in
      <span class="preview-url">{{ previewServerURL }}</span>
    </p>
  }
</div>

<div class="buttons-container">
  @if (cancelButtonText) {
    <button mat-stroked-button color="accent" (click)="close()" data-test-id="dialog-cancel-button">
      {{ cancelButtonText }}
    </button>
  }
  @if (confirmButtonText) {
    <button
      class="button-confirm"
      color="primary"
      mat-raised-button
      [disabled]="!inputIsValid"
      (click)="confirm()"
      data-test-id="dialog-confirm-button"
    >
      @if (confirmButtonIcon) {
        <mat-icon>{{ confirmButtonIcon }}</mat-icon>
      }
      {{ confirmButtonText }}
    </button>
  }
</div>

@if (footerText) {
  <div class="footer-container">
    @if (footerIcon) {
      <mat-icon class="material-icons-filled footer-icon">{{ footerIcon }}</mat-icon>
    }
    <p class="footer-text">{{ footerText }}</p>
  </div>
}
