<div [ngClass]="{ 'display-inline': showInline, 'date-time-show-in-forms': showInForms }">
  @if (!isLabelHidden) {
    <div
      class="label"
      [matTooltipDisabled]="!isTooltipVisible || !isTooltipLabelVisible"
      [matTooltip]="label"
    >
      @if (required) {
        {{ label }}<span class="required">*</span>
      } @else {
        {{ label }}
      }
    </div>
  }
  @if (showInline) {
    <span class="fill-remaining-space"></span>
  }
  @if (value) {
    <div
      class="value"
      [matTooltipDisabled]="!isTooltipVisible"
      [matTooltip]="
        showElapsedTimeInTooltip
          ? dateTimeService.getElapsedTime(value)
          : (value
            | rembergDateTimeDisplay
              : {
                  timezoneDisplayMode: showTimezone
                    ? 'alwaysShowTimezone'
                    : 'showTimezoneOnlyIfDifferent',
                  convertToUserTimezone: !preserveTimezone,
                })
      "
    >
      {{
        value
          | rembergDateTimeDisplay
            : {
                timezoneDisplayMode: showTimezone
                  ? 'alwaysShowTimezone'
                  : 'showTimezoneOnlyIfDifferent',
                convertToUserTimezone: !preserveTimezone,
                overrideFormat: hideDate ? 'p' : hideTime ? 'P' : undefined,
              }
      }}
    </div>
  } @else {
    <div class="value">-</div>
  }
</div>
