/**
 * Helps to return strict typed objects or keys of certain type
 * when it's hard or cumbersome to define it explicitly
 *
 * @example
 * interface Foo {
 *  name: string;
 * }
 *
 * function getFooKey(...) {
 *   ...
 *
 * // return 'name' as keyof Foo -- wouldn't produce an error if the key name changes
 *    return as<keyof Foo>('name'); // - works just fine in that case
 * }
 *
 * .pipe(
 *    map(input => {
 *    ... some logic ...
 *
 *    return as<SomeItem>({ // now IntelliSence will help you to construct SomeItem correctly
 *       ...
 *    });
 * }
 */
export function as<T>(obj: T): T {
  return obj;
}
