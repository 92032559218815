<div
  id="rembergModalWrapper"
  [ngClass]="{ 'modal-wrapper': !styleNoMargin, 'modal-wrapper-no-margin': styleNoMargin }"
>
  @if (headerShow) {
    <div
      fxLayout="row"
      class="modal-header-row"
      [ngClass]="{ 'modal-header-row-with-margin': styleNoMargin }"
    >
      @if (headerCloseActionShow) {
        <button class="modal-back-action" mat-icon-button aria-label="Back">
          <mat-icon (click)="closeDialog()">arrow_back</mat-icon>
        </button>
      }

      @if (headerTitle) {
        <h1
          class="mat-headline-5 title-text table-column-text-ellipsis"
          [ngClass]="{ 'title-text-with-done-button': headerConfirmActionShow }"
        >
          {{ headerTitle }}
        </h1>
      }

      <span class="fill-remaining-space"></span>

      @if (headerConfirmActionShow) {
        <button
          mat-button
          color="primary"
          (click)="confirmDialog()"
          [disabled]="componentRef?.instance?.disableParentConfirmButton"
          i18n="@@done"
        >
          Done
        </button>
      }

      @if (headerThreeDotActionShow) {
        <button
          class="modal-menu-action"
          mat-icon-button
          aria-label="Menu"
          (click)="openThreeDotMenu()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      }
    </div>
  }

  @if (styleHeaderDivider && headerShow) {
    <div class="modal-header-divider"></div>
  }

  <div
    [class.height-to-scroll]="!verticalScrollDisabled"
    [class.vertical-scrolling-disabled]="verticalScrollDisabled"
    [ngStyle]="{ display: createComponentLoader ? 'block' : 'none' }"
    [ngClass]="{
      'height-to-scroll-ios': layout.isIonic && layout.isIos && headerShow,
      'height-to-scroll-android': layout.isIonic && layout.isAndroid && headerShow,
      'remberg-modal-content-wrapper': headerShow && !headerShowWithoutMargin,
    }"
  >
    <template #modalContent></template>
  </div>
</div>
