<div class="wrapper">
  @if (isSearchBarShown$ | async) {
    <app-search-bar
      class="search-bar"
      [autofocus]="true"
      (searchQueryChange)="searchQueryChange.next($event)"
      [inputValue]="inputValue"
      (inputBlur)="showSearchBar$.next(false)"
    />
  }

  <button
    mat-raised-button
    class="toolbar-item button"
    data-test-id="search-button"
    (click)="showSearchBar$.next(true)"
    matBadge="1"
    [matBadgeHidden]="(isSearchBarShown$ | async) || !inputValue"
  >
    <mat-icon>search</mat-icon>
  </button>
</div>
