import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[inputAutoSelectOnFocus]',
  standalone: true,
})
export class InputAutoSelectOnFocusDirective {
  private isOnlyOnce = false;

  constructor(private readonly elementRef: ElementRef<HTMLInputElement>) {}

  @HostListener('focus', ['$event'])
  protected onFocus(): void {
    if (this.isOnlyOnce) {
      return;
    }

    this.isOnlyOnce = true;
    this.elementRef.nativeElement?.select();
  }
}
