import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchNested', standalone: true })
export class SearchNestedPipe implements PipeTransform {
  public transform<T>(
    items: T[] | undefined | null,
    searchText: string,
    getSearchProperty: (item: T) => string,
  ): T[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((item) => getSearchProperty(item).toLowerCase().includes(searchText));
  }
}
