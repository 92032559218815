import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@remberg/global/common/core';
import { LanguageService } from '@remberg/ui-core/core';

@Pipe({ name: 'formatNumber', standalone: true })
export class FormatNumberPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  public transform(value?: number, precision?: number): string {
    if (value === undefined) {
      return '-';
    }
    const currentLanguage = this.languageService.getCurrentLanguage();
    return formatNumber(value, precision, currentLanguage);
  }
}
