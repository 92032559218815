import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { isPlatform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { LocalStorageKeys, SimulateIonicPlatformEnum } from '@remberg/global/ui';
import * as Bowser from 'bowser';
import { filter, firstValueFrom } from 'rxjs';
import { RootGlobalState } from '../store/core-ui.definitions';
import { GlobalActions } from '../store/global/global.actions';
import { DeviceTypeState } from '../store/global/global.definitions';
import { GlobalSelectors } from '../store/global/global.selectors';
import { LayoutService } from './layout.service';

declare global {
  interface Window {
    Cypress?: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  private deviceInfo?: DeviceInfo;
  private deviceId?: DeviceId;
  private isRunningInCypress: boolean = window.Cypress !== undefined;

  /*
   * This flag is used to test mobile app features in the browser during development and e2e testing.
   * Among other changes, it replaces the SQLite APi with a in-memory database running in the browser.
   * The value is set in localStorage directly to bypass bootup race conditions and make it editable
   * from outside the apps logic (like in cypress scripts).
   */
  private simulatedIonicType: SimulateIonicPlatformEnum | undefined =
    (localStorage.getItem(LocalStorageKeys.SIMULATE_IONIC) as SimulateIonicPlatformEnum) ??
    undefined;

  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly platform: Platform,
    private readonly layout: LayoutService,
  ) {}

  public async initialize(): Promise<DeviceTypeState> {
    await this.getDeviceIdAsync();
    await this.getDeviceInfoAsync();
    return await this.initializeDeviceTypeState();
  }

  public getDeviceIdSync = (): string => (this.deviceId ? this.deviceId.identifier : 'not-set');

  public getDeviceInfoAsync = async (): Promise<DeviceInfo> => {
    if (this.deviceInfo) {
      return this.deviceInfo;
    }

    const deviceInfo = await Device.getInfo();

    if (this.simulatedIonicType) {
      deviceInfo.name = `simulated_ionic_${this.simulatedIonicType}`;
    }

    if (this.isRunningInCypress) {
      deviceInfo.name = deviceInfo.name + '_cypress';
    }

    this.deviceInfo = deviceInfo;
    return deviceInfo;
  };

  public getDeviceIdAsync = async (): Promise<string> => {
    if (this.deviceId && this.deviceId.identifier) {
      return this.deviceId.identifier;
    }

    const deviceId = await Device.getId();

    if (this.simulatedIonicType) {
      deviceId.identifier = `${deviceId.identifier}_simulated_ionic_${this.simulatedIonicType}`;
    }

    if (this.isRunningInCypress) {
      deviceId.identifier = deviceId.identifier + '_cypress';
    }

    this.deviceId = deviceId;
    return deviceId.identifier;
  };

  private async initializeDeviceTypeState(): Promise<DeviceTypeState> {
    const isIos =
      this.platform.IOS ||
      isPlatform('ios') ||
      this.simulatedIonicType === SimulateIonicPlatformEnum.IOS;
    const isAndroid =
      this.platform.ANDROID ||
      isPlatform('android') ||
      this.simulatedIonicType === SimulateIonicPlatformEnum.ANDROID;
    const isDesktopWeb = this.platform.isBrowser && isPlatform('desktop');
    const isMobileWeb = isPlatform('mobileweb');
    const isMobileDevice = isPlatform('mobile');
    const isTabletDevice = isPlatform('tablet');
    const isPhoneDevice = isPlatform('mobile') && !isPlatform('tablet');

    // Hybrid includes Cordova and Capacitor
    // https://ionicframework.com/docs/angular/platform
    const isIonic = !!(isPlatform('electron') || isPlatform('hybrid') || this.simulatedIonicType);

    const deviceType: DeviceTypeState = {
      isAndroid,
      isIonic,
      isIos,
      isPhoneDevice,
      isDesktopWeb,
      isMobileWeb,
      isMobileDevice,
      isTabletDevice,
      simulatedIonicType: this.simulatedIonicType,
      deviceId: await this.getDeviceIdAsync(),
      browserName:
        Bowser.getParser(window.navigator.userAgent)?.parseBrowser()?.name ?? 'Unknown Browser',
    };
    this.store.dispatch(
      GlobalActions.deviceTypeInitialized({
        deviceType,
      }),
    );
    this.layout.initialize(deviceType);
    return await firstValueFrom(
      this.store.select(GlobalSelectors.selectDeviceType).pipe(filter(Boolean)),
    );
  }
}
