<div
  fxLayout="column"
  fxLayoutAlign="center center"
  style="height: 100%; position: relative"
  [style.height]="(layout.isXSmallView | async) ? 'initial' : '100%'"
  class="image-loader-wrapper"
>
  <ngx-ui-loader
    style="min-height: 50px"
    [hidden]="loaded"
    [loaderId]="'img-loader'"
    [overlayColor]="'rgba(0,0,0,.0)'"
    [hasProgressBar]="false"
    [fgsTemplate]="fgsSpinner"
    [fgsSize]="25"
    [fgsPosition]="'center-center'"
  >
  </ngx-ui-loader>
  <ng-template #fgsSpinner>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-template>
  @if (!dataUrl) {
    <img
      #image
      [hidden]="!loaded"
      (load)="onLoad()"
      [src]="srcValue"
      src-fallback="{{ srcFallback }}"
      data-test-id="image-instance"
    />
  } @else {
    <img
      #image
      [hidden]="!loaded"
      (load)="onLoad()"
      [src]="dataUrl"
      src-fallback="{{ srcFallback }}"
    />
  }
</div>
