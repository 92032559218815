import { ActivityFieldTypeEnum, ActivityObjectSchema } from '@remberg/activities/common/main';
import {
  MaintenancePlanActivityFieldTypeEnum,
  MaintenancePlanSpecificFieldTypeEnum,
} from './maintenance-plan-activity-field-type.enum';
import { MaintenancePlanActivityFieldEnum } from './maintenance-plan-activity-field.enum';

export const MAINTENANCE_PLAN_ACTIVITY_OBJECT_SCHEMA = {
  [MaintenancePlanActivityFieldEnum.COUNTER]: ActivityFieldTypeEnum.NUMBER,
  [MaintenancePlanActivityFieldEnum.SUBJECT]: ActivityFieldTypeEnum.TEXT,
  [MaintenancePlanActivityFieldEnum.DESCRIPTION]: ActivityFieldTypeEnum.HTML,
  // Fields with MaintenancePlanSpecificFieldTypeEnum
  [MaintenancePlanActivityFieldEnum.STATUS]: MaintenancePlanSpecificFieldTypeEnum.STATUS,
} satisfies ActivityObjectSchema<
  MaintenancePlanActivityFieldEnum,
  MaintenancePlanActivityFieldTypeEnum
>;
