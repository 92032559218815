export enum AdvancedFilterWithinPeriodEnum {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'thisWeek',
  NEXT_WEEK = 'nextWeek',
  LAST_WEEK = 'lastWeek',
  THIS_MONTH = 'thisMonth',
  NEXT_MONTH = 'nextMonth',
  LAST_MONTH = 'lastMonth',
}
