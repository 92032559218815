import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ModalController, Platform, isPlatform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { getFontColorDependingOnBackground } from '@remberg/global/common/core';
import { uuidv4 } from '@remberg/global/ui';
import { BehaviorSubject, EMPTY, Observable, firstValueFrom } from 'rxjs';
import { RootGlobalState } from '../store/core-ui.definitions';
import { BreakpointState } from '../store/global/global.definitions';
import { GlobalSelectors } from '../store/global/global.selectors';
import { DialogWrapperComponent } from './dialog-wrapper/dialog-wrapper.component';
import { DialogOptions, DialogResultData, ModalDialogWrapperInterface } from './dialogs';
import { DynamicPopUpComponent } from './dynamic-pop-up/dynamic-pop-up.component';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';

export class ModalDialogWrapper implements ModalDialogWrapperInterface {
  public dialogModalRef?: MatDialogRef<ModalDialogWrapperInterface> | HTMLIonModalElement;
  isModal?: boolean;
  isDialog?: boolean;

  public isInitialized = new BehaviorSubject<boolean>(false);
  private breakpoints?: BreakpointState;

  private backgroundThemeColor?: string;

  constructor(
    public readonly _modal: ModalController,
    public readonly _dialog: MatDialog,
    private readonly platform: Platform,
    private readonly store: Store<RootGlobalState>,
  ) {}

  /**
   * @description This function returns a Promise for initialization of async modal and sync dialog
   * @returns Returns a promise with a reference to this wrapper
   */
  public async asyncInit(
    createModal: boolean,
    dialogOpts: DialogOptions<DynamicPopUpComponent | any>,
  ): Promise<ModalDialogWrapper> {
    let resolvePromise:
      | ((value: ModalDialogWrapper | PromiseLike<ModalDialogWrapper>) => void)
      | undefined;
    const prom = new Promise<ModalDialogWrapper>((resolve) => (resolvePromise = resolve));

    if (createModal) {
      this.isModal = true;
      this.isDialog = false;

      dialogOpts.modalCssClass = dialogOpts.modalCssClass ? dialogOpts.modalCssClass : [];
      dialogOpts.modalCssClass.push('modal-wrapper-z-index');
      dialogOpts.modalCssClass.push('modal-wrapper-height-variable');

      this._modal
        .create({
          component: ModalWrapperComponent,
          keyboardClose: dialogOpts.modalKeyboardClose || false,
          showBackdrop: dialogOpts.modalShowBackdrop || true,
          backdropDismiss: false,
          canDismiss: dialogOpts.modalCanDismiss,
          cssClass: dialogOpts.modalCssClass,
          componentProps: {
            dialogData: dialogOpts.dialogData,
            dialogModalRef: this,
          },
        })
        .then(async (modal) => {
          this.backgroundThemeColor = (
            await firstValueFrom(this.store.select(GlobalSelectors.selectTheme))
          )?.backgroundThemeColor;
          this.breakpoints = await firstValueFrom(
            this.store.select(GlobalSelectors.selectBreakpoints),
          );
          return modal;
        })
        .then((modal) => {
          this.setStatusBarColorModal();
          modal.present().then(() => {
            this.dialogModalRef = modal;
            this.isInitialized.next(true);
            resolvePromise?.(this);
          });
          modal.onWillDismiss().then(() => {
            this.setStatusBarColorModal(true);
          });
        });
    } else {
      this.isModal = false;
      this.isDialog = true;

      dialogOpts.dialogData.dialogModalRef = this as unknown as MatDialogRef<ModalDialogWrapper>;

      const uniqueClass = 'dialog-' + uuidv4();

      this.dialogModalRef = this._dialog.open(DialogWrapperComponent, {
        panelClass: ['dialog-wrapper-mat-dialog', uniqueClass],
        maxWidth: '100vw',
        maxHeight: '100vh',
        autoFocus: dialogOpts.dialogAutoFocus ?? false,
        restoreFocus: dialogOpts.dialogRestoreAutoFocus ?? false,
        disableClose: dialogOpts.dialogBackdropCloseDisabled ?? false,
        hasBackdrop: dialogOpts.dialogHasBackdrop ?? true,
        backdropClass: dialogOpts.dialogBackdropClass,
        data: dialogOpts.dialogData,
        position: dialogOpts.dialogData?.materialConfig?.position,
      });
      for (const cssClass of dialogOpts?.dialogCssClass ?? []) {
        window.document.querySelector<any>('.' + uniqueClass).parentNode.classList.add(cssClass);
      }
      this.isInitialized.next(true);
      resolvePromise?.(this);
    }

    return prom;
  }

  /**
   * @description This function returns a Promise that listens to data form modal or dialog
   * @returns Returns a promise that has formatted return data in same way
   */
  public async waitForCloseData(): Promise<DialogResultData> {
    const returnData: DialogResultData = {};
    let resolvePromise:
      | ((value: DialogResultData | PromiseLike<DialogResultData>) => void)
      | undefined;
    const prom = new Promise<DialogResultData>((resolve) => (resolvePromise = resolve));

    this.isInitialized.subscribe((initialized) => {
      if (initialized) {
        if (this.isModal) {
          let componentInstance: any;
          // Creating a copy of component instance to access data
          (this.dialogModalRef as HTMLIonModalElement).onWillDismiss().then((data) => {
            if (data?.data?.componentInstance) {
              componentInstance = Object.assign(data.data.componentInstance);
            }
          });
          (this.dialogModalRef as HTMLIonModalElement).onDidDismiss().then((data) => {
            returnData.confirmed = data?.data?.confirmation || data?.data?.confirmed || false;
            returnData.data = data?.data;
            returnData.componentInstance = componentInstance;
            resolvePromise?.(returnData);
          });
        } else {
          let componentInstance: any;
          // Creating a copy of component instance to access data
          (this.dialogModalRef as MatDialogRef<DialogWrapperComponent>)
            .beforeClosed()
            .toPromise()
            .then((data) => {
              if (data?.data?.componentInstance) {
                componentInstance = Object.assign(data.data.componentInstance);
              }
            });
          (this.dialogModalRef as MatDialogRef<DialogWrapperComponent>)
            .afterClosed()
            .toPromise()
            .then((data) => {
              returnData.confirmed = data?.confirmation || data?.confirmed || false;
              returnData.data = data;
              returnData.componentInstance = componentInstance;
              resolvePromise?.(returnData);
            });
        }
      }
    });
    return prom;
  }

  /**
   * @description With this function you can close the modal/dialog from outside of a component and pass parent data
   *
   * @param data Any data object that you want to pass from subcomponent to opening component.
   * @param forceConfirm Overwrite confirmed flag in parent.
   */
  public async closeDialogModal(data?: any, forceConfirm?: boolean): Promise<DialogResultData> {
    const returnData: DialogResultData = {
      confirmed: false || forceConfirm,
      data: data,
    };

    let resolvePromise:
      | ((value: DialogResultData | PromiseLike<DialogResultData>) => void)
      | undefined;
    const prom = new Promise<DialogResultData>((resolve) => (resolvePromise = resolve));

    this.isInitialized.subscribe((initialized) => {
      if (initialized) {
        if (this.isModal) {
          (this.dialogModalRef as HTMLIonModalElement).onDidDismiss().then((data) => {
            returnData.componentInstance = data?.data?.componentInstance;
            returnData.data = data;
            resolvePromise?.(returnData);
          });
        } else {
          (this.dialogModalRef as MatDialogRef<DialogWrapperComponent>).close();
          resolvePromise?.(returnData);
        }
      }
    });

    return prom;
  }

  /**
   * @description With this function you can close the modal/dialog from within a subcomponent without thinking about the type of modal or dialog
   *
   * @param data Any data object that you want to pass from subcomponent to opening component.
   * @param forceConfirm Overwrite confirmed flag in parent.
   */
  public closeDialogModalFromChildComponent(data?: any, forceConfirm?: boolean): void {
    if (forceConfirm) {
      data.confirmed = true;
      data.confirmation = true;
    }

    if (this.isInitialized) {
      if (this.isModal) {
        (this.dialogModalRef as HTMLIonModalElement).dismiss(data);
      } else {
        (this.dialogModalRef as MatDialogRef<DialogWrapperComponent>).close(data);
      }
    }
  }

  /**
   * @description Backdrop click abstraction for dialogs
   *
   */
  public subscribeBackdropClick(): Observable<MouseEvent | boolean> {
    if (this.isDialog) {
      return (this.dialogModalRef as MatDialogRef<DialogWrapperComponent>).backdropClick();
    }
    return EMPTY;
  }

  /**
   * @description Sets the correct status bar color for Android phones only
   *
   * @param reset Reset to original color
   */
  private setStatusBarColorModal(reset?: boolean): void {
    // xSmallView Only
    if (this.breakpoints?.isXSmallView) {
      this.platform.ready().then(() => {
        // Android and ios only
        if (
          (isPlatform('android') || isPlatform('ios')) &&
          isPlatform('hybrid') &&
          Capacitor.isPluginAvailable('StatusBar')
        ) {
          if (reset) {
            if (
              this.backgroundThemeColor &&
              getFontColorDependingOnBackground(this.backgroundThemeColor, 'hex') ===
                'rgb(255,255,255)'
            ) {
              StatusBar.setStyle({ style: Style.Dark });
            } else {
              StatusBar.setStyle({ style: Style.Light });
            }
            // Android only
            if (isPlatform('android')) {
              if (this.backgroundThemeColor) {
                StatusBar.setBackgroundColor({ color: this.backgroundThemeColor });
              }
            }
          } else {
            StatusBar.setStyle({ style: Style.Light });
            // Android only
            if (isPlatform('android')) {
              StatusBar.setBackgroundColor({ color: '#fafafa' });
            }
          }
        }
      });
    }
  }
}
