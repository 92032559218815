import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ADMIN_SUBDOMAIN } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { TenantService } from '@remberg/tenants/ui/clients';
import { delay, firstValueFrom, of, tap } from 'rxjs';
import { AppStateService } from '../services/app-state.service';
import { GlobalActions, GlobalSelectors, NavigationalActions, RootGlobalState } from '../store';

export const loginNavigationGuardFn: CanActivateFn | CanActivateChildFn = async (
  route: ActivatedRouteSnapshot,
) => {
  const store: Store<RootGlobalState> = inject(Store);
  const tenantService = inject(TenantService);
  const appState = inject(AppStateService);
  const logger = inject(LogService);
  const router = inject(Router);

  logger.debug()('LoginNavigationGuard - canActivate');
  await appState.getReadyState();

  const isLoggedIn = await firstValueFrom(store.select(GlobalSelectors.selectIsLoggedIn));
  const targetUrl = route.queryParams['target'];

  if (!isLoggedIn) {
    const isSubdomainRoutingActive = await firstValueFrom(
      store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
    );
    if (!isSubdomainRoutingActive) {
      // We will login as admin
      return true;
    }

    const tenantSubdomain = await firstValueFrom(
      store.select(GlobalSelectors.selectTenantSubdomain),
    );
    if (!tenantSubdomain) {
      store.dispatch(NavigationalActions.goToApplicationRoot({ targetUrl }));
      return false;
    }

    if (tenantSubdomain !== ADMIN_SUBDOMAIN) {
      const tenant = await firstValueFrom(tenantService.getPublicBySubdomain(tenantSubdomain));

      if (!tenant?.isActivated) {
        const message = $localize`:@@tenantIsDeactivated:Tenant is deactivated`;
        store.dispatch(GlobalActions.showToaster({ toasterType: 'error', message }));

        return await firstValueFrom(
          of(false).pipe(
            delay(1000),
            tap(() => store.dispatch(NavigationalActions.goToApplicationRoot({ targetUrl }))),
          ),
        );
      }
    }

    return true;
  }

  if (targetUrl && targetUrl !== '/' && targetUrl !== '/login') {
    logger.debug()('Moving to targeted page: ' + targetUrl);
    void router.navigateByUrl(targetUrl);
    return false;
  }
  store.dispatch(NavigationalActions.goToAuthenticatedDefaultPage());
  return false;
};
