import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { FilesTableColumnEnum } from '../offline/platform-files-offline-service.definitions';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';
import { addColumnIfMissing } from './columnHelpers';

export async function migrateToV25(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V25...');

  await addMissingFilesTableColumns(db, logger);

  await db.executeSql('PRAGMA user_version = 25;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V25.');
}

async function addMissingFilesTableColumns(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  try {
    const tableExistsQuery = await db.executeSql(
      `SELECT name FROM sqlite_master WHERE type='table' AND name='${SyncDataTypesEnum.FILES}';`,
      [],
    );

    if (!tableExistsQuery.rows?.length) {
      logger.debug()(`${SyncDataTypesEnum.FILES} table does not exist. Skipping migration.`);
      return;
    }

    logger.debug()(`FOUND ${SyncDataTypesEnum.FILES} table`);

    await addColumnIfMissing(
      db,
      logger,
      SyncDataTypesEnum.FILES,
      FilesTableColumnEnum.SOURCE,
      'TEXT',
    );

    await addColumnIfMissing(
      db,
      logger,
      SyncDataTypesEnum.FILES,
      FilesTableColumnEnum.SOURCE_TYPE,
      'TEXT',
    );

    await addColumnIfMissing(
      db,
      logger,
      SyncDataTypesEnum.FILES,
      FilesTableColumnEnum.ORIGINALNAME,
      'TEXT',
    );
  } catch (error) {
    logger.error()('Error adding columns!', error);
  }
}
