<!-- Very bad solution, check dynamic switching progress here /https://github.com/angular/components/issues/15367 -->

@switch (buttonStyle) {
  <!-- Base Button -->
  @default {
    <button
      mat-button
      [color]="materialColor"
      [ngClass]="[additionalClass, isErrorState ? 'is-error-state' : '']"
      [ngStyle]="overrideNgStyle ?? { width: '100%', position: 'relative' }"
      [ngClass.lt-sm]="[additionalClassIsSmallView, isErrorState ? 'is-error-state' : '']"
      (click)="loaderClick($event)"
      [disabled]="disabled || loading"
      [attr.data-test-id]="dataTestId"
      [attr.data-product-tour-id]="dataProductTourId"
    >
      @if (icon && !loading) {
        <mat-icon>{{ icon }}</mat-icon>
      }
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  }
  <!-- Raised Button -->
  @case ('mat-raised-button') {
    <button
      mat-raised-button
      [color]="materialColor"
      [ngClass]="[additionalClass, isErrorState ? 'is-error-state' : '']"
      [ngStyle]="overrideNgStyle ?? { width: '100%', position: 'relative' }"
      [ngClass.lt-sm]="[additionalClassIsSmallView, isErrorState ? 'is-error-state' : '']"
      (click)="loaderClick($event)"
      [disabled]="disabled || loading"
      [attr.data-test-id]="dataTestId"
    >
      @if (icon && !loading) {
        <mat-icon>{{ icon }}</mat-icon>
      }
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  }
  <!-- Stroked Button -->
  @case ('mat-stroked-button') {
    <button
      mat-stroked-button
      [color]="materialColor"
      [ngClass]="[additionalClass, isErrorState ? 'is-error-state' : '']"
      [ngStyle]="overrideNgStyle ?? { width: '100%', position: 'relative' }"
      [ngClass.lt-sm]="[additionalClassIsSmallView, isErrorState ? 'is-error-state' : '']"
      (click)="loaderClick($event)"
      [disabled]="disabled || loading"
      [attr.data-test-id]="dataTestId"
    >
      @if (icon && !loading) {
        <mat-icon>{{ icon }}</mat-icon>
      }
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  }
  <!-- Flat Button -->
  @case ('mat-flat-button') {
    <button
      mat-flat-button
      [color]="materialColor"
      [ngClass]="[additionalClass, isErrorState ? 'is-error-state' : '']"
      [ngStyle]="overrideNgStyle ?? { width: '100%', position: 'relative' }"
      [ngClass.lt-sm]="[additionalClassIsSmallView, isErrorState ? 'is-error-state' : '']"
      (click)="loaderClick($event)"
      [disabled]="disabled || loading"
      [attr.data-test-id]="dataTestId"
    >
      @if (icon && !loading) {
        <mat-icon>{{ icon }}</mat-icon>
      }
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  }
}

<ng-template #buttonContent>
  @if (!showTextOnLoading) {
    @if (loading) {
      <div class="text-spinner">
        <mat-progress-spinner
          class="progress-spinner"
          [color]="materialColor ? materialColor : 'accent'"
          [diameter]="spinnerSize ?? defaultSpinnerSize"
          mode="indeterminate"
        />
      </div>
    }
    <span class="" [ngStyle]="loading ? { visibility: 'hidden' } : {}">
      {{ text }}
    </span>
  } @else {
    <div class="flex-row flex-row-center flex-gap-row-10">
      @if (!loading) {
        <span [ngClass]="{ 'button-text-with-icon': icon }">{{ text }}</span>
      } @else {
        <mat-progress-spinner
          class="progress-spinner"
          [color]="materialColor ? materialColor : 'accent'"
          [diameter]="defaultSpinnerSize"
          mode="indeterminate"
        />
        <span>{{ text }}</span>
      }
    </div>
  }
</ng-template>
