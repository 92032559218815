@let canAccessFirstButton =
  buttonList[0] && (!buttonList[0].rightsCheck$ || (buttonList[0].rightsCheck$() | async));

@if (canAccessFirstButton) {
  <!-- Main Button -->
  <app-button-loader
    class="dropdown-loader-button"
    [ngClass]="{ 'full-width': isFullWidth, 'dropdown-button-large': isButtonSizeLarge }"
    [disabled]="disabled"
    [loading]="loading"
    [buttonStyle]="buttonStyle"
    [text]="buttonList[0].label"
    [dataTestId]="dataTestId ?? buttonList[0].name ?? buttonList[0].dataTestId"
    [additionalClass]="additionalClass + (buttonList.length > 1 ? ' dropdown-arrow' : ' ')"
    [additionalClassIsSmallView]="
      additionalClassIsSmallView + (buttonList.length > 1 ? ' dropdown-arrow' : ' ')
    "
    [materialColor]="materialColor"
    [isErrorState]="highlightAsRequired"
    [overrideNgStyle]="getAdditionalButtonStyles"
    [icon]="buttonList[0].hasIcon"
    [spinnerSize]="buttonLoaderSpinnerSize"
    (action)="clicked(0)"
  >
  </app-button-loader>
}

<!-- Optional: Dropdown Button & Trigger -->
<!-- Very bad solution, check dynamic switching progress here /https://github.com/angular/components/issues/15367 -->
@if (buttonList.length > 1 && canAccessFirstButton) {
  @switch (buttonStyle) {
    @default {
      <button
        mat-button
        [ngClass]="[
          'dropdown-arrow-button',
          isButtonSizeLarge ? 'dropdown-arrow-button-large' : '',
          highlightAsRequired ? 'dropdown-arrow-button-warn' : '',
        ]"
        [color]="materialColor"
        [disabled]="disabled || loading"
        [matMenuTriggerFor]="menu"
        [attr.data-test-id]="dataTestIdForDropDown ?? 'dropdown-button'"
      >
        <mat-icon class="down-icon">keyboard_arrow_down</mat-icon>
      </button>
    }
    @case ('mat-raised-button') {
      <button
        mat-raised-button
        [ngClass]="[
          'dropdown-arrow-button',
          isButtonSizeLarge ? 'dropdown-arrow-button-large' : '',
          highlightAsRequired ? 'dropdown-arrow-button-warn' : '',
        ]"
        [color]="materialColor"
        [disabled]="disabled || loading"
        [matMenuTriggerFor]="menu"
        [attr.data-test-id]="dataTestIdForDropDown ?? 'dropdown-button'"
      >
        <mat-icon class="down-icon">keyboard_arrow_down</mat-icon>
      </button>
    }
    @case ('mat-stroked-button') {
      <button
        mat-stroked-button
        [ngClass]="[
          'dropdown-arrow-button',
          isButtonSizeLarge ? 'dropdown-arrow-button-large' : '',
          highlightAsRequired ? 'dropdown-arrow-button-warn' : '',
        ]"
        [color]="materialColor"
        [disabled]="disabled || loading"
        [matMenuTriggerFor]="menu"
        [style.border-style]="borderStyle"
        [attr.data-test-id]="dataTestIdForDropDown ?? 'dropdown-button'"
      >
        <mat-icon class="down-icon">keyboard_arrow_down</mat-icon>
      </button>
    }
    @case ('mat-flat-button') {
      <button
        mat-flat-button
        [ngClass]="[
          'dropdown-arrow-button',
          isButtonSizeLarge ? 'dropdown-arrow-button-large' : '',
          highlightAsRequired ? 'dropdown-arrow-button-warn' : '',
        ]"
        [color]="materialColor"
        [disabled]="disabled || loading"
        [matMenuTriggerFor]="menu"
        [attr.data-test-id]="dataTestIdForDropDown ?? 'dropdown-button'"
      >
        <mat-icon class="down-icon">keyboard_arrow_down</mat-icon>
      </button>
    }
  }
}

<!-- Dropdown Menu -->
<mat-menu #menu="matMenu" xPosition="before" [backdropClass]="backdropClass">
  @for (button of buttonList?.slice(1); track button; let i = $index) {
    @if (!button.rightsCheck$ || (button.rightsCheck$() | async)) {
      <button
        mat-menu-item
        (click)="clicked(i + 1)"
        [attr.data-test-id]="button.dataTestId ?? button.name"
        [ngClass]="button.hasClass"
      >
        @if (button.hasColorPreface) {
          <div [ngClass]="button.hasColorPreface">&nbsp;</div>
        }
        @if (button.hasIcon) {
          <mat-icon>{{ button.hasIcon }}</mat-icon>
        }
        <span class="text-ellipsis">{{ button.label }}</span>
      </button>
    }
  }
</mat-menu>
