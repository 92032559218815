<div class="rb-card card">
  <div class="header">
    <div class="header-image">
      @if (icon && !thumbnailId) {
        <mat-icon>{{ icon }}</mat-icon>
      }
      @if (!icon && thumbnailId) {
        <img
          [src]="thumbnailId | fileUrl: thumbnailSource : 'small' : false | async"
          [src-fallback]="thumbnailFallback"
        />
      }
      @if (!icon && !thumbnailId) {
        <img [src]="thumbnailFallback" />
      }
    </div>
    <div class="header-content">
      <div class="header-content-top-row">
        <div class="text">
          <ng-content select="[slot=header-text]"></ng-content>
        </div>
        <div class="icon">
          <ng-content select="[slot=header-icon]"></ng-content>
        </div>
      </div>
      <h3 class="title">
        <ng-content select="[slot=title]"></ng-content>
      </h3>
      <div class="sub-title-content">
        <ng-content select="[slot=sub-title-content]"> </ng-content>
      </div>
    </div>
  </div>

  <div class="address-content">
    <ng-content select="[slot=address-content]"></ng-content>
  </div>

  <div class="footer-content">
    <ng-content select="[slot=footer-content]"> </ng-content>
  </div>
</div>
