import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { RootGlobalState } from '../store/core-ui.definitions';
import { DeviceTypeState } from '../store/global/global.definitions';
import { GlobalSelectors } from '../store/global/global.selectors';

// Breakpoints definitions:
// https://material.angular.io/cdk/layout/overview
/**
 * @deprecated Use global store to get layout instead
 */
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  /*
   * Mainly: We will optimize code for the following
   * Web (xSmall, Small, xSmallOrSmall, MediumOrLarge)
   * Ionic (Phone or Tablet)
   */
  public isXSmallView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // equals phone
  public isSmallView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // equals table
  public isXSmallOrSmallView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // equals phone or table
  public isMediumOrLargeOrXLargeView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  ); // equals desktop
  /*
   * Additional for specific cases
   */
  public isMediumView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLargeView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isXLargeView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /*
   * Device Detection
   */
  public isIonic = false; // for ionic app
  public isTabletDevice = false; // for ionic app table
  /*
   * This flag is used to test mobile app features in the browser during development and e2e testing.
   * Among other changes, it replaces the SQLite APi with a in-memory database running in the browser.
   * The value is set in localStorage directly to bypass bootup race conditions and make it editable
   * from outside the apps logic (like in cypress scripts).
   */
  public isSimulatedIonic: string | null = null;

  /*
   * Additional for specific cases
   */
  public isMobileWeb: boolean = false;
  public isAndroid: boolean = false;
  public isIos: boolean = false;

  constructor(private readonly store: Store<RootGlobalState>) {}

  public initialize(deviceType: DeviceTypeState): void {
    this.isIonic = deviceType.isIonic;
    this.isTabletDevice = deviceType.isTabletDevice;
    this.isSimulatedIonic = deviceType.simulatedIonicType ?? null;
    this.isMobileWeb = deviceType.isMobileWeb;
    this.isAndroid = deviceType.isAndroid;
    this.isIos = deviceType.isIos;
    this.store.select(GlobalSelectors.selectIsXSmallView).subscribe(this.isXSmallView);
    this.store.select(GlobalSelectors.selectIsSmallView).subscribe(this.isSmallView);
    this.store
      .select(GlobalSelectors.selectIsXSmallOrSmallView)
      .subscribe(this.isXSmallOrSmallView);
    this.store
      .select(GlobalSelectors.selectIsMediumOrLargeOrXLargeView)
      .subscribe(this.isMediumOrLargeOrXLargeView);
    this.store.select(GlobalSelectors.selectIsMediumView).subscribe(this.isMediumView);
    this.store.select(GlobalSelectors.selectIsLargeView).subscribe(this.isLargeView);
    this.store.select(GlobalSelectors.selectIsXLargeView).subscribe(this.isXLargeView);
  }
}
