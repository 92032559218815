import { AsyncPipe } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialModule } from '@remberg/global/ui';
import { GlobalSelectors, RootGlobalState } from '@remberg/ui-core/core';

@Component({
  templateUrl: './update-required.component.html',
  styleUrls: ['./update-required.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MaterialModule, AsyncPipe],
})
export class UpdateRequiredComponent {
  protected readonly deviceType$ = this.store.select(GlobalSelectors.selectDeviceType);

  constructor(private readonly store: Store<RootGlobalState>) {}
}
