import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UnknownOr, isUnknownObject } from '@remberg/global/common/core';
import {
  ApiResponse,
  EmptyListType,
  MultiSelectFunctions,
  MultiSelectModalOptionLabel,
} from '@remberg/global/ui';
import { UserGroupBasic, mapToUserGroupBasic } from '@remberg/users/common/main';
import { UserGroupService } from '@remberg/users/ui/clients';
import { Observable, map } from 'rxjs';
import {
  BaseMultiselectComponent,
  BaseMultiselectComponentTranslations,
} from '../base-multiselect/base-multiselect.component';

@Component({
  selector: 'app-user-group-multiselect',
  templateUrl: '../base-multiselect/base-multiselect.component.html',
  styleUrls: ['../base-multiselect/base-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGroupMultiselectComponent extends BaseMultiselectComponent<
  UnknownOr<UserGroupBasic>
> {
  @Input() public titleIcon: string = 'group';
  @Input() public titleIconSvg = '';
  @Input() public translations: BaseMultiselectComponentTranslations = {
    title: $localize`:@@userGroups:User Groups`,
  };

  @Input() public tenantId?: string;
  @Input() public disabledOptions: string[] = [];

  public readonly emptyListType: EmptyListType = 'users';

  private getModalOptionLabel = (
    userGroup: UnknownOr<UserGroupBasic>,
  ): MultiSelectModalOptionLabel => ({
    label: isUnknownObject(userGroup) ? '' : `${userGroup.name}`,
  });

  private getOptionLabel = (userGroup: UnknownOr<UserGroupBasic>): string =>
    this.getModalOptionLabel(userGroup).label;

  private getChipLabel = (userGroup: UnknownOr<UserGroupBasic>): string =>
    this.getOptionLabel(userGroup);

  private getIsOptionDisabled = (userGroup: UnknownOr<UserGroupBasic>): boolean =>
    this.disabledOptions.includes(userGroup?._id);

  private fetchItemsWithCount = (search: string): Observable<ApiResponse<UserGroupBasic[]>> =>
    this.userGroupService.getGroupsByTenant(this.tenantId, search).pipe(
      map((res) => ({
        data: res.map((userGroup) => mapToUserGroupBasic(userGroup)),
        count: res.length,
      })),
    );

  protected readonly functions: MultiSelectFunctions<UnknownOr<UserGroupBasic>> = {
    getChipLabel: this.getChipLabel,
    getOptionLabel: this.getOptionLabel,
    getModalOptionLabel: this.getModalOptionLabel,
    fetchListValuesWithCountFunction: this.fetchItemsWithCount,
    getIsOptionDisabled: this.getIsOptionDisabled,
  };

  constructor(private readonly userGroupService: UserGroupService) {
    super();
  }
}
