import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memoizeSingleInput',
  standalone: true,
})
export class MemoizeSingleInputPipe implements PipeTransform {
  public transform<K, T>(arg: K, fn: (arg: K) => T): T {
    return fn(arg);
  }
}
