<mat-form-field
  appearance="outline"
  floatLabel="always"
  class="my-mat-form-field phone-number-input-field"
  [ngClass]="{ 'my-mat-form-field-full-width': fullWidth }"
  [class.mat-form-field-invalid]="(showError || isInErrorState) && errorMessage"
>
  <mat-label>{{ label }}</mat-label>

  <input
    [type]="layout.isAndroid || layout.isIos ? 'tel' : 'text'"
    matInput
    [disabled]="disabled"
    [required]="required"
    [minlength]="minLength ?? null"
    [maxlength]="maxLength ?? null"
    [pattern]="phoneNumberRegex"
    [formControl]="numberControl"
    data-test-id="phone-number-input"
  />

  <!-- Country button -->
  @if (countryCodeWithFallback$ | async; as countryCode) {
    <span
      matPrefix
      class="country-code-prefix"
      [ngClass]="{ 'disabled-color': disabled }"
      data-test-id="phone-number-country-code-span"
      >+{{ countryCode[2] }}</span
    >
  }

  <!-- Wojciech:
   Even though conditions are the same, they are split on purpose.
   If @if node has more than one child, the (in this case mat-stroke-button's) content projection
   will not pick up child node of the @if.
   -->
  @if (countryCodeWithFallback$ | async; as countryCode) {
    <button
      matSuffix
      mat-button
      class="country-code"
      [disabled]="disabled"
      (click)="openCountryCodeSelect()"
      data-test-id="phone-number-country-code-button"
    >
      <div class="flex-row country-code-button" [ngClass]="{ 'disabled-color': disabled }">
        {{ countryCode[1] | uppercase }}
        <mat-icon [ngClass]="{ 'disabled-color': disabled }" class="country-code-icon"
          >arrow_drop_down</mat-icon
        >
      </div>
    </button>
  }

  <!-- Errors -->
  @if ((showError || isInErrorState) && errorMessage) {
    <mat-error [@transitionMessages]="animationState">{{ errorMessage }}</mat-error>
  }
</mat-form-field>

<div class="select">
  <mat-select
    class="mat-mdc-option-force-primary"
    #countryCodeSelect
    disableOptionCentering
    [compareWith]="compareWith"
    [value]="countryCodeWithFallback$ | async"
    (selectionChange)="countryCodeSelected($event)"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="translations.search"
        [noEntriesFoundLabel]="translations.noEntriesFound"
        [disableInitialFocus]="false"
        ngModel
        (ngModelChange)="filterCountryCodeOptions($event)"
      >
      </ngx-mat-select-search>
    </mat-option>
    @for (countryCode of filteredCountryCodeOptions; track countryCode) {
      <mat-option [value]="countryCode" attr.data-test-id="option-key-{{ countryCode[2] }}">
        <div class="country-code-option">
          <span class="text-ellipsis"
            >{{ countryCode[0] }} ({{ countryCode[1].toUpperCase() }})</span
          >
          <span class="country-code-option-number">+{{ countryCode[2] }}</span>
        </div>
      </mat-option>
    }
  </mat-select>
</div>
