<div>
  <mat-accordion multi>
    <ul class="top-ul">
      <ng-container *ngTemplateOutlet="configOptionList; context: { list: config }"> </ng-container>
    </ul>
  </mat-accordion>
</div>

<ng-template #configOptionList let-list="list" let-parentOption="parentOption">
  @for (option of list; track option) {
    <section class="section" [attr.data-test-id]="'expansion-section-' + option.name">
      <li>
        @if (option.subOptions?.length > 0) {
          <mat-expansion-panel
            displayMode="flat"
            class="mat-expansion-panel-no-card parameters-panel"
            style="border: 0px !important; margin: 0"
          >
            <mat-expansion-panel-header>
              <mat-checkbox
                class="checkbox-text"
                [(ngModel)]="option.checked"
                [checked]="isSet(option.subOptions, false)"
                (change)="setAll($event, option)"
                (click)="onCheckClick($event)"
                [indeterminate]="isSet(option.subOptions, true)"
                [attr.data-test-id]="'export-property-' + option.name"
              >
                {{ option.translation }}
              </mat-checkbox>
            </mat-expansion-panel-header>
            @if (option.subOptions?.length > 0) {
              <span>
                <ul>
                  <ng-container
                    *ngTemplateOutlet="configOptionList; context: { list: option.subOptions }"
                  ></ng-container>
                </ul>
              </span>
            }
          </mat-expansion-panel>
        } @else {
          <span>
            <mat-checkbox
              [(ngModel)]="option.checked"
              (change)="updateResultingConfig()"
              class="checkbox-text"
              [attr.data-test-id]="'export-property-' + option.name"
            >
              {{ option.translation }}
            </mat-checkbox>
          </span>
        }
      </li>
    </section>
  }
</ng-template>
