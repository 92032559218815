import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { MultiLanguageText } from '@remberg/global/common/core';
import { getLocaleText } from '../helpers/getLocalText';

@Pipe({
  name: 'localizeMultiLanguageText',
  standalone: true,
})
export class LocalizeMultiLanguageTextPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) protected locale: string) {}

  public transform(value: string | MultiLanguageText | undefined, args?: string[]): string {
    return getLocaleText(value, this.locale, args && args[0] ? args[0] : undefined) ?? '';
  }
}
