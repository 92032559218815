/* eslint-disable max-params */
import { inject, Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AdvancedFilterConcatOperatorEnum,
  AdvancedFilterOperatorEnum,
} from '@remberg/advanced-filters/common/main';
import { AssetBasic } from '@remberg/assets/common/base';
import { mapAssetToAssetBasic } from '@remberg/assets/common/main';
import { ASSETS_OFFLINE_SERVICE } from '@remberg/assets/ui/clients';
import { OrganizationBasic } from '@remberg/crm/common/base';
import {
  ContactRaw,
  contactRawToContactBasic,
  organizationRawToOrganizationBasic,
} from '@remberg/crm/common/main';
import {
  CONTACTS_OFFLINE_SERVICE,
  OrganizationOfflineServiceInterface,
  ORGANIZATIONS_OFFLINE_SERVICE,
} from '@remberg/crm/ui/clients';
import {
  FormInstanceCreateFromEmptyBody,
  FormInstanceCreateFromExistingBody,
  FormInstanceEmailStatus,
  FormInstanceFilterFieldEnum,
  FormInstancePopulateFieldEnum,
  FormInstanceTableQuery,
  FormInstanceTableResponse,
  FormInstanceUpdateBody,
} from '@remberg/forms/common/dtos';
import {
  FormEmailSectionData,
  FormInstance,
  FormInstanceAdvancedFilter,
  FormInstanceAdvancedFilterFieldEnum,
  FormInstanceAdvancedFilterObject,
  FormInstanceEmailStatusEnum,
  FormInstancePrefillingService,
  FormInstanceRaw,
  FormInstanceStatusEnum,
  FormInstanceTableElement,
  FormSectionTypesEnum,
  FormTemplateConfig,
  getFormInstanceRelatedAssets,
  getFormInstanceRelatedOrganizations,
  PrefillingInputs,
} from '@remberg/forms/common/main';
import {
  FORM_TEMPLATE_OFFLINE_SERVICE,
  FormInstanceOfflineServiceInterface,
  FormTemplateOfflineServiceInterface,
} from '@remberg/forms/ui/clients';
import {
  assertDefined,
  createUnknownObject,
  CreationTypeEnum,
  isDefined,
  UnknownOr,
  UnreachableCaseError,
} from '@remberg/global/common/core';
import {
  DataResponse,
  getBrowserTimezone,
  getStringID,
  hasOwnProperty,
  LocalStorageKeys,
  LogService,
  OfflinePopulateType,
  OnlineStatusDataTypeEnum,
  SQLQueryParams,
  SQLSortDirection,
  SyncDataTypesEnum,
} from '@remberg/global/ui';
import { createFormInstance, getIsoLanguageCodeValidated } from '@remberg/ui-core/offline';
import { firstValueFrom } from 'rxjs';
import { getInstanceNumber } from '../../helpers/get-instance-number';
import {
  concatSQLFiltersByOperator,
  generateContainsSQLFilter,
  generateDoesNotContainSQLFilter,
  generateEqualsSQLFilter,
  generateIsEmptySQLFilter,
  generateNotEmptySQLFilter,
  generateNotEqualsSQLFilter,
  SQLConcatOperator,
  sqlFiltersHelper,
} from '../../helpers/sqlFiltersHelper';
import { RootGlobalState } from '../../store/core-ui.definitions';
import { GlobalSelectors } from '../../store/global/global.selectors';
import { AppStateService } from '../app-state.service';
import { BaseOfflineService } from '../base.offline.service';
import { toRembergDateTime } from '../datetime.service';
import { SqlDBService } from '../sqlDB.service';
import { EmailStatusOfflineService } from './emailstatus.offline.service';
import { FormInstanceTableColumnEnum } from './form-instance-offline-service.definitions';
import { FormInstancePopulateOfflineService } from './formInstancePopulate.offline.service';
import { FormTemplateVersionOfflineService } from './formTemplateVersion.offline.service';
import { OrganizationOfflineService } from './organization.offline.service';
import { dataTypeToServiceMap } from './populate-array.helper';
import { FORMS_PREFILLING_SERVICE_2 } from './prefilling';

export const FORM_INSTANCE_FILTER_ENUM_TO_COLUMN_NAME: Record<
  FormInstanceAdvancedFilterFieldEnum,
  FormInstanceTableColumnEnum | 'CUSTOM_LOGIC'
> = {
  [FormInstanceAdvancedFilterFieldEnum.ASSIGNEE]: FormInstanceTableColumnEnum.ASSIGNEE_ID,
  [FormInstanceAdvancedFilterFieldEnum.FORM_TEMPLATE]: FormInstanceTableColumnEnum.FORM_TEMPLATE_ID,
  [FormInstanceAdvancedFilterFieldEnum.RELATED_ASSETS]:
    FormInstanceTableColumnEnum.RELATED_ASSET_IDS,
  [FormInstanceAdvancedFilterFieldEnum.RELATED_ORGANIZATIONS]:
    FormInstanceTableColumnEnum.RELATED_ORGANIZATION_IDS,
  [FormInstanceAdvancedFilterFieldEnum.RELATED_WORK_ORDER_2]:
    FormInstanceTableColumnEnum.RELATED_WORK_ORDER_ID_2,
  [FormInstanceAdvancedFilterFieldEnum.STATUS]: FormInstanceTableColumnEnum.STATUS,
  [FormInstanceAdvancedFilterFieldEnum.CREATED_AT]: FormInstanceTableColumnEnum.CREATED_AT,
  [FormInstanceAdvancedFilterFieldEnum.FINALIZED_AT]: FormInstanceTableColumnEnum.FINALIZED_AT,
  // These filter types have a custom logic
  [FormInstanceAdvancedFilterFieldEnum.INVOLVED_CONTACT]: 'CUSTOM_LOGIC',
  [FormInstanceAdvancedFilterFieldEnum.CREATED_BY]: 'CUSTOM_LOGIC',
  [FormInstanceAdvancedFilterFieldEnum.EMAILS]: 'CUSTOM_LOGIC',
};

const params: SQLQueryParams<
  FormInstanceTableColumnEnum,
  FormInstancePopulateFieldEnum,
  keyof FormInstanceTableElement | keyof FormInstance
> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.FORMINSTANCES,
  columns: {
    [FormInstanceTableColumnEnum.STATUS]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => val?.status,
    },
    [FormInstanceTableColumnEnum.FORM_TEMPLATE_ID]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => getStringID(val?.formTemplateId),
    },
    [FormInstanceTableColumnEnum.FORM_TEMPLATE_VERSION_ID]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => getStringID(val?.formTemplateVersionId),
    },
    [FormInstanceTableColumnEnum.RELATED_ASSET_IDS]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => JSON.stringify(val.relatedAssetIds ?? []),
    },
    [FormInstanceTableColumnEnum.RELATED_ORGANIZATION_IDS]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => JSON.stringify(val.relatedOrganizationIds ?? []),
    },
    [FormInstanceTableColumnEnum.CREATED_BY_ID]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => getStringID(val?.createdById),
    },
    [FormInstanceTableColumnEnum.ASSIGNEE_ID]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => getStringID(val?.assigneeId),
    },
    [FormInstanceTableColumnEnum.UPDATED_BY_ID]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => getStringID(val?.updatedById),
    },
    [FormInstanceTableColumnEnum.DATE_MODIFIED]: {
      type: 'DATETIME',
      valueFunction: (val: FormInstanceRaw) => val?.dateModified,
    },
    [FormInstanceTableColumnEnum.FINALIZED_AT]: {
      type: 'DATETIME',
      valueFunction: (val: FormInstanceRaw) => val?.finalizedAt,
    },
    [FormInstanceTableColumnEnum.CREATED_AT]: {
      type: 'DATETIME',
      valueFunction: (val: FormInstanceRaw) => val.createdAt,
    },
    [FormInstanceTableColumnEnum.CREATION_TYPE]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => val.creationType,
    },
    [FormInstanceTableColumnEnum.COUNTER]: {
      type: 'INTEGER',
      valueFunction: (val: FormInstanceRaw) => val?.counter,
    },
    [FormInstanceTableColumnEnum.NAME]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => val?.name?.trim(),
      sortNoCase: true,
    },
    [FormInstanceTableColumnEnum.RELATED_WORK_ORDER_ID_2]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => val?.relatedWorkOrderId2,
    },
    [FormInstanceTableColumnEnum.PDF_LANGUAGE]: {
      type: 'TEXT',
      valueFunction: (val: FormInstanceRaw) => val?.pdfLanguage?.trim(),
      sortNoCase: true,
    },
  },
  populates: {
    [FormInstancePopulateFieldEnum.ASSIGNEE]: {
      targetKey: 'assignee',
      populateFunction: async (val: FormInstanceRaw) =>
        val.assigneeId
          ? contactRawToContactBasic(
              (await dataTypeToServiceMap[SyncDataTypesEnum.CONTACTS]?.tryGetInstance(
                val.assigneeId,
              )) as ContactRaw | undefined,
            )
          : undefined,
    },
    [FormInstancePopulateFieldEnum.CREATED_BY]: {
      targetKey: 'createdBy',
      populateFunction: async (val: FormInstanceRaw) => {
        if (!val.createdById) return;

        switch (val.creationType) {
          case CreationTypeEnum.USER: {
            const contactsOfflineService = inject(CONTACTS_OFFLINE_SERVICE);
            return contactsOfflineService.tryGetInstance(val.createdById);
          }
        }

        // Other creation types not supported in offline mode for now
        return undefined;
      },
    },
    [FormInstancePopulateFieldEnum.UPDATED_BY]: {
      targetKey: 'updatedBy',
      populateFunction: async (val: FormInstanceRaw) =>
        val.updatedById
          ? contactRawToContactBasic(
              (await dataTypeToServiceMap[SyncDataTypesEnum.CONTACTS]?.tryGetInstance(
                val.updatedById,
              )) as ContactRaw | undefined,
            )
          : undefined,
    },

    [FormInstancePopulateFieldEnum.RELATED_WORK_ORDER_2]: {
      targetKey: 'relatedWorkOrder2',
      populateFunction: async (val: FormInstanceRaw) =>
        val.relatedWorkOrderId2
          ? await dataTypeToServiceMap[SyncDataTypesEnum.WORKORDERS2]?.tryGetInstance(
              val.relatedWorkOrderId2,
            )
          : undefined,
    },
    [FormInstancePopulateFieldEnum.FORM_TEMPLATE]: {
      targetKey: 'formTemplate',
      populateFunction: async (val: FormInstanceRaw) =>
        val.formTemplateId
          ? await dataTypeToServiceMap[SyncDataTypesEnum.FORMTEMPLATES]?.tryGetInstance(
              val.formTemplateId,
            )
          : undefined,
    },
    [FormInstancePopulateFieldEnum.FORM_TEMPLATE_VERSION]: {
      targetKey: 'formTemplateVersion',
      populateFunction: async (val: FormInstanceRaw) =>
        val.formTemplateVersionId
          ? await dataTypeToServiceMap[SyncDataTypesEnum.FORMTEMPLATEVERSIONS]?.tryGetInstance(
              val.formTemplateVersionId,
            )
          : undefined,
    },
    [FormInstancePopulateFieldEnum.EMAIL_STATUSES]: {
      targetKey: 'emailStatuses',
      populateFunction: async (val: FormInstanceRaw) => {
        const emailStatusDict = await (
          dataTypeToServiceMap[SyncDataTypesEnum.FORMINSTANCES] as
            | FormInstanceOfflineService
            | undefined
        )?.getEmailStatusesForFormInstance(val);

        return emailStatusDict;
      },
    },
    [FormInstancePopulateFieldEnum.RELATED_ORGANIZATIONS]: {
      targetKey: 'relatedOrganizations',
      populateFunction: async (val: FormInstanceRaw) => {
        const result: OrganizationBasic[] = [];
        for (const organizationId of val.relatedOrganizationIds) {
          const organizationBasic = organizationRawToOrganizationBasic(
            await (
              dataTypeToServiceMap[SyncDataTypesEnum.ORGANIZATIONS] as
                | OrganizationOfflineService
                | undefined
            )?.tryGetInstance(organizationId),
          );
          if (organizationBasic) {
            result.push(organizationBasic);
          }
        }
        return result;
      },
    },
    [FormInstancePopulateFieldEnum.RELATED_ASSETS]: {
      targetKey: 'relatedAssets',
      populateFunction: async (val: FormInstanceRaw): Promise<UnknownOr<AssetBasic>[]> => {
        const assetsOfflineService = inject(ASSETS_OFFLINE_SERVICE);

        const assets = (await assetsOfflineService.findManyByIds(val.relatedAssetIds)).map(
          (asset) => mapAssetToAssetBasic(asset),
        );
        return val.relatedAssetIds.map(
          (assetId) =>
            assets.find((asset) => asset._id === assetId) ?? createUnknownObject(assetId),
        );
      },
    },
  },
};

@Injectable()
export class FormInstanceOfflineService
  extends BaseOfflineService<FormInstanceRaw, FormInstanceFilterFieldEnum>
  implements FormInstanceOfflineServiceInterface
{
  constructor(
    dbService: SqlDBService,
    logger: LogService,
    store: Store<RootGlobalState>,
    private readonly appState: AppStateService,
    @Inject(ORGANIZATIONS_OFFLINE_SERVICE)
    private readonly organizationService: OrganizationOfflineServiceInterface,
    @Inject(FORM_TEMPLATE_OFFLINE_SERVICE)
    private readonly formTemplateService: FormTemplateOfflineServiceInterface,
    private readonly formTemplateVersionService: FormTemplateVersionOfflineService,
    private readonly populateService: FormInstancePopulateOfflineService,
    @Inject(FORMS_PREFILLING_SERVICE_2)
    private readonly prefillingService2: FormInstancePrefillingService,
    private readonly emailStatusOfflineService: EmailStatusOfflineService,
  ) {
    super(dbService, params, logger, store);
  }

  public async createFromEmpty(
    formInstanceMeta: FormInstanceCreateFromEmptyBody,
  ): Promise<FormInstanceRaw> {
    const contact = await firstValueFrom(this.store.select(GlobalSelectors.selectCurrentContact));
    const tenantId = await firstValueFrom(this.store.select(GlobalSelectors.selectTenantId));

    assertDefined(contact, 'Failed to create new form instance. Current user is undefined');
    assertDefined(tenantId, 'Failed to create new form instance. Current tenantId is undefined');

    const formTemplate = await this.formTemplateService.getInstancePopulated(
      formInstanceMeta.formTemplateId,
      true,
      true,
    );

    // Create a new empty form instance
    const newFormInstance = createFormInstance(contact, tenantId, formTemplate, formInstanceMeta);

    // Prefill
    const formTemplateConfig = formTemplate.formTemplateVersion?.formTemplateConfig;
    assertDefined(
      formTemplateConfig,
      'Failed to create new form instance. FormTemplateConfig is undefined',
    );

    const timezone = getBrowserTimezone();
    const organization = await this.organizationService.tryGetInstance(contact.organizationId);

    const prefillingInputs: PrefillingInputs = {
      assetId: formInstanceMeta.assetId,
      assignedUserId: formInstanceMeta.assigneeId,
      organizationId: formInstanceMeta.relatedOrganizationId,
      workOrderId: formInstanceMeta.relatedWorkOrderId2,

      creationDateTime: toRembergDateTime(
        newFormInstance.createdAt,
        timezone ?? organization?.crmData.tz,
      ),
      currentUser: contact,
      currentUserId: contact._id,
      currentAccountId: organization?._id,
      currentAccount: organization,
    };

    newFormInstance.data = await this.prefillingService2.prefill(
      formTemplateConfig,
      newFormInstance.data,
      prefillingInputs,
    );

    // Update related entity ids
    newFormInstance.relatedAssetIds = getFormInstanceRelatedAssets<false>(
      formTemplateConfig,
      newFormInstance.data,
    );
    newFormInstance.relatedOrganizationIds = getFormInstanceRelatedOrganizations<false>(
      formTemplateConfig,
      newFormInstance.data,
    );

    // Save and assign a draftId
    const result = await this.addInstance(
      newFormInstance,
      OnlineStatusDataTypeEnum.OFFLINE_CREATION,
    );
    if (!result) {
      throw new Error('Failed to create empty FormInstance!');
    }
    result.draftId = await this.createDraftId(newFormInstance._id);

    return result.data;
  }

  public async createFromExisting(
    formInstance: FormInstanceCreateFromExistingBody,
  ): Promise<FormInstanceRaw> {
    const contact = await firstValueFrom(this.store.select(GlobalSelectors.selectContactRaw));

    assertDefined(contact, 'Failed to duplicate form instance. Current user is undefined');

    const formTemplateVersion = await this.formTemplateVersionService.getInstance(
      formInstance.formTemplateVersionId,
    );
    const formTemplateConfig = formTemplateVersion?.formTemplateConfig;

    assertDefined(
      formTemplateConfig,
      'Failed to create new form instance. FormTemplateConfig is undefined',
    );

    // Update related entity ids
    const relatedAssetIds = getFormInstanceRelatedAssets<false>(
      formTemplateConfig,
      formInstance.data,
    );
    const relatedOrganizationIds = getFormInstanceRelatedOrganizations<false>(
      formTemplateConfig,
      formInstance.data,
    );

    const createdAt = new Date().toISOString();

    const { _id, name, formTemplateId, data, pdfLanguage, assigneeId, relatedWorkOrderId2 } =
      formInstance;

    const newFormInstance: FormInstanceRaw = {
      _id,
      name,
      formTemplateId,
      pdfLanguage: getIsoLanguageCodeValidated(
        pdfLanguage,
        formTemplateVersion.allowedPdfLanguages,
      ),
      relatedOrganizationIds,
      relatedAssetIds,
      data,
      relatedWorkOrderId2,
      assigneeId,
      counter: 0,
      createdById: contact._id,
      createdAt: createdAt,
      updatedAt: createdAt,
      dateModified: createdAt,
      formTemplateVersionId: formTemplateVersion._id,
      status: FormInstanceStatusEnum.IN_PROGRESS,
      updatedById: contact._id,
      tenantId: contact.tenantId,
      organizationId: contact.organizationId,
    };

    // Save and assign a draftId
    const result = await this.addInstance(
      newFormInstance,
      OnlineStatusDataTypeEnum.OFFLINE_CREATION,
    );
    assertDefined(result, 'Failed to create new form instance. Creation result is undefined');
    result.draftId = await this.createDraftId(newFormInstance._id);

    return result.data;
  }

  public async updateOne(
    update: FormInstanceUpdateBody,
    onlineStatus: OnlineStatusDataTypeEnum.ONLINE | OnlineStatusDataTypeEnum.OFFLINE_CHANGE,
  ): Promise<OnlineStatusDataTypeEnum> {
    const contact = await firstValueFrom(this.store.select(GlobalSelectors.selectContactRaw));

    const instance = await this.tryGetInstance(update._id);
    const dateModified = new Date().toISOString();

    assertDefined(
      contact,
      `Failed to update form instance "${update._id}". Current user is undefined`,
    );

    assertDefined(
      instance,
      `Failed to update form instance "${update._id}". The instance is not found in the offline DB`,
    );

    const formTemplateConfig = (
      await this.formTemplateVersionService.tryGetInstance(instance.formTemplateVersionId, false)
    )?.formTemplateConfig;

    assertDefined(
      formTemplateConfig,
      `Failed to update form instance "${update._id}". The formTemplateConfig is not found in the offline DB`,
    );

    const data = update.data ?? instance.data;

    const updatedInstance: FormInstanceRaw = {
      ...instance,
      name: (hasOwnProperty(update, 'name') ? update.name : instance.name) ?? '',
      assigneeId: hasOwnProperty(update, 'assigneeId')
        ? (update.assigneeId ?? undefined)
        : instance.assigneeId,
      relatedWorkOrderId2: hasOwnProperty(update, 'relatedWorkOrderId2')
        ? (update.relatedWorkOrderId2 ?? undefined)
        : instance.relatedWorkOrderId2,
      status: update.status ?? instance.status,
      data,
      dateModified,
      updatedById: contact._id,
      finalizedAt:
        instance.status !== FormInstanceStatusEnum.FINALIZED &&
        update.status === FormInstanceStatusEnum.FINALIZED
          ? dateModified
          : instance.finalizedAt,
      relatedAssetIds: getFormInstanceRelatedAssets<false>(formTemplateConfig, data),
      relatedOrganizationIds: getFormInstanceRelatedOrganizations<false>(formTemplateConfig, data),
    };

    const result = await this.updateInstance(updatedInstance, onlineStatus);

    // look for new emails in the instance and update emailstatus.offline.service
    if (update.data) {
      const newDtos = this.getEmailStatusDtoFromInstance(formTemplateConfig, updatedInstance);
      const oldDtos = this.getEmailStatusDtoFromInstance(formTemplateConfig, instance);

      const newIds = newDtos.map((dto) => dto._id);
      const oldIds = oldDtos.map((dto) => dto._id);

      // add new EmailDTO's
      const newStatusesDtos = newDtos.filter((dto) => !oldIds.includes(dto._id));
      for (const status of newStatusesDtos) {
        this.emailStatusOfflineService.addInstance(status);
      }

      // remove EmailDTO when deleted
      const deletedStatusesDtos = oldDtos.filter((dto) => !newIds.includes(dto._id));
      for (const status of deletedStatusesDtos) {
        this.emailStatusOfflineService.deleteInstance(status);
      }

      // check for status changes on existing emails
      const commonDtos = newDtos.filter((dto) => oldIds.includes(dto._id));
      for (const commonDto of commonDtos) {
        const oldDto = oldDtos.find((dto) => dto._id == commonDto._id);
        if (commonDto.status !== oldDto?.status) {
          this.emailStatusOfflineService.updateInstance(commonDto);
        }
      }
    }

    // We should return the status from the result,
    // because it can be OnlineStatusDataTypeEnum.OFFLINE_CREATION
    return result?.onlineStatus || OnlineStatusDataTypeEnum.OFFLINE_CHANGE;
  }

  public async deleteOne(formInstanceId: string): Promise<void> {
    const currentInstance = await this.getInstanceAsDataResponse(formInstanceId);
    if (currentInstance) {
      // remove instance from local db (async)
      await this.deleteInstance(formInstanceId);

      if (currentInstance.onlineStatus !== OnlineStatusDataTypeEnum.OFFLINE_CREATION) {
        // add deletion to deletions in order to be pushed upon reconnect
        const deletedIds = this.getDeletedInstanceIds();
        deletedIds.push(formInstanceId);
        await this.setDeletedInstanceIds(deletedIds);
      }
    }
  }

  public async getOneForDetailPage(
    formInstanceId: string,
  ): Promise<DataResponse<FormInstance> | undefined> {
    // Get raw object
    const dataResponse = await this.getInstanceAsDataResponse(formInstanceId, {
      [FormInstancePopulateFieldEnum.FORM_TEMPLATE_VERSION]: true,
      [FormInstancePopulateFieldEnum.FORM_TEMPLATE]: true,
      [FormInstancePopulateFieldEnum.ASSIGNEE]: true,
      [FormInstancePopulateFieldEnum.CREATED_BY]: true,
      [FormInstancePopulateFieldEnum.UPDATED_BY]: true,
      [FormInstancePopulateFieldEnum.RELATED_WORK_ORDER_2]: true,
      [FormInstancePopulateFieldEnum.EMAIL_STATUSES]: true,
    });

    if (!dataResponse) {
      return undefined;
    }

    // Set draft id
    if (dataResponse.onlineStatus === OnlineStatusDataTypeEnum.OFFLINE_CREATION) {
      const draftIds = this.getDraftIds();
      dataResponse.draftId = draftIds[formInstanceId];
    }

    // Populate data
    const { data: formInstance, ...otherProperties } = dataResponse;

    const formTemplateConfig = (
      await this.formTemplateVersionService.tryGetInstance(
        formInstance.formTemplateVersionId,
        false,
      )
    )?.formTemplateConfig;

    assertDefined(
      formTemplateConfig,
      `Failed to get form instance "${formInstanceId}". The formTemplateConfig is not found in the offline DB`,
    );

    const populatedData = await this.populateService.populateFormInstanceData(
      formTemplateConfig,
      formInstance.data,
    );

    return {
      ...otherProperties,
      data: { ...formInstance, data: populatedData },
    } as unknown as DataResponse<FormInstance>;
  }

  public async getManyForList(query: FormInstanceTableQuery): Promise<FormInstanceTableResponse> {
    const filterStrings: string[] = [];

    const advancedFilterString = this.getAdvancedFiltersString(query.filterObject);
    if (advancedFilterString) {
      filterStrings.push(advancedFilterString);
    }

    const staticFilters = this.getStaticFiltersString(query.staticFilters);
    if (staticFilters.length) {
      filterStrings.push(...staticFilters);
    }

    if (query.search) {
      const searchQuery = toSqlSearchFilter(query.search);

      if (searchQuery) {
        filterStrings.push(searchQuery);
      }
    }

    const populateParameters: OfflinePopulateType = {};
    for (const populateParam of query.populate ?? []) {
      populateParameters[populateParam] = true;
    }

    const result = await this.getInstancesWithCount(
      query.limit,
      query.page,
      query.sortField,
      query.sortDirection === 'desc' ? SQLSortDirection.DESC : SQLSortDirection.ASC,
      concatSQLFiltersByOperator(filterStrings, SQLConcatOperator.AND),
      populateParameters,
    );
    const draftIds = this.getDraftIds();

    return {
      count: result.count as number,
      data: result.data.map((instance) => ({ ...instance, draftId: draftIds[instance._id] })),
    };
  }

  public async getInProgressCountByFormTemplateId(formTemplateId: string): Promise<number> {
    return await this.countInstances(
      `${FormInstanceTableColumnEnum.FORM_TEMPLATE_ID}='${formTemplateId}' AND ${FormInstanceTableColumnEnum.STATUS}='${FormInstanceStatusEnum.IN_PROGRESS}'`,
    );
  }

  public async getEmailStatusesForFormInstance(
    formInstanceOrId: string | FormInstanceRaw,
  ): Promise<Record<string, FormInstanceEmailStatusEnum>> {
    const formInstance =
      typeof formInstanceOrId === 'string'
        ? await this.tryGetInstance(formInstanceOrId)
        : formInstanceOrId;

    if (!formInstance) {
      this.logger.error()(
        `Failed to get email statuses for the form instance with the id "${formInstanceOrId}". Form instance is not found`,
      );

      return {};
    }

    const formTemplateConfig = (
      await (
        dataTypeToServiceMap[SyncDataTypesEnum.FORMTEMPLATEVERSIONS] as
          | FormTemplateVersionOfflineService
          | undefined
      )?.tryGetInstance(formInstance.formTemplateVersionId)
    )?.formTemplateConfig;

    if (!formTemplateConfig) {
      this.logger.error()(
        `Failed to get email statuses for the form instance with the id "${formInstanceOrId}". Form template config is not found. formTemplateVersionId: ${formInstance.formTemplateVersionId}`,
      );

      return {};
    }

    const emailIds = this.getEmailStatusDtoFromInstance(formTemplateConfig, formInstance).map(
      (dto) => dto._id,
    );

    return await (
      dataTypeToServiceMap[SyncDataTypesEnum.EMAILSTATUSES] as EmailStatusOfflineService
    ).getManyByIds(emailIds);
  }

  public getDeletedInstanceIds(): string[] {
    const ids = this.appState.getValue(LocalStorageKeys.OFFLINE_FORM_DELETED_INSTANCES);

    return ids ? JSON.parse(ids) : [];
  }

  public async setDeletedInstanceIds(value: string[]): Promise<void> {
    await this.appState.setValue(
      LocalStorageKeys.OFFLINE_FORM_DELETED_INSTANCES,
      JSON.stringify(value),
    );
  }

  private async createDraftId(formInstanceId: string): Promise<number> {
    const draftIds = this.getDraftIds();
    const counter = Object.keys(draftIds).length + 1;
    draftIds[formInstanceId] = counter;
    await this.setDraftIds(draftIds);

    return counter;
  }

  public getDraftIds(): Record<string, number | undefined> {
    const draftsIds = this.appState.getValue(LocalStorageKeys.OFFLINE_FORM_DRAFT_COUNT_DICT);

    return draftsIds ? JSON.parse(draftsIds) : {};
  }

  public async setDraftIds(draftsIds: Record<string, number | undefined>): Promise<void> {
    await this.appState.setValue(
      LocalStorageKeys.OFFLINE_FORM_DRAFT_COUNT_DICT,
      JSON.stringify(draftsIds),
    );
  }

  /**
   * This helper function returns EmailStatusDtos from a FormInstanceRaw
   */
  private getEmailStatusDtoFromInstance(
    formTemplateConfig: FormTemplateConfig,
    formInstance: FormInstanceRaw,
  ): FormInstanceEmailStatus[] {
    return formTemplateConfig.sections.reduce((result, section, sectionIdx) => {
      if (section.type === FormSectionTypesEnum.EMAIL_SECTION) {
        const sectionData = formInstance.data[sectionIdx] as FormEmailSectionData;
        for (const email of sectionData.emailList ?? []) {
          if (email.id) {
            const status: FormInstanceEmailStatusEnum =
              !email.isSent && !email.isDraft
                ? FormInstanceEmailStatusEnum.MARKED_FOR_SENDING
                : email.isDraft
                  ? FormInstanceEmailStatusEnum.DRAFT
                  : FormInstanceEmailStatusEnum.SENT;
            result.push({ _id: email.id, status });
          }
        }
      }
      return result;
    }, [] as FormInstanceEmailStatus[]);
  }

  public override getAdvancedFiltersString(
    filterQuery?: FormInstanceAdvancedFilterObject,
  ): string | undefined {
    if (!filterQuery?.filters.length) {
      return undefined;
    }

    const sqlFilters = filterQuery.filters.map((filter) => this.getAdvancedFilterString(filter));

    return concatSQLFiltersByOperator(
      sqlFilters,
      filterQuery.concatOperator === AdvancedFilterConcatOperatorEnum.OR
        ? SQLConcatOperator.OR
        : SQLConcatOperator.AND,
    );
  }

  public override getStaticFiltersString(staticFilters?: FormInstanceAdvancedFilter[]): string[] {
    return staticFilters?.map((filter) => this.getAdvancedFilterString(filter)) ?? [];
  }

  public override getAdvancedFilterString(filter: FormInstanceAdvancedFilter): string {
    const columnName = FORM_INSTANCE_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
    const tableName = params.tableName;

    switch (filter.identifier) {
      case FormInstanceAdvancedFilterFieldEnum.STATUS:
      case FormInstanceAdvancedFilterFieldEnum.ASSIGNEE:
      case FormInstanceAdvancedFilterFieldEnum.FORM_TEMPLATE:
      case FormInstanceAdvancedFilterFieldEnum.RELATED_WORK_ORDER_2:
      case FormInstanceAdvancedFilterFieldEnum.CREATED_AT:
      case FormInstanceAdvancedFilterFieldEnum.FINALIZED_AT:
        return sqlFiltersHelper(filter, `${tableName}.${columnName}`);
      case FormInstanceAdvancedFilterFieldEnum.RELATED_ORGANIZATIONS:
      case FormInstanceAdvancedFilterFieldEnum.RELATED_ASSETS:
        switch (filter.operator) {
          case AdvancedFilterOperatorEnum.IS:
            return generateContainsSQLFilter(`${tableName}.${columnName}`, filter.value);
          case AdvancedFilterOperatorEnum.IS_NOT:
            return generateDoesNotContainSQLFilter(`${tableName}.${columnName}`, filter.value);
        }
        return sqlFiltersHelper(filter, `${tableName}.${columnName}`);
      case FormInstanceAdvancedFilterFieldEnum.INVOLVED_CONTACT:
        return this.getInvolvedUserAdvancedFilter(filter);
      case FormInstanceAdvancedFilterFieldEnum.CREATED_BY:
        return this.getCreatedByAdvancedFilter(filter);
      // Unsupported in offline mode
      case FormInstanceAdvancedFilterFieldEnum.EMAILS:
        return '';
      default:
        throw new UnreachableCaseError(filter.identifier);
    }
  }

  private getInvolvedUserAdvancedFilter(filter: FormInstanceAdvancedFilter): string {
    const tableName = params.tableName;

    switch (filter.operator) {
      case AdvancedFilterOperatorEnum.IS:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is');
        return concatSQLFiltersByOperator(
          [
            `${generateEqualsSQLFilter(`${tableName}.${FormInstanceTableColumnEnum.ASSIGNEE_ID}`, filter.value)}`,
            `${generateEqualsSQLFilter(`${tableName}.${FormInstanceTableColumnEnum.CREATED_BY_ID}`, filter.value)}`,
          ],
          SQLConcatOperator.OR,
        );
      case AdvancedFilterOperatorEnum.IS_NOT:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is not');
        return concatSQLFiltersByOperator(
          [
            concatSQLFiltersByOperator(
              [
                `${generateNotEqualsSQLFilter(`${tableName}.${FormInstanceTableColumnEnum.ASSIGNEE_ID}`, filter.value)}`,
                generateIsEmptySQLFilter(`${tableName}.${FormInstanceTableColumnEnum.ASSIGNEE_ID}`),
              ],
              SQLConcatOperator.OR,
            ),
            concatSQLFiltersByOperator(
              [
                `${generateNotEqualsSQLFilter(`${tableName}.${FormInstanceTableColumnEnum.CREATED_BY_ID}`, filter.value)}`,
                generateIsEmptySQLFilter(
                  `${tableName}.${FormInstanceTableColumnEnum.CREATED_BY_ID}`,
                ),
              ],
              SQLConcatOperator.OR,
            ),
          ],
          SQLConcatOperator.AND,
        );
      case AdvancedFilterOperatorEnum.IS_EMPTY:
        return concatSQLFiltersByOperator(
          [
            `${generateIsEmptySQLFilter(`${tableName}.${FormInstanceTableColumnEnum.ASSIGNEE_ID}`)}`,
            `${generateIsEmptySQLFilter(`${tableName}.${FormInstanceTableColumnEnum.CREATED_BY_ID}`)}`,
          ],
          SQLConcatOperator.AND,
        );
      case AdvancedFilterOperatorEnum.IS_NOT_EMPTY:
        return concatSQLFiltersByOperator(
          [
            `${generateNotEmptySQLFilter(`${tableName}.${FormInstanceTableColumnEnum.ASSIGNEE_ID}`)}`,
            `${generateNotEmptySQLFilter(`${tableName}.${FormInstanceTableColumnEnum.CREATED_BY_ID}`)}`,
          ],
          SQLConcatOperator.OR,
        );

      default:
        throw new Error(
          `Operator ${filter.operator} can't be used for ${FormInstanceAdvancedFilterFieldEnum.INVOLVED_CONTACT} filter type`,
        );
    }
  }

  private getCreatedByAdvancedFilter(filter: FormInstanceAdvancedFilter): string {
    const tableName = params.tableName;

    let filterString = '';
    switch (filter.operator) {
      case AdvancedFilterOperatorEnum.IS:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is');
        filterString = generateEqualsSQLFilter(
          `${tableName}.${FormInstanceTableColumnEnum.CREATED_BY_ID}`,
          filter.value,
        );
        break;
      case AdvancedFilterOperatorEnum.IS_NOT:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is not');
        filterString = generateNotEqualsSQLFilter(
          `${tableName}.${FormInstanceTableColumnEnum.CREATED_BY_ID}`,
          filter.value,
        );
        break;
      default:
        throw new Error(
          `Operator ${filter.operator} can't be used for ${FormInstanceAdvancedFilterFieldEnum.CREATED_BY} filter type`,
        );
    }

    return concatSQLFiltersByOperator(
      [
        filterString,
        generateEqualsSQLFilter(
          `${tableName}.${FormInstanceTableColumnEnum.CREATION_TYPE}`,
          CreationTypeEnum.USER,
        ),
      ],
      SQLConcatOperator.AND,
    );
  }
}

function toSqlSearchFilter(searchString: string | undefined): string | undefined {
  if (searchString) {
    const instanceNumber = getInstanceNumber(searchString);

    if (isDefined(instanceNumber)) {
      return `(${params.tableName}.${FormInstanceTableColumnEnum.COUNTER} = ${instanceNumber} OR ${params.tableName}.${FormInstanceTableColumnEnum.NAME} LIKE '%${searchString}%')`;
    }

    return `${params.tableName}.${FormInstanceTableColumnEnum.NAME} LIKE '%${searchString}%'`;
  }

  return undefined;
}
