<button
  mat-stroked-button
  class="toolbar-item"
  [matMenuTriggerFor]="visualizationTypeMenu"
  data-test-id="visualization-type-button"
>
  @if (visualizationTypes && currentVisualizationType) {
    <mat-icon>{{ visualizationTypes[currentVisualizationType]?.icon }}</mat-icon>
  }

  <!-- Wojciech:
   Even though conditions are the same, they are split on purpose.
   If @if node has more than one child, the (in this case mat-stroke-button's) content projection
   will not pick up child node of the @if.
   -->
  @if (visualizationTypes && currentVisualizationType) {
    <span class="icon-aligner icon-aligner-responsive">
      {{ visualizationTypes[currentVisualizationType]?.label }}
    </span>
  }
</button>

<mat-menu #visualizationTypeMenu="matMenu" class="menu-no-min-height">
  @if (visualizationTypes) {
    @for (type of visualizationTypes | keyvalue: originalOrder; track type) {
      <button
        mat-menu-item
        class="visualization-option"
        attr.data-test-id="option-{{ type.key }}"
        [class.selected-visualization]="type.key === currentVisualizationType"
        [value]="type.key"
        (click)="visualizationTypeChanged.emit(toVisualizationTypeEnum(type.key))"
      >
        <mat-icon>{{ type.value.icon }}</mat-icon>
        <span class="visualization-type-option-text">{{ type.value.label }}</span>
      </button>
    }
  }
</mat-menu>
