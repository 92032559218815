import { UnreachableCaseError, getDefinedOrThrow } from '@remberg/global/common/core';
import {
  FormEmailDriveConfig,
  FormEmailSectionConfig,
  FormSection,
  FormSectionTypesEnum,
  FormTemplateConfig,
} from '../models';

export function getEmailSectionsWithDriveConfiguration(
  formTemplateConfig: FormTemplateConfig,
): (FormEmailSectionConfig & { drive: FormEmailDriveConfig })[] {
  const emailSections: (FormEmailSectionConfig & { drive: FormEmailDriveConfig })[] = [];

  for (const section of formTemplateConfig.sections) {
    emailSections.push(...getEmailSectionWithDriveConfigs(getDefinedOrThrow(section)));
  }

  return emailSections;
}

function getEmailSectionWithDriveConfigs(
  section: FormSection,
): (FormEmailSectionConfig & { drive: FormEmailDriveConfig })[] {
  const emailSections: (FormEmailSectionConfig & { drive: FormEmailDriveConfig })[] = [];

  switch (section.type) {
    case FormSectionTypesEnum.EMAIL_SECTION:
      if (hasDriveConfiguration(section.config)) {
        emailSections.push(section.config);
      }
      break;
    case FormSectionTypesEnum.FIELD_SECTION:
    case FormSectionTypesEnum.SIGNATURE_SECTION:
      break;
    default:
      throw new UnreachableCaseError(section);
  }

  return emailSections;
}

function hasDriveConfiguration(
  emailSectionConfig: FormEmailSectionConfig,
): emailSectionConfig is FormEmailSectionConfig & { drive: FormEmailDriveConfig } {
  return !!emailSectionConfig.drive;
}
