<div
  class="card flex-row is-clickable"
  [attr.data-test-id]="dataTestId"
  [ngClass]="{ 'is-clickable': isClickable }"
>
  <div
    class="flex-row-center full-width content-container"
    (click)="handleClick($event)"
    (mouseup)="handleMouseup($event)"
  >
    @if (!hideThumbnail) {
      <div class="thumbnail">
        @if (thumbnailSource) {
          <img
            class="thumbnail-image"
            [ngClass]="{ 'thumbnail-image-rounded': thumbnailRounded }"
            [src]="thumbnailId | fileUrl: thumbnailSource : 'small' | async"
            [src-fallback]="thumbnailFallback"
          />
        } @else {
          <img
            class="thumbnail-image"
            [ngClass]="{ 'thumbnail-image-rounded': thumbnailRounded }"
            [src]="thumbnailFallback"
          />
        }

        @if (indicatorConfig?.label) {
          <app-status-indicator
            class="thumbnail-indicator"
            [displayFlex]="true"
            [size]="StatusIndicatorSizeEnum.XSMALL"
            [label]="indicatorConfig?.label"
            [colorPaletteOption]="indicatorConfig?.colorPaletteOption"
            [color]="indicatorConfig?.color"
            [backgroundColor]="indicatorConfig?.backgroundColor"
            [icon]="indicatorConfig?.icon"
            [maxWidth]="'100%'"
          />
        }
      </div>
    }

    <div
      class="flex-col content"
      [ngClass]="{
        'no-actions': !menuFunctions?.length,
        'content-thumbnail-rounded': thumbnailRounded,
      }"
    >
      <div
        class="mat-subtitle-2 full-width text-ellipsis"
        #titleDiv
        [matTooltip]="title ?? ''"
        [matTooltipDisabled]="isTooltipDisabled(titleDiv)"
      >
        <span>{{ title }}</span>
      </div>
      @if (subtitle) {
        <div
          class="mat-body-2 full-width text-ellipsis"
          #subtitleDiv
          [matTooltip]="subtitle"
          [matTooltipDisabled]="isTooltipDisabled(subtitleDiv)"
        >
          <span>{{ subtitle }}</span>
        </div>
      }
    </div>
  </div>

  @if ((menuFunctions?.length ?? 0) > 0) {
    <button class="menu-button" mat-icon-button [matMenuTriggerFor]="elementMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #elementMenu="matMenu" xPosition="before">
      @for (menuFunction of menuFunctions; track menuFunction) {
        <button
          attr.data-test-id="options-button-{{ menuFunction.dataTestId }}"
          mat-menu-item
          (click)="menuFunction.click(element)"
        >
          <mat-icon>{{ menuFunction?.icon }}</mat-icon>
          <span>{{ menuFunction?.name }}</span>
        </button>
      }
    </mat-menu>
  }
  @if (customAction) {
    <button
      class="custom-action-button"
      mat-icon-button
      (click)="customAction.click(element)"
      [matTooltip]="customAction.name"
    >
      <mat-icon>{{ customAction.icon }}</mat-icon>
    </button>
  }
</div>
