import { ActivityRelatedObjectType } from '../definitions';
import { ActivityFieldTypeEnum, ActivityModelNameEnum } from '../enums';
import {
  ACTIVITY_ARRAY_TYPE_TO_POPULATE_TYPE_MAP,
  ActivityArrayFieldTypeToPopulate,
} from './activity-array-type-to-populate-type-map';

export function getModelNameForActivityFieldType<F extends string = ActivityFieldTypeEnum>(
  fieldType: F,
): ActivityModelNameEnum | undefined {
  const relatedObjectType: ActivityRelatedObjectType | undefined =
    ACTIVITY_ARRAY_TYPE_TO_POPULATE_TYPE_MAP[fieldType as ActivityArrayFieldTypeToPopulate] ??
    fieldType;

  return relatedObjectType ? RELATED_OBJECT_TYPE_TO_MODEL_NAME_MAP[relatedObjectType] : undefined;
}

const RELATED_OBJECT_TYPE_TO_MODEL_NAME_MAP: Record<
  ActivityRelatedObjectType,
  ActivityModelNameEnum | undefined
> = {
  [ActivityFieldTypeEnum.ASSET_TYPE]: ActivityModelNameEnum.ASSET_TYPE,
  [ActivityFieldTypeEnum.ASSET]: ActivityModelNameEnum.ASSET,
  [ActivityFieldTypeEnum.CASE]: ActivityModelNameEnum.CASE,
  [ActivityFieldTypeEnum.CONTACT]: ActivityModelNameEnum.CONTACT,
  [ActivityFieldTypeEnum.ORGANIZATION]: ActivityModelNameEnum.ORGANIZATION,
  [ActivityFieldTypeEnum.PLATFORM_FILE]: ActivityModelNameEnum.PLATFORM_FILE,
  [ActivityFieldTypeEnum.USER_GROUP]: ActivityModelNameEnum.USER_GROUP,
  [ActivityFieldTypeEnum.WORK_ORDER]: ActivityModelNameEnum.WORK_ORDER,
  [ActivityFieldTypeEnum.MAINTENANCE_PLAN]: ActivityModelNameEnum.MAINTENANCE_PLAN,
  [ActivityFieldTypeEnum.MAINTENANCE_PLAN_2]: ActivityModelNameEnum.MAINTENANCE_PLAN_2,
  [ActivityFieldTypeEnum.WORK_ORDER_STATUS]: undefined,
  [ActivityFieldTypeEnum.WORK_ORDER_TYPE]: undefined,
  [ActivityFieldTypeEnum.NOTE]: ActivityModelNameEnum.NOTE,
  [ActivityFieldTypeEnum.PART]: ActivityModelNameEnum.PART,
  [ActivityFieldTypeEnum.TICKET2]: ActivityModelNameEnum.TICKET2,
  [ActivityFieldTypeEnum.TICKET2_CATEGORY]: undefined,
};
