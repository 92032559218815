import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FeatureFlagKey,
  PermissionAction,
  RootGlobalState,
  getHasAccess$,
} from '@remberg/ui-core/core';
import { Observable } from 'rxjs';

/**
 * Pipe that returns Observable<boolean> based on passed permission action and optionally feature flag key.
 * Needs to be used with `async` pipe.
 *
 * Decision is made using current tenant and user role from NGRX Store.
 * If neither is passed, the element will be rendered.
 * If tenant/role are missing in store, the element will not be rendered.
 *
 * For just feature flag, use `hasFeatureFlag` pipe.
 *
 * Example with just permission:
 * ```html
 * <app-a
 *   [boolInput]="AiPermissionsEnum.AI_COPILOT_ENABLED | hasAccess | async"
 * >
 *   content
 * </app-a>
 * ```
 *
 * Example with both:
 * ```html
 * <app-a
 *   [boolInput]="AiPermissionsEnum.AI_COPILOT_ENABLED | hasAccess:FeatureFlagEnum.AI_COPILOT | async"
 * >
 *   content
 * </app-a>
 * ```
 */
@Pipe({ name: 'hasAccess', standalone: true })
export class HasAccessPipe implements PipeTransform {
  constructor(private readonly store: Store<RootGlobalState>) {}

  public transform(
    action?: PermissionAction,
    featureFlag?: FeatureFlagKey,
    viewCheck?: boolean,
  ): Observable<boolean> {
    return getHasAccess$(this.store, action, featureFlag, viewCheck);
  }
}
