import { PhoneNumber } from '../definitions';

// Regex to parse phone numbers: +49 123131 or (+49) 123 1310242 or (+49) 123-1310-242
const PHONE_NUMBER_WITH_COUNTRY_CODE_REGEX: RegExp =
  /^(\+([1-9]{1,4})|\(\+([1-9]{1,4})\))\s([0-9\s\-]*)$/;

export function phoneNumberStringToPhoneNumberObject(
  phoneNumberString: string | undefined,
): PhoneNumber {
  if (!phoneNumberString) {
    return {
      countryPrefix: undefined,
      number: undefined,
    };
  }

  const match = phoneNumberString.match(PHONE_NUMBER_WITH_COUNTRY_CODE_REGEX);
  if (match) {
    // Example of the match result
    // 0: "(+49) 2342342343"
    // 1: "(+49)" or "+49"
    // 2: undefined or "49"
    // 3: "49" or undefined
    // 4: "2342342343"
    return {
      countryPrefix: match[2] ?? match[3],
      // removing ' ' and '-' because they are not allowed for the phone number picker
      number: match[4]?.replace(/[\s\-]/g, ''),
    };
  }

  return {
    countryPrefix: undefined,
    number: phoneNumberString,
  };
}
