import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxDisplayedNumber',
  standalone: true,
})
export class MaxDisplayedNumberPipe implements PipeTransform {
  public transform(value: number, limit: number): number {
    return value > limit ? limit : value;
  }
}
