import { isEnumValue, TimeZoneEnum } from '@remberg/global/common/core';

export function getBrowserTimezone(): TimeZoneEnum | undefined {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (isEnumValue(timeZone, TimeZoneEnum)) {
    return timeZone;
  }

  if (timeZone === 'UTC') {
    return TimeZoneEnum['Etc/UTC'];
  }

  // Other possible values might be Etc/GMT+1, Etc/GMT-1, etc or Etc/Unknown:
  // https://source.chromium.org/chromium/chromium/src/+/main:third_party/icu/source/i18n/unicode/timezone.h;l=168-179;drc=05401be858fce13f572977457ff78cea16418569?q=timezone.h&ss=chromium%2Fchromium%2Fsrc
  // but we don't have a corresponding enum value for them.
  // Also timezones like Etc/GMT-1 (-1h here means UTC+01:00)
  // represent so called custom timezones without the daylight saving time shift.
  // it means that a custom timezone can match different values from TimeZoneEnum
  // depends on the daylight saving time.
  //
  // let's simply return undefined in this case and fallback to the RembergUser.tz setting

  return undefined;
}
