import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPlatforms } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  ApplicationTypeEnum,
  DeviceTypeEnum,
  TrackingEventEnum,
  TrackingInfo,
  TrackingInfoOptions,
} from '@remberg/analytics/common/main';
import { AnalyticsTrackingServiceInterface } from '@remberg/analytics/ui/clients';
import { API_URL_PLACEHOLDER, LogService } from '@remberg/global/ui';
import { EMPTY, catchError, firstValueFrom } from 'rxjs';
import { RootGlobalState } from '../store/core-ui.definitions';
import { GlobalSelectors } from '../store/global/global.selectors';
import { DeviceInfoService } from './device-info.service';

@Injectable()
export class AnalyticsTrackingService implements AnalyticsTrackingServiceInterface {
  private readonly trackingUrl = `${API_URL_PLACEHOLDER}/analytics/v1`;

  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly http: HttpClient,
    private readonly logger: LogService,
    private readonly deviceInfoService: DeviceInfoService,
  ) {}

  public async trackEvent(
    eventName: TrackingEventEnum,
    eventOptions?: TrackingInfoOptions,
  ): Promise<void> {
    this.logger.debug()(`Logging event: ${eventName}`);
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    const isLoggedIn = await firstValueFrom(this.store.select(GlobalSelectors.selectIsLoggedIn));

    const trackingInfo: TrackingInfo = {
      eventName,
      eventOptions,
      deviceType: getPlatforms() as DeviceTypeEnum[],
      browserType: (await firstValueFrom(this.store.select(GlobalSelectors.selectDeviceType)))
        ?.browserName,
      deviceId: await this.deviceInfoService.getDeviceIdAsync(),
      applicationVersion: await firstValueFrom(
        this.store.select(GlobalSelectors.selectShortVersionInfoString),
      ),
      applicationType: isIonic ? ApplicationTypeEnum.MOBILE : ApplicationTypeEnum.WEBAPP,
    };

    if (!isLoggedIn) {
      return await this.trackPublicEvent(trackingInfo);
    }
    this.http
      .post<TrackingInfo>(`${this.trackingUrl}/track`, { trackingInfo })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.logger.error()(
            `Backend analytics event logging error: ${error.message}`,
            trackingInfo,
          );
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private async trackPublicEvent(trackingInfo: TrackingInfo): Promise<void> {
    const tenantId = (await firstValueFrom(this.store.select(GlobalSelectors.selectTenantPublic)))
      ?.tenantId;
    this.http
      .post<TrackingInfo>(`${this.trackingUrl}/track/public`, {
        trackingInfo,
        userMeta: {
          tenantId,
        },
      })
      .pipe(
        catchError((error) => {
          this.logger.error()(`Backend analytics event logging error: ${error}`);
          return error;
        }),
      )
      .subscribe(this.logger.debug());
  }
}
