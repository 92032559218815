import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  FormInstanceCreateFromExistingBody,
  FormInstanceCreateFromExistingResponse,
  FormInstanceUpdateBody,
} from '@remberg/forms/common/dtos';
import { FormInstanceRaw } from '@remberg/forms/common/main';
import {
  FORM_INSTANCE_OFFLINE_SERVICE,
  FormInstanceOfflineServiceInterface,
  FormInstanceService,
} from '@remberg/forms/ui/clients';
import { Complete } from '@remberg/global/common/core';
import {
  AbortToken,
  ButtonActions,
  ChangeTypeEnum,
  DEFAULT_DIALOG_WIDTH,
  DynamicButtonConfig,
  LocalStorageKeys,
  LogService,
  OnlineStatusDataTypeEnum,
  PushStatus,
  StorageService,
} from '@remberg/global/ui';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DialogOptions } from '../../dialogs/dialogs';
import { DynamicPopUpComponent } from '../../dialogs/dynamic-pop-up/dynamic-pop-up.component';
import { ModalDialogWrapper } from '../../dialogs/modalDialogWrapper';
import { DialogService } from '../dialog.service';

@Injectable({
  providedIn: 'root',
})
export class OfflinePushFormInstanceService {
  constructor(
    private readonly logger: LogService,
    private readonly formInstanceService: FormInstanceService,
    @Inject(FORM_INSTANCE_OFFLINE_SERVICE)
    private readonly formInstanceOfflineService: FormInstanceOfflineServiceInterface,
    private readonly dialog: DialogService,
    private readonly storage: StorageService,
  ) {}

  public async pushOfflineFormCreations(
    statusSubject: BehaviorSubject<PushStatus | undefined>,
    abortToken: AbortToken,
  ): Promise<boolean> {
    // Reset push status
    statusSubject.next({
      ...statusSubject.getValue(),
      [ChangeTypeEnum.FORM_CREATIONS]: 0,
    });

    const createdFormInstancesOffline = await this.formInstanceOfflineService.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      `onlineStatus = '${OnlineStatusDataTypeEnum.OFFLINE_CREATION}'`,
    );

    const draftIds = this.formInstanceOfflineService.getDraftIds();

    for (const formInstance of createdFormInstancesOffline) {
      // Before every create, we can abort
      abortToken.check();

      const draftId = draftIds[formInstance._id];

      try {
        const result = await firstValueFrom(
          this.formInstanceService.createFromExistingOnline(
            getFormInstanceCreateFromExistingDto(formInstance),
          ),
        );

        await this.onFormInstanceCreated(
          formInstance,
          result,
          draftIds,
          OnlineStatusDataTypeEnum.ONLINE,
        );

        // update push status
        const currentStatus = statusSubject.getValue()?.[ChangeTypeEnum.FORM_CREATIONS] as number;
        statusSubject.next({
          ...statusSubject.getValue(),
          [ChangeTypeEnum.FORM_CREATIONS]: currentStatus + 1 / createdFormInstancesOffline.length,
        });
      } catch (error) {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Conflict) {
          // if something went wrong and we tried to create the form instance twice
          // let's treat the current form instance as changed that it will be included in pushOfflineFormUpdates
          await this.onFormInstanceCreated(
            formInstance,
            error.error as FormInstanceCreateFromExistingResponse,
            draftIds,
            OnlineStatusDataTypeEnum.OFFLINE_CHANGE,
          );
          continue;
        }

        this.logger.error()('Error pushingOffline form creation: ', error);
        // let the user confirm if he wants to continue anyways
        const popupData = await this.showWarningPopup(
          $localize`:@@offlinePushFormInstanceWarningDraftInfo:Form Instance: ${
            formInstance.name
          } (Draft ${draftId ?? ''})`,
        ).waitForCloseData();

        if (popupData.confirmed) {
          // backup the change before discarding it
          await this.backupDiscardedChange({
            type: ChangeTypeEnum.FORM_CREATIONS,
            change: formInstance,
          });
          // delete the instance from the DB
          await this.formInstanceOfflineService.deleteInstance(formInstance);
        } else {
          throw error;
        }
      }
    }

    // recalculate outstandingChangesCount
    await this.formInstanceOfflineService.recalculateOutstandingChangesCount();

    // Reset the draft ids
    await this.formInstanceOfflineService.setDraftIds({});

    // final status update
    statusSubject.next({
      ...statusSubject.getValue(),
      [ChangeTypeEnum.FORM_CREATIONS]: 1,
    });

    return true;
  }

  public async pushOfflineFormUpdates(
    statusSubject: BehaviorSubject<PushStatus | undefined>,
    abortToken: AbortToken,
  ): Promise<boolean> {
    // get push status
    statusSubject.next({
      ...statusSubject.getValue(),
      [ChangeTypeEnum.FORM_UPDATES]: 0,
    });

    const changedFormInstances = await this.formInstanceOfflineService.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      `onlineStatus = '${OnlineStatusDataTypeEnum.OFFLINE_CHANGE}'`,
    );

    for (const formInstance of changedFormInstances) {
      // before every update, we can abort.
      abortToken.check();

      let originalInstance: FormInstanceRaw | undefined = undefined;

      try {
        // fetch the original version of the formInstance (required for 3 way merge)
        originalInstance = await this.formInstanceOfflineService.getOriginalInstance(formInstance);

        // update the form instance in the backend and local DB
        const result = await this.formInstanceService
          .updateOneOnline({
            ...getFormInstanceUpdateDto(formInstance, originalInstance),
            originalFormInstance: getFormInstanceUpdateDto(originalInstance),
          })
          .toPromise();

        // log
        this.logger.debug()('Pushed Change: ' + formInstance._id);
        this.logger.debug()(result);

        // update push status
        statusSubject.next({
          ...statusSubject.getValue(),
          [ChangeTypeEnum.FORM_UPDATES]:
            (statusSubject.getValue()?.formUpdates ?? 0) + 1 / changedFormInstances.length,
        });
      } catch (error: unknown) {
        this.logger.error()('Error pushingOffline form update: ', error);

        // in case instance not found
        if ((error as HttpErrorResponse).status === HttpStatusCode.NotFound && originalInstance) {
          // let the user confirm if he wants to create a new form
          const popupData = await this.showWarningCreatePopup(formInstance).waitForCloseData();

          if (popupData.confirmed) {
            // run the update request again, but with the property isDeleted = true;
            await this.formInstanceService
              .updateOneOnline({
                ...getFormInstanceUpdateDto(formInstance, originalInstance),
                originalFormInstance: getFormInstanceUpdateDto(originalInstance),
                isDeleted: true,
              })
              .toPromise();

            this.logger.debug()('Pushed Change with isDeleted property: ' + formInstance._id);

            // update push status
            statusSubject.next({
              ...statusSubject.getValue(),
              [ChangeTypeEnum.FORM_UPDATES]:
                (statusSubject.getValue()?.formUpdates ?? 0) + 1 / changedFormInstances.length,
            });
          } else {
            // backup and delete the change before discarding it
            await this.backupDiscardedChange({
              type: ChangeTypeEnum.FORM_UPDATES,
              change: formInstance,
            });

            await this.formInstanceOfflineService.deleteInstance(originalInstance);
          }
        } else {
          let errorMessage = $localize`:@@offlinePushFormInstanceWarningInfo:Form Instance: ${formInstance.name} (${formInstance.counter})`;
          if ((error as HttpErrorResponse).status === HttpStatusCode.Locked) {
            errorMessage = $localize`:@@partsOfAFormHaveBeenLockedByEitherSendingAnEmailOrSigningCommaAndYouAreTryingToUpdateTheseLockedSectionsDot:Parts of a form have been locked by either sending an email or signing, and you are trying to update these locked sections.`;
          }

          const popupData = await this.showWarningPopup(errorMessage).waitForCloseData();

          if (popupData.confirmed) {
            // backup the change before discarding it
            await this.backupDiscardedChange({
              type: ChangeTypeEnum.FORM_UPDATES,
              change: formInstance,
            });

            if (originalInstance) {
              // reset the form instance object and its online status in the local DB
              await this.formInstanceOfflineService.updateInstance(
                originalInstance,
                OnlineStatusDataTypeEnum.ONLINE,
              );
            }
          } else {
            // abort by throwing the error
            throw error;
          }
        }
      }
    }

    // recalculate outstandingChangesCount
    await this.formInstanceOfflineService.recalculateOutstandingChangesCount();

    // final status update
    statusSubject.next({
      ...statusSubject.getValue(),
      [ChangeTypeEnum.FORM_UPDATES]: 1,
    });

    return true;
  }

  public async pushOfflineFormDeletions(
    statusSubject: BehaviorSubject<PushStatus | undefined>,
    abortToken: AbortToken,
  ): Promise<boolean> {
    // get push status
    statusSubject.next({
      ...statusSubject.getValue(),
      [ChangeTypeEnum.FORM_DELETIONS]: 0,
    });

    const formInstanceToDeleteIds = this.formInstanceOfflineService.getDeletedInstanceIds();
    const formInstanceToDeleteIdsLength = formInstanceToDeleteIds.length;

    while (formInstanceToDeleteIds.length > 0) {
      const formInstanceId = formInstanceToDeleteIds.pop();
      if (!formInstanceId) {
        continue;
      }
      // before every delete, we can abort.
      abortToken.check();

      if (formInstanceId) {
        try {
          await this.formInstanceService.deleteOneOnline(formInstanceId).toPromise();
          this.logger.debug()('Pushed Deletion: ' + formInstanceId);

          // remove the id from the list of deleted form instances
          await this.formInstanceOfflineService.setDeletedInstanceIds(formInstanceToDeleteIds);

          // update push status
          statusSubject.next({
            ...statusSubject.getValue(),
            [ChangeTypeEnum.FORM_DELETIONS]:
              (statusSubject.getValue()?.[ChangeTypeEnum.FORM_DELETIONS] ?? 0) +
              1 / formInstanceToDeleteIdsLength,
          });
        } catch (error: unknown) {
          this.logger.error()('Error pushingOffline form deletion: ', error);

          // let the user confirm if he wants to continue anyway
          const popupData = await this.showWarningPopup(
            $localize`:@@offlinePushFormInstanceWarningDeleteInfo:Form Instance Id: ${formInstanceId}`,
          ).waitForCloseData();

          if (popupData.confirmed) {
            // backup the change before discarding it
            await this.backupDiscardedChange({
              type: ChangeTypeEnum.FORM_DELETIONS,
              change: { _id: formInstanceId },
              error: error,
            });

            // remove the id from the list of deleted form instances
            await this.formInstanceOfflineService.setDeletedInstanceIds(formInstanceToDeleteIds);
          } else {
            throw error;
          }
        }
      }
    }

    // final status delete
    statusSubject.next({
      ...statusSubject.getValue(),
      [ChangeTypeEnum.FORM_DELETIONS]: 1,
    });

    return true;
  }

  /**
   * These functions are copied over from the "FormInstanceService".
   */
  private async backupDiscardedChange(change: {
    type: ChangeTypeEnum;
    change: Partial<FormInstanceRaw>;
    error?: any;
    time?: Date;
  }): Promise<void> {
    const backupChangesRaw = await this.storage.get(LocalStorageKeys.IONIC_OFFLINE_CHANGES_BACKUP);
    const backupChanges = backupChangesRaw ? JSON.parse(backupChangesRaw) : [];
    change.time = change.time ?? new Date();
    backupChanges.push(change);
    await this.storage.set(
      LocalStorageKeys.IONIC_OFFLINE_CHANGES_BACKUP,
      JSON.stringify(backupChanges),
    );
  }

  private showWarningPopup(formInstanceInfoText: string): ModalDialogWrapper {
    const descriptionTexts = [
      formInstanceInfoText,
      // eslint-disable-next-line max-len
      $localize`:@@doYouWantToDiscardTheUploadOfOfflineChangesOrRetryToUploadThemLater:Do you want to discard the upload of offline changes or retry to upload them later?`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@discard:Discard`,
        category: 'success',
        action: ButtonActions.CONFIRM,
        dataTestId: 'discard-upload-offline-changes-btn',
      },
      {
        text: $localize`:@@retryLater:Retry Later`,
        category: 'danger',
        color: 'primary',
        action: ButtonActions.RETRY,
        dataTestId: 'retry-upload-offline-changes-btn',
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@uploadingOfflineChangesFailed:Uploading Offline Changes failed`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'justify',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this.dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private showWarningCreatePopup(formInstance: FormInstanceRaw): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@formInstanceColon:Form Instance: ${formInstance.counter} (${formInstance.name})`,
      $localize`:@@itSeemsThatThisformWasDeletedBySomeoneElseDot:It seems that this form was deleted by someone else.`,
      // eslint-disable-next-line max-len
      $localize`:@@doYouWantToRestoreTheFormWithYourChangesOrDiscardTheLocalChanges:Do you want to restore the form and apply your changes or discard the local changes?`,
    ];
    const buttons: DynamicButtonConfig[] = [
      {
        text: $localize`:@@discard:Discard`,
        category: 'danger',
        action: ButtonActions.ABORT,
      },
      {
        text: $localize`:@@createNewForm:Create new Form`,
        category: 'success',
        color: 'primary',
        action: ButtonActions.CONFIRM,
      },
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
        },
        factoryInput: [
          {
            icon: {
              icon: 'sync_problem',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@uploadingOfflineChangesFailed:Uploading Offline Changes failed`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'justify',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons },
          { buttonsDirection: 'vertical' },
        ],
      },
    };
    return this.dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private async onFormInstanceCreated(
    formInstance: FormInstanceRaw,
    result: FormInstanceCreateFromExistingResponse,
    draftIds: Record<string, number | undefined>,
    onlineStatus: OnlineStatusDataTypeEnum.ONLINE | OnlineStatusDataTypeEnum.OFFLINE_CHANGE,
  ): Promise<void> {
    formInstance.counter = result.counter;
    this.logger.debug()('Pushed Create: ', formInstance);
    this.logger.debug()(result);

    await this.formInstanceOfflineService.updateInstanceAndStatus(formInstance, onlineStatus);

    delete draftIds[formInstance._id];
    this.logger.debug()('Update draft ids ', draftIds);
    await this.formInstanceOfflineService.setDraftIds(draftIds);

    this.logger.debug()('Recalculate outstanding changes count');
    await this.formInstanceOfflineService.recalculateOutstandingChangesCount();
  }
}

function getFormInstanceUpdateDto(
  formInstance: FormInstanceRaw,
  originalFormInstance?: FormInstanceRaw,
): FormInstanceUpdateBody {
  return {
    _id: formInstance._id,
    name: formInstance.name,
    status: formInstance.status,
    assigneeId:
      !!originalFormInstance?.assigneeId && !formInstance.assigneeId
        ? null
        : formInstance.assigneeId,
    relatedWorkOrderId2:
      !!originalFormInstance?.relatedWorkOrderId2 && !formInstance.relatedWorkOrderId2
        ? null
        : formInstance.relatedWorkOrderId2,
    dateModified: formInstance.dateModified,
    data: formInstance.data,
  };
}

function getFormInstanceCreateFromExistingDto(
  formInstance: FormInstanceRaw,
): Complete<FormInstanceCreateFromExistingBody> {
  return {
    _id: formInstance._id,
    name: formInstance.name,
    formTemplateId: formInstance.formTemplateId,
    formTemplateVersionId: formInstance.formTemplateVersionId,
    pdfLanguage: formInstance.pdfLanguage,
    relatedWorkOrderId2: formInstance.relatedWorkOrderId2,
    assigneeId: formInstance.assigneeId,
    status: formInstance.status,
    createdAt: formInstance.createdAt,
    dateModified: formInstance.dateModified,

    data: formInstance.data,
  };
}
