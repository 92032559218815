<div
  fxLayout="column"
  class="empty-list-container"
  [ngClass]="{
    'empty-list-full-height': fullHeight && (layout.isXSmallOrSmallView | async) === false,
    'empty-list-full-height-x-small': fullHeight && (layout.isXSmallView | async),
    'empty-list-full-height-small': fullHeight && (layout.isSmallView | async),
  }"
  attr.data-test-id="{{ dataTestId }}"
>
  @if (!hideImage) {
    <img
      class="main-image"
      [attr.src]="'/assets/img/lists/' + type + '_list.svg' | withCacheBreaker | sanitizeUrl"
    />
  }

  <div
    class="main-text"
    [ngStyle]="{ fontSize: hideImage && '14px' }"
    data-test-id="empty-list-main-text"
    [ngClass]="{ 'in-card-text-only': hideImage && inCard }"
  >
    {{ type ? emptyListTypeTranslations[type]?.mainText : '' }}
  </div>

  @if (!hideSubtext) {
    <div class="sub-text">
      {{ type ? emptyListTypeTranslations[type]?.subText : '' }}
    </div>
  }

  @if (addFileOption) {
    <button
      (click)="click()"
      style="margin-top: 20px"
      mat-raised-button
      color="primary"
      type="button"
      i18n="@@plusAddFile-DocumentInGerman"
    >
      + Add File
    </button>
  }
</div>
