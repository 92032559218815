export enum OrganizationsFilterEnum {
  UPDATED_AT = 'updatedAt',
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
  ID = 'id',
  CLASS = 'class',
  SHIPPING_COUNTRY_PROVINCE = 'shippingCountryProvince',
  SHIPPING_COUNTRY = 'shippingCountry',
  SHIPPING_CITY = 'shippingCity',
  SHIPPING_ZIP_CODE = 'shippingZipCode',
  SHIPPING_BOUNDING_BOX = 'shippingBoundingBox',
  BILLING_COUNTRY_PROVINCE = 'billingCountryProvince',
  BILLING_COUNTRY = 'billingCountry',
  BILLING_CITY = 'billingCity',
  BILLING_ZIP_CODE = 'billingZipCode',
  BILLING_BOUNDING_BOX = 'billingBoundingBox',
  CUSTOM_TAG = 'customTag',
}
