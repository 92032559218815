<div class="header-wrapper">
  <mat-icon class="mat-icon icon-primary">delete</mat-icon>
</div>

<h2 class="header-text" [innerHtml]="headerText | safeHtml"></h2>

<p class="content-text" [innerHtml]="paragraph1Text | safeHtml"></p>
<p class="content-text hint" [innerHtml]="paragraph2Text | safeHtml"></p>

<mat-form-field appearance="outline" class="my-mat-form-field my-mat-form-field-outline">
  <mat-label>{{ translations.textFieldLabel }}</mat-label>
  @if (confirmationControl) {
    <input
      [formControl]="confirmationControl"
      matInput
      type="text"
      placeholder="{{ placeholderText }}"
      cdkFocusInitial
      data-test-id="dialog-input"
    />
  }
  @if (confirmationControl?.errors?.['required']) {
    <mat-error>
      {{ translations.errorRequired }}
    </mat-error>
  }
  @if (confirmationControl?.errors?.['isMatching']) {
    <mat-error>
      {{ translations.errorIsMatching }}
    </mat-error>
  }
</mat-form-field>

<div class="buttons-container">
  <button mat-raised-button [mat-dialog-close]="false" i18n="@@cancel">Cancel</button>
  <button
    class="button-confirm"
    color="primary"
    mat-raised-button
    [mat-dialog-close]="true"
    [disabled]="confirmationControl?.invalid"
    i18n="@@delete"
    data-test-id="delete-button"
  >
    Delete
  </button>
</div>
