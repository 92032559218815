import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentRef,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ButtonActions, DynamicButtonConfig } from '@remberg/global/ui';
import { LayoutService } from '../../services/layout.service';
import { DialogData, ModalDialogWrapperInterface } from '../dialogs';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
})
export class DialogWrapperComponent
  implements AfterViewInit, OnDestroy, ModalDialogWrapperInterface
{
  materialConfig?: MatDialogConfig;

  // Header Input Options
  headerShow: boolean; // default false
  removeBottomMargin: boolean; // default false
  headerTitle?: string; // default
  headerCloseActionShow: boolean; // default true
  overflowXHidden: boolean; // default false
  overflowYHidden: boolean; // default false

  // Style Input Options
  styleFullscreen: boolean; // default false
  styleNoMargin: boolean; // default false
  styleStickyHeader: boolean; // default true
  styleHeight?: string; // default
  styleWidth?: string; // default
  styleMaxWidth?: string; // default 90vw
  styleMaxHeight?: string; // default 90vh
  styleMinWidth?: string; // default
  styleMinHeight?: string; // default
  styleZIndex?: string; //
  dialogModalRef?: MatDialogRef<ModalDialogWrapperInterface>;
  disableParentConfirmButton?: boolean;

  dialogCustomStyles: any;

  @ViewChild('dialogContent', {
    read: ViewContainerRef,
    static: false,
  })
  container?: ViewContainerRef;

  componentRef?: ComponentRef<DialogWrapperComponent>;

  factory?: ComponentFactory<DialogWrapperComponent>;
  factoryInput: Record<string, DialogWrapperComponent>[];
  isDialog?: boolean;
  isModal?: boolean;

  showButtons: boolean = false;
  hideAbortButton: boolean = false;
  buttonsDirection: 'vertical' | 'horizontal';

  ButtonActions = ButtonActions;

  buttons: DynamicButtonConfig[];

  constructor(
    public dialogRef: MatDialogRef<DialogWrapperComponent>,
    public layout: LayoutService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) dialogData: DialogData<DialogWrapperComponent>,
  ) {
    this.factory = dialogData.factory;
    this.factoryInput = dialogData.factoryInput;

    // Header Options
    this.headerShow = dialogData.wrapperInput.headerShow ?? false;
    this.removeBottomMargin = dialogData.wrapperInput.removeBottomMargin ?? false;
    this.overflowXHidden = dialogData.wrapperInput.overflowXHidden ?? false;
    this.overflowYHidden = dialogData?.wrapperInput.overflowYHidden || false;
    this.headerTitle = dialogData.wrapperInput.headerTitle;
    this.headerCloseActionShow = dialogData.wrapperInput.headerCloseActionShow ?? true;

    // Style Options

    // By default set fullscreen mode in all small screen devices if no input provided
    this.styleFullscreen = dialogData.wrapperInput.styleFullscreen ?? false;
    this.styleNoMargin = dialogData.wrapperInput.styleNoMargin ?? false;
    this.styleStickyHeader = dialogData.wrapperInput.styleStickyHeader ?? true;
    this.styleMaxHeight = dialogData.wrapperInput.styleMaxHeight;
    this.styleMaxWidth = dialogData.wrapperInput.styleMaxWidth;
    this.styleMinHeight = dialogData.wrapperInput.styleMinHeight;
    this.styleMinWidth = dialogData.wrapperInput.styleMinWidth;
    this.styleHeight = dialogData.wrapperInput.styleHeight;
    this.styleWidth = dialogData.wrapperInput.styleWidth;
    this.dialogModalRef = dialogData.dialogModalRef;

    this.showButtons = dialogData.wrapperInput.showButtons ?? this.showButtons;
    this.hideAbortButton = dialogData.wrapperInput.hideAbortButton || false;
    this.buttonsDirection = dialogData.wrapperInput.buttonsDirection || 'horizontal';
    this.buttons = dialogData.wrapperInput.buttons || [
      {
        text: $localize`:@@abort:Abort`,
        category: 'danger',
        action: ButtonActions.ABORT,
        dataTestId: 'popup-abort',
      },
      {
        text: $localize`:@@confirm:Confirm`,
        category: 'success',
        color: 'primary',
        action: ButtonActions.CONFIRM,
        dataTestId: 'popup-confirm',
      },
    ];
  }

  ngAfterViewInit(): void {
    this._applyCustomStyles();
    this._createComponent();
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    this.componentRef?.destroy();
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.componentRef?.destroy();
  }

  buttonPressed(button: DynamicButtonConfig): void {
    if (button.action === ButtonActions.ABORT) {
      this.dialogRef.close({
        data: {
          confirmation: false,
          action: button.action,
          data: {
            componentInstance: this.componentRef?.instance,
          },
        },
      });
    } else if (button.action === ButtonActions.CONFIRM) {
      this.dialogRef.close({
        confirmation: true,
        action: button.action,
        data: {
          componentInstance: this.componentRef?.instance,
        },
      });
    }
  }

  private _createComponent(): void {
    if (this.container && this.factory) {
      this.container.clear();
      this.componentRef = this.container.createComponent(this.factory);
      const instance = this.componentRef.instance;

      // Pass all @Input data for subcomponent via factoryInput: [{ inputName: 'inputValue' }]
      for (const element of this.factoryInput) {
        const currentElementKey = Object.keys(element)[0];
        if (currentElementKey) {
          (instance as any)[currentElementKey] = element[currentElementKey];
        }
      }
      instance.isDialog = true;
      instance.isModal = false;
      instance.dialogModalRef = this.dialogModalRef;
      // Pass Modal Reference to component
      instance.dialogRef = this.dialogRef;
    }
  }

  private _applyCustomStyles(): void {
    this.dialogCustomStyles = {};
    if (this.styleMaxWidth) {
      this.dialogCustomStyles['max-width'] = this.styleMaxWidth;
    }
    if (this.styleMaxHeight) {
      this.dialogCustomStyles['max-height'] = this.styleMaxHeight;
      if (!this.styleHeight) {
        this.dialogCustomStyles['heightVariable'] = this.styleHeight;
      }
    }
    if (this.styleMinWidth) {
      this.dialogCustomStyles['min-width'] = this.styleMinWidth;
    }
    if (this.styleMinHeight) {
      this.dialogCustomStyles['min-height'] = this.styleMinHeight;
    }
    if (this.styleWidth) {
      this.dialogCustomStyles['width'] = this.styleWidth;
    }
    if (this.styleHeight) {
      this.dialogCustomStyles['height'] = this.styleHeight;
      this.dialogCustomStyles['heightVariable'] = this.styleHeight;
    }
  }
}
