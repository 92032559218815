export enum ApplicationEnvironmentEnum {
  PRODUCTION = 'prod',
  STAGING = 'staging',
  TEST01 = 'test01',
  MIRROR = 'mirror',
  DEV = 'dev',
  LOCAL = 'local',
  PREVIEW = 'preview',
  UNKNOWN = 'unknown',
}
