import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CustomTag,
  CustomTagCreateOneBody,
  CustomTagModelEnum,
  CustomTagUpdateOneBody,
} from '@remberg/custom-tags/common/main';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
} from '@remberg/global/ui';
import { from, Observable } from 'rxjs';
import {
  CUSTOM_TAGS_OFFLINE_SERVICE,
  CustomTagsOfflineServiceInterface,
} from './custom-tags.offline.service';

// Any fecthing logic added here must be accomodated with offline service incorporation
@Injectable({
  providedIn: 'root',
})
export class CustomTagsService {
  private readonly CUSTOM_TAGS_URL = `${API_URL_PLACEHOLDER}/customtags/v1`;

  constructor(
    private readonly http: HttpClient,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(CUSTOM_TAGS_OFFLINE_SERVICE)
    private readonly customTagsOfflineService: CustomTagsOfflineServiceInterface,
  ) {}

  public findAll(model: CustomTagModelEnum): Observable<CustomTag[]> {
    const isOnline = this.connectivityService.getConnected();
    if (!isOnline) {
      return from(this.customTagsOfflineService.findAll(model));
    }

    return this.http.get<CustomTag[]>(`${this.CUSTOM_TAGS_URL}/all/${model}`);
  }

  public deleteOne(id: string): Observable<void> {
    return this.http.delete<void>(`${this.CUSTOM_TAGS_URL}/${id}`);
  }

  public createOne(body: CustomTagCreateOneBody): Observable<CustomTag> {
    return this.http.post<CustomTag>(this.CUSTOM_TAGS_URL, body);
  }

  public updateOne(id: string, body: CustomTagUpdateOneBody): Observable<CustomTag> {
    return this.http.patch<CustomTag>(`${this.CUSTOM_TAGS_URL}/${id}`, body);
  }
}
