export enum MaintenancePlanAPIFilterFieldEnum {
  CREATED_BY = 'createdBy',
  ASSIGNED_ORGANIZATION = 'assignedOrganization',
  ASSET = 'asset',
  STATUS = 'status',
  TERMINATED = 'terminated',
  WORK_ORDER_PRIORITY = 'workOrderPriority',
  WORK_ORDER_RESPONSIBLE_GROUP = 'workOrderResponsibleGroup',
  WORK_ORDER_RESPONSIBLE_CONTACT = 'workOrderResponsibleContact',

  // WO 2 world
  WORK_ORDER_STATUS_2 = 'workOrderStatus2',
  WORK_ORDER_TYPE_2 = 'workOrderType2',
  WORK_ORDER_ASSIGNED_CONTACT = 'workOrderAssignedContact',
  WORK_ORDER_ASSIGNED_GROUP = 'workOrderAssignedGroup',
}
