import { Pipe, PipeTransform } from '@angular/core';
import { LanguageCodeEnum } from '@remberg/global/common/core';

export const LANGUAGE_CODE_ENUM_TRANSLATIONS: Record<LanguageCodeEnum, string> = {
  [LanguageCodeEnum.EN_US]: $localize`:@@english:English`,
  [LanguageCodeEnum.DE]: $localize`:@@german:German`,
  [LanguageCodeEnum.TR]: $localize`:@@turkish:Turkish`,
  [LanguageCodeEnum.FR]: $localize`:@@french:French`,
  [LanguageCodeEnum.IT]: $localize`:@@italian:Italian`,
  [LanguageCodeEnum.ES]: $localize`:@@spanish:Spanish`,
  [LanguageCodeEnum.EL]: $localize`:@@greek:Greek`,
  [LanguageCodeEnum.TH]: $localize`:@@thai:Thai`,
  [LanguageCodeEnum.PL]: $localize`:@@polish:Polish`,
  [LanguageCodeEnum.CS]: $localize`:@@czech:Czech`,
  [LanguageCodeEnum.HU]: $localize`:@@hungarian:Hungarian`,
};

@Pipe({
  name: 'translateLanguageCodeEnum',
  standalone: true,
})
export class TranslateLanguageCodeEnumPipe implements PipeTransform {
  public transform(value?: LanguageCodeEnum): string {
    return value ? LANGUAGE_CODE_ENUM_TRANSLATIONS[value] : '';
  }
}
