import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IsoLanguageCodesEnum, MultiLanguageText } from '@remberg/global/common/core';
import { MAP_ISO_LANGUAGE_TO_FLAG_SVG } from '../multi-language-input/multi-language-edit-dialog/multi-language-edit-dialog.definitions';

@Component({
  selector: 'app-translation-list',
  templateUrl: './translation-list.component.html',
  styleUrls: ['./translation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatTooltipModule],
})
export class TranslationListComponent {
  @Input()
  public countryCodes: IsoLanguageCodesEnum[] = [];

  @Input()
  public translations?: MultiLanguageText;

  protected readonly MAP_ISO_LANGUAGE_TO_FLAG_SVG = MAP_ISO_LANGUAGE_TO_FLAG_SVG;
}
