import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalController } from '@ionic/angular';
import { DEFAULT_DIALOG_WIDTH } from '@remberg/global/ui';
import { LayoutService } from '../../services/layout.service';
import { ConfirmationPopUpComponent } from '../confirmation-pop-up/confirmation-pop-up.component';
import { DialogData, ModalDialogWrapperInterface } from '../dialogs';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent implements AfterViewInit, OnDestroy {
  @ViewChild('modalContent', { read: ViewContainerRef, static: false })
  container?: ViewContainerRef;
  @Input() componentRef?: ComponentRef<ModalDialogWrapperInterface>;
  factory?: ComponentFactory<ModalDialogWrapperInterface>;
  factoryInput?: Record<string, unknown>[];

  // Modal Wrapper Input
  /** Header title string */
  headerTitle?: string;
  /** Showing dialog header with title */
  headerShow: boolean = true;
  /** Removes the extra margin-top after the header divider on the modalContent wrapper. */
  headerShowWithoutMargin: boolean = false;
  headerCloseActionShow: boolean = true;
  headerConfirmActionShow: boolean = true;
  headerThreeDotActionShow: boolean = false;
  dialogModalRef?: MatDialogRef<ModalDialogWrapperInterface>;
  verticalScrollDisabled: boolean = false;

  // Modal Style
  styleNoMargin?: boolean; // default false
  styleHeaderDivider?: boolean; // default false

  createComponentLoader: boolean = false;

  @Input() dialogData?: DialogData<ModalDialogWrapperInterface>;
  isModal?: boolean;
  isDialog?: boolean;

  constructor(
    private modalController: ModalController,
    private cdr: ChangeDetectorRef,
    public layout: LayoutService,
    private _dialog: MatDialog,
  ) {}

  ngAfterViewInit(): void {
    this.factory = this.dialogData?.factory;
    this.factoryInput = this.dialogData?.factoryInput;

    this.headerTitle =
      this.dialogData?.wrapperInput.headerTitle || $localize`:@@modalTitle:Modal Title`;
    this.headerShow = this.dialogData?.wrapperInput.headerShow ?? true;
    this.headerShowWithoutMargin = this.dialogData?.wrapperInput.headerShowWithoutMargin || false;
    this.headerCloseActionShow = this.dialogData?.wrapperInput.headerCloseActionShow ?? true;
    this.headerConfirmActionShow = this.dialogData?.wrapperInput.headerConfirmActionShow || false;
    this.headerThreeDotActionShow = this.dialogData?.wrapperInput.headerThreeDotActionShow || false;
    this.verticalScrollDisabled = this.dialogData?.wrapperInput.overflowYHidden || false;

    this.styleNoMargin = this.dialogData?.wrapperInput.styleNoMargin || false;
    this.styleHeaderDivider = this.dialogData?.wrapperInput.styleHeaderDivider || false;

    this._createComponent();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
  }

  private _createComponent(): void {
    if (this.container && this.factory) {
      this.container.clear();
      this.componentRef = this.container.createComponent(this.factory);

      // Pass all @Input data for subcomponent via factoryInput: [{ inputName: 'inputValue' }]
      for (const element of this.factoryInput ?? []) {
        const currentElementKey = Object.keys(element)[0];
        if (currentElementKey) {
          (this.componentRef.instance as Record<string, unknown>)[currentElementKey] =
            element[currentElementKey];
        }
      }
      // Pass Modal Reference to component
      if (this.componentRef) {
        this.componentRef.instance.modalController = this.modalController;
        this.componentRef.instance.dialogModalRef = this.dialogModalRef;
        this.componentRef.instance.isModal = true;
        this.componentRef.instance.isDialog = false;
      }
    }

    this.createComponentLoader = true;
  }

  openThreeDotMenu(): void {
    // TODO
  }

  confirmDialog(): void {
    this.modalController.dismiss({
      confirmed: true,
      componentInstance: this.componentRef?.instance,
    });
    this.componentRef?.destroy();
  }

  closeDialog(): void {
    if (this.componentRef?.instance?.showDiscardPopup) {
      const localDialogModalRef = this._dialog.open(ConfirmationPopUpComponent, {
        width: DEFAULT_DIALOG_WIDTH,
        height: 'auto',
        minWidth: this.layout.isXSmallView.getValue() ? undefined : DEFAULT_DIALOG_WIDTH,
        maxWidth: this.layout.isXSmallView.getValue() ? undefined : '500px',
      });
      localDialogModalRef.afterClosed().subscribe((data) => {
        if (data.confirmation) {
          this.modalController.dismiss({
            confirmed: false,
            componentInstance: this.componentRef?.instance,
          });
          this.componentRef?.destroy();
        }
      });
    } else {
      this.modalController.dismiss({
        confirmed: false,
        componentInstance: this.componentRef?.instance,
      });
      this.componentRef?.destroy();
    }
  }
}
