import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UnreachableCaseError } from '@remberg/global/common/core';
import { LocalStorageKeys, SimulateIonicPlatformEnum, StorageService } from '@remberg/global/ui';
import { GlobalSelectors, RootGlobalState } from '@remberg/ui-core/core';
import { firstValueFrom, map } from 'rxjs';

export enum SimulationModesEnum {
  WEB = 'Web (default)',
  IONIC_ANDROID = 'ionic Android',
  IONIC_IOS = 'ionic iOS',
}

const MAP_SIMULATED_IONIC_TYPE_TO_SIMULATION_MODE = {
  [SimulateIonicPlatformEnum.IOS]: SimulationModesEnum.IONIC_IOS,
  [SimulateIonicPlatformEnum.ANDROID]: SimulationModesEnum.IONIC_ANDROID,
};

@Component({
  selector: 'app-simulation-picker',
  templateUrl: './simulation-picker.component.html',
  styleUrls: ['./simulation-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationPickerComponent {
  protected readonly simulationModes = Object.values(SimulationModesEnum);
  protected currentSimulationMode = SimulationModesEnum.WEB;
  protected readonly isXSmallView$ = this.store
    .select(GlobalSelectors.selectBreakpoints)
    .pipe(map((breakpoints) => breakpoints?.isXSmallView));

  protected readonly currentSimulationMode$ = this.store
    .select(GlobalSelectors.selectDeviceType)
    .pipe(
      map((deviceType) =>
        deviceType?.simulatedIonicType
          ? MAP_SIMULATED_IONIC_TYPE_TO_SIMULATION_MODE[deviceType.simulatedIonicType]
          : SimulationModesEnum.WEB,
      ),
    );
  protected readonly currentSimulationModeIcon$ = this.currentSimulationMode$.pipe(
    map((mode) => (mode !== SimulationModesEnum.WEB ? 'phone_android' : 'computer')),
  );

  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly storageService: StorageService,
  ) {}

  protected async onSelectMode(selection: SimulationModesEnum): Promise<void> {
    const currentApplicationDomain = await firstValueFrom(
      this.store.select(GlobalSelectors.selectApplicationRootDomain),
    );
    const serverName = currentApplicationDomain?.split('.')[0];
    switch (selection) {
      case SimulationModesEnum.WEB:
        localStorage.removeItem(LocalStorageKeys.SIMULATE_IONIC);
        window.location.reload();
        break;
      case SimulationModesEnum.IONIC_ANDROID:
      case SimulationModesEnum.IONIC_IOS:
        localStorage.setItem(LocalStorageKeys.SIMULATE_IONIC, SimulateIonicPlatformEnum.ANDROID);
        if (selection === SimulationModesEnum.IONIC_IOS) {
          localStorage.setItem(LocalStorageKeys.SIMULATE_IONIC, SimulateIonicPlatformEnum.IOS);
        }
        if (serverName) {
          if (serverName.includes('preview')) {
            this.storageService.set(LocalStorageKeys.IONIC_CURRENT_SERVER_NAME, 'preview');
          } else {
            this.storageService.set(LocalStorageKeys.IONIC_CURRENT_SERVER_NAME, serverName);
          }
        }
        window.location.reload();
        break;
      default:
        throw new UnreachableCaseError(selection);
    }
  }
}
