import { Pipe, PipeTransform } from '@angular/core';
import { FileSizeUnit, formatFileSize } from '../helpers/format-file-size';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  public transform(bytes: number | undefined): string;
  public transform(bytes: number | undefined, precision: number): string;
  public transform(bytes: number | undefined, unit: FileSizeUnit): string;
  public transform(bytes: number | undefined, unit: FileSizeUnit, precision: number): string;
  public transform(bytes = 0, unitOrPrecision?: number | FileSizeUnit, precision?: number): string {
    return formatFileSize(bytes, unitOrPrecision, precision);
  }
}
