import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { matFormFieldAnimations } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { PhoneNumber } from '@remberg/global/common/core';
import { CountryCode } from '@remberg/global/ui';
import { GlobalSelectors, RootGlobalState } from '@remberg/ui-core/core';
import { Subscription, map } from 'rxjs';

/**
 * Sets an error if there's no countryCode and user touched the control.
 *
 * Missing country code is a result of import job and we don't want to force user to actually
 * select the country code if he didn't touch the control.
 *
 * This should be set on the upper level FormControl, see
 * libs/crm/ui/contacts-shared-core/src/components/contacts-properties-form/contacts-properties-form.component.ts
 * for details.
 */
export function missingCountryCodeValidator(
  control: AbstractControl<PhoneNumber | undefined>,
): ValidationErrors | null {
  if (!control.value || !control.dirty) return null;

  const { number, countryPrefix } = control.value;
  return number && !countryPrefix ? { missingCountryCode: true } : null;
}

/**
 * This component differs from app-ohone-number-input in 2 crucial ways:
 * - it accepts undefined phone number value
 * - it accepts undefined country code
 * - it doesn't provide a default country code by itself if it's missing in the value
 *      (if a default is needed, it should be provided by the parent component)
 *
 * Generally it's just an input component that doesn't implement business logic of deciding the value.
 *
 * This was done to keep the flow unidirectional (provide a value, propagate a value back)
 * without any extras. It was needed so that phone numbers without country codes can still be saved
 * if not touched by the user. Such phone numbers come from import feature in many different formats
 * and user should be able to adjust related objects without having to fix the phone number.
 */
@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  animations: [matFormFieldAnimations.transitionMessages],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() public label = $localize`:@@phoneNumber:Phone number`;
  @Input() public fullWidth?: boolean;
  @Input() public inputErrorMessage?: string;
  @Input() public minLength?: number | null = 0;
  @Input() public maxLength?: number | null = null;
  @Input() public allowedSpecialCharacters?: string = '';
  @Input() public required = false;

  protected filteredCountryCodeOptions: [string, string, string][] = CountryCode;
  protected phoneNumberRegex = '';

  protected readonly errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (): boolean =>
      (this.numberControl.invalid && this.numberControl.dirty) ||
      (!!this.inputErrorMessage && this.isParentDirty),
  };

  protected readonly numberControl = new FormControl<string>('', { nonNullable: true });
  protected readonly countryCodeControl = new FormControl<[string, string, string] | undefined>(
    undefined,
    { nonNullable: true },
  );
  private readonly formGroup = new FormGroup({
    number: this.numberControl,
    countryCode: this.countryCodeControl,
  });

  protected readonly animationState = 'enter';
  protected readonly translations = {
    search: $localize`:@@search:Search`,
    noEntriesFound: $localize`:@@noEntriesFound:No Entries Found`,
  };

  protected readonly isAndroidOrIos$ = this.store.select(GlobalSelectors.selectIsAndroidOrIos);

  private isParentDirty = false;
  private readonly countryCodeOptions: [string, string, string][] = CountryCode;
  private readonly subscription = new Subscription();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly store: Store<RootGlobalState>,
    @Optional() @Self() private readonly ngControl?: NgControl,
  ) {
    if (this.ngControl) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    if (this.ngControl?.control?.hasValidator(Validators.required)) {
      this.required = true;
    }

    this.phoneNumberRegex = this.allowedSpecialCharacters
      ? `[0-9 ${this.allowedSpecialCharacters}]*`
      : '[0-9 ]*';

    const parentControl = this.ngControl?.control?.parent;
    if (parentControl) {
      this.subscription.add(
        parentControl.statusChanges.subscribe(() => {
          this.isParentDirty = parentControl.dirty;
          this.cdRef.markForCheck();
        }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public writeValue(phoneNumber: PhoneNumber | null): void {
    if (!phoneNumber) {
      this.numberControl.reset('', { emitEvent: false });
      this.countryCodeControl.reset(undefined, { emitEvent: false });
    } else {
      this.numberControl.reset(phoneNumber.number, { emitEvent: false });
      const country = phoneNumber.countryPrefix
        ? this.resolveCountryByPrefix(phoneNumber.countryPrefix)
        : undefined;
      this.countryCodeControl.reset(country, { emitEvent: false });
    }
  }

  public registerOnChange(onChangeFn: () => void): void {
    this.subscription.add(
      this.formGroup.valueChanges
        .pipe(map(({ number, countryCode }) => ({ number, countryPrefix: countryCode?.[2] })))
        .subscribe(onChangeFn),
    );
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.numberControl.disable();
    } else {
      this.numberControl.enable();
    }
  }

  public registerOnTouched(onTouchedFn: () => void): void {
    this.subscription.add(this.numberControl.valueChanges.subscribe(onTouchedFn));
  }

  protected get errorMessage(): string | undefined {
    if (this.inputErrorMessage) {
      return this.inputErrorMessage;
    }

    const errors = this.numberControl.errors ?? {};

    switch (true) {
      case !!errors['required']:
        return $localize`:@@phoneNumberIsRequired:Phone number is required`;
      case !!errors['minlength']:
        return $localize`:@@inputTooShort:Input too short`;
      case !!errors['maxlength']:
        return $localize`:@@inputTooLong:Input too long`;
      case !!errors['pattern']:
        return $localize`:@@invalidPhoneNumber:Invalid phone number`;
      default:
        return undefined;
    }
  }

  protected countryCodeSelected($event: MatSelectChange): void {
    this.countryCodeControl.setValue($event.value);
  }

  protected filterCountryCodeOptions(searchString: string): void {
    const searchTerm = searchString.replace('+', '').toLowerCase();
    this.filteredCountryCodeOptions = this.countryCodeOptions.filter(
      (value: [string, string, string]) => value.join(' ').toLowerCase().includes(searchTerm),
    );
  }

  protected compareWith(o1: typeof CountryCode, o2: typeof CountryCode): boolean {
    return o1?.[0] === o2?.[0] && o1?.[1] === o2?.[1] && o1?.[2] === o2?.[2];
  }

  /**
   * Wojciech: searching by prefix opens us up for some ambiguity
   * eg for +1 we have US, Canada and Dominican Republic - Canada will be selected
   * Temporary fix is searching in reverse order
   *
   * List of duplicates:
   * {
   *  1: [
   *    'Canada', 'Dominican Republic (República Dominicana)', 'Jamaica', 'Puerto Rico',
   *    'United States'
   *  ],
   *  7: [ 'Kazakhstan (Казахстан)', 'Russia (Россия)' ],
   *  39: [ 'Italy (Italia)', 'Vatican City (Città del Vaticano)' ],
   *  44: [ 'Guernsey', 'Isle of Man', 'Jersey', 'United Kingdom' ],
   *  47: [ 'Norway (Norge)', 'Svalbard and Jan Mayen' ],
   *  61: [ 'Australia', 'Christmas Island', 'Cocos (Keeling) Islands' ],
   *  64: [ 'New Zealand', 'Pitcairn' ],
   *  212: [ 'Morocco (‫المغرب‬‎)', 'Western Sahara (‫الصحراء الغربية‬‎)' ],
   *  262: [ 'French Southern Territories', 'Mayotte', 'Réunion (La Réunion)' ],
   *  358: [ 'Åland Islands', 'Finland (Suomi)' ],
   *  500: [
   *    'Falkland Islands (Islas Malvinas)', 'South Georgia and the South Sandwich Islands'
   *  ],
   *  590: [
   *    'Guadeloupe', 'Saint Barthélemy', 'Saint Martin (Saint-Martin (partie française))'
   *  ],
   *  599: [ 'Caribbean Netherlands', 'Curaçao' ],
   *  672: [ 'Heard Island and McDonald Islands', 'Norfolk Island' ]
   *}
   */
  private resolveCountryByPrefix(prefix: string): [string, string, string] | undefined {
    const country = this.countryCodeOptions
      .slice()
      .reverse()
      .find((country) => country[2] === prefix);

    return country?.length === 3 ? country : undefined;
  }
}
