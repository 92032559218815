import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  API_URL_PLACEHOLDER,
  environment,
  LocalStorageKeys,
  LogService,
  StorageService,
} from '@remberg/global/ui';
import { BehaviorSubject, firstValueFrom, ReplaySubject } from 'rxjs';
import { RootGlobalState } from '../store/core-ui.definitions';
import { GlobalActions } from '../store/global/global.actions';
import { GlobalSelectors } from '../store/global/global.selectors';

const API_ENDPOINT = '/api';
const FILES_ENDPOINT = '/files';
const FILES_DOWNLOAD_ENDPOINT = '/api/files/v2/download/';

@Injectable({
  providedIn: 'root',
})
export class ServerConfigurationService {
  public currentServerUrl: string = '';
  public currentServerName: string = '';

  public apiUrl = new BehaviorSubject<string>('/api');
  protected filesUrl = new BehaviorSubject<string>('/files');
  public filesDownloadUrl = new BehaviorSubject<string>('/api/files/v2/download/');
  public showDebugInformation$ = new BehaviorSubject<boolean>(false);

  /** This subject will emit one true boolean once the server configuration service has been setup.
   * This is needed before any service can be sure to talk to the correct api endpoints.
   */
  public ready = new ReplaySubject<boolean>(1);

  constructor(
    private readonly logger: LogService,
    private readonly store: Store<RootGlobalState>,
    private readonly storage: StorageService,
  ) {}

  public async initialize(): Promise<void> {
    this.logger.debug()('ServerConfigurationService - initialize', environment);
    const storedServerName = await this.storage.get(LocalStorageKeys.IONIC_CURRENT_SERVER_NAME);
    const storedServerUrl = await this.storage.get(LocalStorageKeys.IONIC_CURRENT_SERVER_URL);
    this.store.dispatch(GlobalActions.setServerUrl({ serverUrl: storedServerName }));
    this.store.dispatch(GlobalActions.setServerName({ serverName: storedServerUrl }));

    this.showDebugInformation$.next(!!environment.debug);
    let serverName = new URL(window.location.href).searchParams.get('server');

    if (!serverName) {
      serverName = storedServerName;
    }

    const isStoredServerNameValidForEnvironment =
      serverName &&
      environment['servers'] &&
      Object.keys(environment['servers']).includes(serverName);

    this.filesDownloadUrl.subscribe((url) =>
      this.store.dispatch(GlobalActions.setFilesDownloadUrl({ url })),
    );

    if (isStoredServerNameValidForEnvironment && serverName) {
      this.logger.debug()('Using the stored server name: ' + serverName);

      // in case of preview environment load the selected URL from local storage
      if (serverName === 'preview') {
        const previewId = new URL(window.location.href).searchParams.get('preview-id');
        const previewServerUrl = previewId
          ? `https://preview-${previewId}.remberg.dev`
          : storedServerUrl;
        if (!previewServerUrl) {
          throw new Error('Missing preview environment-id for server selection');
        }
        this.setServerUrlOnSelection(serverName, previewServerUrl);
      } else {
        this.setServerUrlOnSelection(serverName);
      }

      // if there is a storedServerName (also on production) other than environment.defaultServer
      // it means the app is beeing tested so show the server picker right away in this case
      if (serverName != environment.defaultServer) {
        this.showDebugInformation$.next(true);
      }
    } else if (environment.defaultServer) {
      this.logger.debug()('Using the default server name: ' + environment.defaultServer);
      this.setServerUrlOnSelection(environment.defaultServer);
    } else {
      this.logger.debug()('ServerConfigurationService - nothing 1');
    }
    this.emitReady();
  }

  public getAvailableServerOptions(): string[] {
    return environment['servers'] ? Object.keys(environment['servers']) : [];
  }

  private emitReady(): void {
    this.logger.debug()('Server configuration service is ready.');
    this.ready.next(true);
  }

  public setServerUrlOnSelection(serverName: string, serverUrl?: string): string {
    this.logger.debug()('setServerUrlOnSelection called.');
    if (environment['servers'] && Object.keys(environment['servers']).includes(serverName)) {
      this.currentServerUrl =
        serverUrl ?? environment['servers'][serverName as keyof (typeof environment)['servers']];
      this.currentServerName = serverName;
      this.store.dispatch(GlobalActions.setServerName({ serverName }));
      this.logger.debug()('Updating current server to: ' + this.currentServerUrl);
      this.apiUrl.next(this.currentServerUrl + API_ENDPOINT);
      this.filesUrl.next(this.currentServerUrl + FILES_ENDPOINT);
      this.filesDownloadUrl.next(this.currentServerUrl + FILES_DOWNLOAD_ENDPOINT);

      // in case there was a server url passed, persist the url in local storage
      if (serverUrl) {
        this.store.dispatch(GlobalActions.setServerUrl({ serverUrl }));
      }
    } else {
      this.logger.warn()('Could not update current server to: ' + serverName);
    }
    return this.currentServerUrl;
  }

  public async showDebugInformation(): Promise<void> {
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    if (isIonic && !this.showDebugInformation$.getValue()) {
      this.showDebugInformation$.next(true);
    }
  }

  public getApiUrlForServer(url: string): string {
    return url.replace(API_URL_PLACEHOLDER, this.apiUrl.getValue());
  }
}
