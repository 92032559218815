import { Store } from '@ngrx/store';
import { Observable, combineLatest, map, of } from 'rxjs';
import { FeatureFlagKey, PermissionAction } from '../definitions/permission-checks';
import { RootGlobalState } from '../store/core-ui.definitions';
import { GlobalSelectors } from '../store/global/global.selectors';

export function getHasAccess$(
  store: Store<RootGlobalState>,
  action?: PermissionAction,
  featureFlag?: FeatureFlagKey,
  viewCheck?: boolean,
): Observable<boolean> {
  const permission$ = action
    ? store.select(GlobalSelectors.selectHasPermission(action, viewCheck))
    : of(true);
  const featureFlag$ = featureFlag
    ? store.select(GlobalSelectors.selectHasFeature(featureFlag))
    : of(true);

  return combineLatest([permission$, featureFlag$]).pipe(
    map(([hasPermission, hasFeature]) => hasPermission && hasFeature),
  );
}
