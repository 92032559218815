import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  TimeZoneEnum,
  UnreachableCaseError,
} from '@remberg/global/common/core';
import { add } from 'date-fns';
import { AdvancedFilterWithinPeriodEnum } from '../enums/advanced-filter-within-period.enum';

export function getAdvancedFilterDateRangeForPeriod(
  period: AdvancedFilterWithinPeriodEnum,
  tz?: TimeZoneEnum,
): {
  start: Date;
  end: Date;
} {
  const now = new Date();

  switch (period) {
    case AdvancedFilterWithinPeriodEnum.TODAY:
      return { start: startOfDay(now, tz), end: endOfDay(now, tz) };
    case AdvancedFilterWithinPeriodEnum.TOMORROW: {
      const tomorrow = add(now, { days: 1 });
      return { start: startOfDay(tomorrow, tz), end: endOfDay(tomorrow, tz) };
    }
    case AdvancedFilterWithinPeriodEnum.YESTERDAY: {
      const yesterday = add(now, { days: -1 });
      return { start: startOfDay(yesterday, tz), end: endOfDay(yesterday, tz) };
    }

    case AdvancedFilterWithinPeriodEnum.THIS_WEEK:
      return {
        start: startOfWeek(now, tz),
        end: endOfWeek(now, tz),
      };
    case AdvancedFilterWithinPeriodEnum.NEXT_WEEK: {
      const nextWeek = add(now, { weeks: 1 });
      return {
        start: startOfWeek(nextWeek, tz),
        end: endOfWeek(nextWeek, tz),
      };
    }
    case AdvancedFilterWithinPeriodEnum.LAST_WEEK: {
      const lastWeek = add(now, { weeks: -1 });
      return {
        start: startOfWeek(lastWeek, tz),
        end: endOfWeek(lastWeek, tz),
      };
    }

    case AdvancedFilterWithinPeriodEnum.THIS_MONTH:
      return { start: startOfMonth(now, tz), end: endOfMonth(now, tz) };
    case AdvancedFilterWithinPeriodEnum.NEXT_MONTH: {
      const nextMonth = add(now, { months: 1 });
      return { start: startOfMonth(nextMonth, tz), end: endOfMonth(nextMonth, tz) };
    }
    case AdvancedFilterWithinPeriodEnum.LAST_MONTH: {
      const lastMonth = add(now, { months: -1 });
      return { start: startOfMonth(lastMonth, tz), end: endOfMonth(lastMonth, tz) };
    }
    default:
      throw new UnreachableCaseError(period);
  }
}
