@let dataSource = dataSource$ | async;
@let virtualScrollHeight = virtualScrollHeight$ | async;
@let isLoading = isLoading$ | async;
@let totalCount = totalCount$ | async;
@let currentBatchSize = infiniteListContainerStore.batchSize$ | async;

@if (!!dataSource?.length || isLoading) {
  <cdk-virtual-scroll-viewport
    class="virtual-scroll-wrapper"
    [itemSize]="itemSize$ | async"
    (scrolledIndexChange)="fetchNextBatch()"
    [minBufferPx]="virtualScrollHeight"
    [maxBufferPx]="virtualScrollHeight"
    [ngStyle]="{ 'height.px': !isFullScreen && virtualScrollHeight }"
    [ngClass]="{
      'overflow-hidden': !isFullScreen && currentBatchSize === totalCount,
    }"
  >
    <ng-container
      *cdkVirtualFor="
        let data of dataSource;
        let i = index;
        templateCacheSize: templateCacheSize;
        trackBy: trackByFn
      "
    >
      <ng-template
        [ngTemplateOutlet]="templateRef"
        [ngTemplateOutletContext]="{ $implicit: data, index: i }"
      ></ng-template>
    </ng-container>
    @if (isLoading) {
      <app-loader></app-loader>
    }
  </cdk-virtual-scroll-viewport>
} @else {
  <ng-content select="[slot=placeholder]"></ng-content>
}
