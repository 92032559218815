import { ComponentType } from '@angular/cdk/portal';
import { ComponentFactory, Type } from '@angular/core';
import { DialogPosition, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalController } from '@ionic/angular';
import {
  DOMRectSnapshot,
  DynamicButtonConfig,
  getBoundingClientRectSnapshot,
} from '@remberg/global/ui';
import assert from 'assert';
import { firstValueFrom } from 'rxjs';

export interface DialogOptions<T> {
  childComponent: Type<T>;

  // dialog options
  dialogData: DialogData<T>;
  dialogAutoFocus?: boolean; // default to false
  dialogRestoreAutoFocus?: boolean; // default to false
  dialogBackdropCloseDisabled?: boolean; // default to false
  dialogBackdropClass?: string | string[];
  dialogHasBackdrop?: boolean;
  dialogCssClass?: string[];

  // modal options
  modalKeyboardClose?: boolean;
  modalBackdropDismiss?: boolean;
  modalCanDismiss?: boolean;
  modalCssClass?: string[] | undefined;
  modalShowBackdrop?: boolean;

  isModal?: boolean;
  isDialog?: boolean;
}

export interface DialogWrapperInput {
  // Modal Header Input Options
  headerShow?: boolean;
  headerShowWithoutMargin?: boolean;
  removeBottomMargin?: boolean;
  overflowXHidden?: boolean;
  overflowYHidden?: boolean;
  headerTitle?: string;
  headerCloseActionShow?: boolean;
  headerConfirmActionShow?: boolean;
  headerThreeDotActionShow?: boolean;

  // Style Input Options
  styleFullscreen?: boolean;
  styleNoMargin?: boolean;
  styleStickyHeader?: boolean;
  styleHeight?: string;
  styleWidth?: string;
  styleMaxWidth?: string;
  styleMaxHeight?: string;
  styleMinWidth?: string;
  styleMinHeight?: string;
  styleZIndex?: string;
  styleHeaderDivider?: boolean;

  // dialog wrapper confirmation logic
  showButtons?: boolean;
  hideAbortButton?: boolean;
  buttonsDirection?: 'vertical' | 'horizontal';
  buttons?: DynamicButtonConfig[];
}

export interface DialogData<T> {
  factory?: ComponentFactory<T>;
  // TODO: We should really find a way to type this.
  factoryInput: any[];
  wrapperInput: DialogWrapperInput;
  dialogModalRef?: MatDialogRef<ModalDialogWrapperInterface>;
  isModal?: boolean;
  isDialog?: boolean;
  materialConfig?: MatDialogConfig;
}

export interface DialogResultData {
  confirmed?: boolean;
  data?: any;
  componentInstance?: any;
}

function getDialogPositionForDOMRect(
  { top, right, bottom, left, height }: DOMRectSnapshot,
  dialogHeight = 0,
  dialogWidth = 0,
): DialogPosition {
  const marginFromTarget = 16;
  const windowHeight = document.body.clientHeight;
  const windowWidth = document.body.clientWidth;

  let targetTop = top + height + marginFromTarget;
  let targetRight: number | undefined = windowWidth - right;
  let targetLeft: number | undefined = left;

  const hasMoreSpaceAbove = top > windowHeight - bottom && top > marginFromTarget + dialogHeight;
  const exceedsScreenYBounds = targetTop + dialogHeight > windowHeight;

  if (exceedsScreenYBounds) {
    targetTop = hasMoreSpaceAbove
      ? top - marginFromTarget - dialogHeight // Position above
      : Math.max(windowHeight - dialogHeight, 0); // Maximize visible height
  }

  const hasMoreSpaceRight = windowWidth - left > left;

  const dialogPlacedLeft = targetRight + dialogWidth;
  const dialogPlacedRight = left + dialogWidth;
  const exceedsScreenXBounds = dialogPlacedLeft > windowWidth && dialogPlacedRight > windowWidth;

  if (exceedsScreenXBounds) {
    targetRight = Math.max(windowWidth - marginFromTarget - dialogWidth, 0); // Maximize visible width
    targetLeft = Math.max(windowWidth - marginFromTarget - dialogWidth, 0);
  }

  return {
    top: `${targetTop}px`,
    ...(!hasMoreSpaceRight && { right: `${targetRight}px` }),
    ...(hasMoreSpaceRight && { left: `${targetLeft}px` }),
  };
}
/**
 * Specialized method for opening the specified dialog relative to the desired position
 * indicated via targetDomRect
 * *
 * @template T - Type of the data param to be propogated to the component
 * @param {T} data -The data parameter propagated to the component
 * @param {DOMRectSnapshot} targetDomRect - The reference element's DOMRectSnapshot used for positioning the dialog
 * @param {string[]} [panelClass] - Provided panel class(es) for styling
 * @returns {MatDialogConfig} A mat dialog config object
 */
export function getDialogConfigNextToTarget<T>(
  data: T,
  targetDomRect: DOMRectSnapshot,
  panelClass: string[] = [],
): MatDialogConfig {
  const dialogConfig = new MatDialogConfig<T>();
  dialogConfig.position = getDialogPositionForDOMRect(targetDomRect);
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = false;
  dialogConfig.data = data;
  dialogConfig.panelClass = [...panelClass, 'positioned-dialog'];

  return dialogConfig;
}

/**
 * This function readjusts the position of a dialog in the situation to avoid
 * cases where a dialog might exist beyond the dimensions of the current window
 *
 * @async
 * @template E - Type of the component being referenced
 * @param {MatDialogRef<E, any>} dialogRef - Reference to a dialog opened via the MatDialog service
 * @param {DOMRectSnapshot} targetDomRect - The reference element's DOMRectSnapshot used for positioning the dialog
 */
export async function positionDialogNextToTarget<E>(
  dialogRef: MatDialogRef<E, any>,
  targetDomRect: DOMRectSnapshot,
): Promise<void> {
  await firstValueFrom(dialogRef.afterOpened());
  const elementRef = document.getElementById(dialogRef.id);
  const { top, right, left } = getDialogPositionForDOMRect(
    targetDomRect,
    elementRef?.clientHeight,
    elementRef?.clientWidth,
  );
  if (!elementRef?.parentElement) return;
  elementRef.parentElement.style.marginRight = right ?? '0px';
  elementRef.parentElement.style.marginTop = top ?? '0px';
  elementRef.parentElement.style.marginLeft = left ?? '0px';
}

interface OpenDialogConfig<T, E> {
  data?: T;
  targetDomRect: DOMRectSnapshot;
  panelClass?: string[];
  matDialog: MatDialog;
  component: ComponentType<E>;
}

/**
 * This function opens a dialog specifically positioned next to the target, repositioning the
 * dialog as necessary
 *
 * @async
 * @template T - [The type of the data param being passed to the dialog]
 * @template E - [The type of the component being created]
 * @template R - [The type of the data returned by the dialog]
 * @param {T} .data - The data parameter propagated to the component
 * @param {DOMRectSnapshot} .targetDomRect - The reference element's DOMRectSnapshot used for positioning the dialog
 * @param {string[]} .panelClass] - Provided panel class(es) for styling
 * @param {MatDialog} .matDialog - Reference to the MatDialog service used to open the dialog
 * @param {ComponentType<E>} .component - The component being initialized with matDialog
 * @returns {Promise<MatDialogRef<E, R>>} - Reference to the newly created dialog.
 */
export async function openDialogNextToTarget<T, E, R = any>({
  data,
  targetDomRect,
  panelClass = [],
  matDialog,
  component,
}: OpenDialogConfig<T, E>): Promise<MatDialogRef<E, R>> {
  const dialogRef = matDialog.open<E, T, R>(
    component,
    // This is necessary to avoid a snapping effect with major
    // dialog repositioning after opening
    getDialogConfigNextToTarget(data, targetDomRect, panelClass),
  );
  await positionDialogNextToTarget<E>(dialogRef, targetDomRect);
  return dialogRef;
}

export function getMobileDialogConfig<T>(data: T, panelClass: string[] = []): MatDialogConfig {
  const dialogConfig = new MatDialogConfig<T>();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = false;
  dialogConfig.data = data;
  dialogConfig.panelClass = [...panelClass, 'mobile-device-full-screen'];
  return dialogConfig;
}

export function getDomRectOfEventTarget(currentTarget: EventTarget | null): DOMRectSnapshot {
  assert(currentTarget instanceof HTMLElement, 'currentTarget must be a defined HTMLElement');
  return getBoundingClientRectSnapshot(currentTarget);
}

export interface ModalDialogWrapperInterface {
  isModal?: boolean;
  isDialog?: boolean;
  dialogModalRef?: MatDialogRef<ModalDialogWrapperInterface> | HTMLIonModalElement;
  showDiscardPopup?: boolean;
  disableParentConfirmButton?: boolean;
  modalController?: ModalController;
  closeDialogModalFromChildComponent?: (data?: any, forceConfirm?: boolean) => void;
}
