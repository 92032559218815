export enum MaintenancePlanPopulateEnum {
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',

  ASSOCIATIONS_RELATED_ASSETS = 'associationsRelatedAssets',
  ASSOCIATIONS_RELATED_CONTACTS = 'associationsRelatedContacts',
  ASSOCIATIONS_RELATED_ORGANIZATIONS = 'associationsRelatedOrganizations',
  ASSOCIATIONS_LAST_GENERATED_WORK_ORDER = 'associationsLastGeneratedWorkOrder',

  WORK_ORDER_TEMPLATE_STATUS = 'workOrderTemplateStatus',
  WORK_ORDER_TEMPLATE_TYPE = 'workOrderTemplateType',
  WORK_ORDER_TEMPLATE_RESPONSIBLE_CONTACT = 'workOrderTemplateResponsibleContact',
  WORK_ORDER_TEMPLATE_RESPONSIBLE_GROUP = 'workOrderTemplateResponsibleGroup',
  WORK_ORDER_TEMPLATE_ASSIGNED_CONTACTS = 'workOrderTemplateAssignedContacts',
  WORK_ORDER_TEMPLATE_ASSIGNED_GROUPS = 'workOrderTemplateAssignedGroups',
}
