import { InjectionToken } from '@angular/core';
import { OfflineService } from '@remberg/global/ui';
import { UserGroup } from '@remberg/users/common/main';

export interface UserGroupOfflineServiceInterface extends OfflineService<UserGroup, never, never> {
  getUserGroupsByTenant(search?: string, ownGroupsOnly?: boolean): Promise<UserGroup[]>;
}

export const USER_GROUP_OFFLINE_SERVICE = new InjectionToken<UserGroupOfflineServiceInterface>(
  'Token for injecting the user groups offline service without circular dependencies',
);
