import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { DialogOptions } from '../dialogs/dialogs';
import { ModalDialogWrapper } from '../dialogs/modalDialogWrapper';
import { RootGlobalState } from '../store/core-ui.definitions';

/**
 * @deprecated Use matDialog instead
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private _modal: ModalController,
    private _dialog: MatDialog,
    private _resolver: ComponentFactoryResolver,
    private platform: Platform,
    private store: Store<RootGlobalState>,
    private logger: LogService,
  ) {}

  /**
   * @description This is the default function to call for a modal / dialog.
   * @param dialogOpts Options for styling and configuration.
   * @param openModal Main flag when to open in modal or dialog. Provie flag via layout service.
   * @returns Returns the reference to the wrapper.
   */
  showDialogOrModal<T>(
    dialogOpts: DialogOptions<T>,
    openModal: boolean = false,
  ): ModalDialogWrapper {
    dialogOpts.dialogData.factory = this._resolver.resolveComponentFactory<T>(
      dialogOpts.childComponent,
    );
    const ref = new ModalDialogWrapper(this._modal, this._dialog, this.platform, this.store);
    ref.asyncInit(openModal, dialogOpts);
    return ref;
  }

  /**
   * @description This is a function to close all modals and dialogs for edge cases like forced logout
   */
  async closeAll(): Promise<void> {
    // Close all dialogs
    this._dialog.closeAll();

    // Close all modals
    let currentModal = await this._modal.getTop();
    while (currentModal) {
      if (!(await currentModal.dismiss())) {
        this.logger.debug()('Could not close topmost modal!');
      }
      currentModal = await this._modal.getTop();
    }
  }
}
