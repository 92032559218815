import { RouterReducerState, RouterState } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';
import { GlobalState } from './global/global.definitions';

export const GLOBAL_STORE_NAME = 'global';

export interface RootGlobalState {
  [GLOBAL_STORE_NAME]: GlobalState;
}

export interface RootState extends RootGlobalState {
  router?: RouterState;
}

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

// TODO enable strict typing RootGlobalState -> GlobalState
export const selectGlobalState = createFeatureSelector<GlobalState>(GLOBAL_STORE_NAME);
