export enum ActivityFieldTypeEnum {
  TEXT = 'text',
  TEXT_ARRAY = 'textArray',
  HTML = 'html',
  NUMBER = 'number',
  NUMBER_ARRAY = 'numberArray',
  BOOLEAN = 'boolean',
  TRANSLATABLE_ID = 'translatableId',
  TRANSLATABLE_ID_ARRAY = 'translatableIdArray',
  REMBERG_DATE = 'rembergDate',
  REMBERG_DATE_TIME = 'rembergDateTime',
  REMBERG_TIMESTAMP = 'rembergTimestamp',
  REMBERG_TIME = 'rembergTime',
  USER_GROUP = 'userGroup',
  USER_GROUP_ARRAY = 'userGroupArray',
  ADDRESS = 'address',
  CONTACT = 'contact',
  CONTACT_ARRAY = 'contactArray',
  ORGANIZATION = 'organization',
  ORGANIZATION_ARRAY = 'organizationArray',
  MAINTENANCE_PLAN = 'maintenancePlan',
  MAINTENANCE_PLAN_2 = 'maintenancePlan2',
  PLATFORM_FILE = 'platformFile',
  PLATFORM_FILE_ARRAY = 'platformFileArray',
  ASSET = 'asset',
  ASSET_TYPE = 'assetType',
  ASSET_ARRAY = 'assetArray',
  CASE = 'case',
  WORK_ORDER = 'workOrder',
  WORK_ORDER_STATUS = 'workOrderStatus',
  WORK_ORDER_TYPE = 'workOrderType',
  NOTE = 'note',
  CUSTOM_PROPERTIES = 'customProperties',
  TICKET2_CATEGORY = 'category',
  TICKET2 = 'ticket2',
  TICKET2_ARRAY = 'ticket2Array',
  PART = 'part',
  PART_ARRAY = 'partArray',
}

export type ExtendedActivityFieldType = ActivityFieldTypeEnum | string;
