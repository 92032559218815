<div [ngClass]="{ 'display-inline': showInline, 'phone-number-show-in-forms': !showInline }">
  @if (!isLabelHidden) {
    <div class="label">
      @if (required) {
        {{ label }}<span class="required">*</span>
      } @else {
        {{ label }}
      }
    </div>
  }
  @if (showInline) {
    <span class="fill-remaining-space"></span>
  }
  <div class="value" [mdePopoverTriggerFor]="appPhoneNumberDisplayPopover">
    @if (value) {
      @if (showIcon) {
        <div class="icon-wrapper">
          <mat-icon class="smaller-icon">phone</mat-icon>
        </div>
      }
      <a
        data-test-id="phone-number-link"
        class="phone-number"
        [class]="href && !disableNavigation ? 'link' : 'no-link'"
        [href]="!disableNavigation ? href : ''"
      >
        {{ value | phoneNumber }}</a
      >
    } @else {
      <span>-</span>
    }
  </div>

  <mde-popover
    #appPhoneNumberDisplayPopover="mdePopover"
    [mdePopoverTriggerOn]="isTooltipVisible && !disableNavigation ? 'hover' : 'none'"
    [mdePopoverOverlapTrigger]="false"
    [mdePopoverEnterDelay]="POPOVER_DELAY"
    [mdePopoverLeaveDelay]="POPOVER_DELAY"
  >
    <mat-card appearance="outlined">
      @if (value) {
        <span data-test-id="phone-number-value">{{ value | phoneNumber }}</span>
      } @else {
        <span>-</span>
      }

      <a class="copy-to-clipboard" (click)="copyPhoneNumber()"><mat-icon>copy</mat-icon></a>

      @if (href && !disableNavigation) {
        <a class="open-in-new" [href]="!disableNavigation ? href : ''"
          ><mat-icon>open_in_new</mat-icon>
        </a>
      }
    </mat-card>
  </mde-popover>
</div>
