import { DOCUMENT } from '@angular/common';
import { AfterContentInit, Directive, ElementRef, HostListener, Inject } from '@angular/core';

/**
 * This directive is meant for mat-dialog-content component for dialogs with dynamic height and position.

 * Adjusts the max-height of mat-dialog-content to the difference from the top of dialog content
 * to bottom of viewport minus footer height minus 16px padding.
 */
@Directive({ selector: '[rbDialogContentMaxHeight]', standalone: true })
export class DialogMaxHeightDirective implements AfterContentInit {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly el: ElementRef<HTMLElement>,
  ) {}

  @HostListener('window:resize')
  @HostListener('resize')
  public onResize(): void {
    this.adjustMaxHeight();
  }

  public ngAfterContentInit(): void {
    // because dialog appears with an animation and its starting point affects the measurement
    setTimeout(() => this.adjustMaxHeight(), 200);
  }

  private adjustMaxHeight(): void {
    const marginBottom = 16;
    const footerHeight = 90; // 81 for rb-dialog, 89 for old dialogs, doesn't really matter
    const viewportHeight = Math.floor(this.document.body.offsetHeight);
    const dialogContentTop = Math.floor(this.el.nativeElement.getBoundingClientRect().top);
    const adjustedHeight = viewportHeight - dialogContentTop - footerHeight - marginBottom;
    this.el.nativeElement.style.maxHeight = `${adjustedHeight}px`;
  }
}
