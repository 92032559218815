export enum ApplicationRootDomainBaseEnum {
  APP = 'app.remberg.de',
  STAGING = 'staging.remberg.dev',
  TEST01 = 'test01.remberg.dev',
  MIRROR = 'mirror.remberg.dev',
  DEV = 'dev.remberg.dev',
  LOCAL_PREFIX = 'local.localhost',
  PREVIEW_PREFIX = 'preview-',
  PREVIEW_SHORT_PREFIX = 'pr-',
}
