import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFlagEnum } from '@remberg/global/common/core';

export const FEATURE_FLAG_ENUM_TRANSLATIONS: Record<FeatureFlagEnum, string> = {
  [FeatureFlagEnum.ASSETS]: $localize`:@@assets:Assets`,
  [FeatureFlagEnum.CASES]: $localize`:@@serviceCases:Cases`,
  [FeatureFlagEnum.FILES_DOWNLOAD]: $localize`:@@filesDownload:Files Download`,
  [FeatureFlagEnum.CASES_REQUIRE_OEM_FEEDBACK]: $localize`:@@casesrequireOEMFeedback:Cases: Require OEM Feedback`,
  [FeatureFlagEnum.FILES]: $localize`:@@files:Files`,
  [FeatureFlagEnum.API]: $localize`:@@api:API`,
  [FeatureFlagEnum.IMPORT]: $localize`:@@dataImport:Data Import`,
  [FeatureFlagEnum.EXPORT]: $localize`:@@dataExport:Data Export`,
  [FeatureFlagEnum.FORMS]: $localize`:@@forms:Forms`,
  [FeatureFlagEnum.QRCODES]: $localize`:@@qrcodes:QR codes`,
  [FeatureFlagEnum.WORK_ORDERS_HIERARCHY]: $localize`:@@workOrdersColonHierarchy:Work Orders: Hierarchy`,
  [FeatureFlagEnum.WORKORDERS_MAINTENANCEPLANS]: $localize`:@@workOrdersmaintenancePlans:Work Orders: Maintenance Plans`,
  [FeatureFlagEnum.WORKORDERS_SCHEDULING]: $localize`:@@workOrdersSchedulingBoard:Work Orders: Scheduling Board`,
  [FeatureFlagEnum.WORKORDERS_SCHEDULING_HORIZONTAL_SCROLLING]: $localize`:@@workOrdersSchedulingBoardHorizontalScrolling:Work Orders: Scheduling Board Horizontal Scrolling`,
  [FeatureFlagEnum.ASSETS_STATUS]: $localize`:@@assetsStatus:Assets: Status`,
  [FeatureFlagEnum.POWERED_BY_REMBERG]: $localize`:@@emailShowPoweredByRemberg:Email: Show "Powered by remberg"`,
  [FeatureFlagEnum.CASES_CATEGORY]: $localize`:@@casesCategory:Cases: Category`,
  [FeatureFlagEnum.ASSETS_HIERARCHY]: $localize`:@@assetsHierarchy:Assets: Hierarchy`,
  [FeatureFlagEnum.TASKS]: $localize`:@@tasks:Tasks`,
  [FeatureFlagEnum.PARTS]:
    $localize`:@@parts:Parts` +
    ' ' +
    $localize`:@@paidForPortalUsersInBrackets:(Paid for Portal Users)`,
  [FeatureFlagEnum.MOBILEAPP]: $localize`:@@mobileApp:Mobile App`,
  [FeatureFlagEnum.ASSETS_ORGANIZATIONS_AND_CONTACTS]: $localize`:@@assetsOrganizationAndContact:Assets: Organization and Contact`,
  [FeatureFlagEnum.CASES_ORGANIZATION]: $localize`:@@casesOrganization:Cases: Organization`,
  [FeatureFlagEnum.WORKORDERS_ORGANIZATIONS_AND_CONTACTS]: $localize`:@@workOrdersAndMaintenancePlansOrganizationAndContact:Work Orders & Maintenance Plans: Organization and Contact`,
  [FeatureFlagEnum.ASSETS_WORKORDERS_ADDRESS]: $localize`:@@assetsWorkOrdersMaintenancePlansAddress:Assets, Work Orders & Maintenance Plans: Address`,
  [FeatureFlagEnum.APPOINTMENTS_TEMPORARY]: $localize`:@@yTemporaryAppointments:Y_Temporary: Appointments`,
  [FeatureFlagEnum.MAINTENANCE_PLANS_TEMPORARY]: $localize`:@@yTemporaryMaintenancePlans:Y_Temporary: Maintenance Plans`,
  [FeatureFlagEnum.WORK_ORDERS_TEMPORARY]: $localize`:@@yTemporaryWorkOrders:Y_Temporary: Work Orders`,
  [FeatureFlagEnum.DASHBOARDS]:
    $localize`:@@dashboards:Dashboards` + ' ' + $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.GLOBAL_MAP]: $localize`:@@globalMap:Global map`,
  [FeatureFlagEnum.ASSETS_TEMPORARY]: $localize`:@@yTemporaryAssets:Y_Temporary: Assets`,
  [FeatureFlagEnum.TAGS_TEMPORARY]: $localize`:@@yTemporaryTags:Y_Temporary: Tags`,
  [FeatureFlagEnum.ASSET_TYPES_TEMPORARY]: $localize`:@@yTemporaryAssetTypes:Y_Temporary: Asset Types`,
  [FeatureFlagEnum.AI_COPILOT]:
    $localize`:@@xrmAiCopilot:XRM AI Copilot` + ' ' + $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.AI_CASES]:
    $localize`:@@xrmAiCopilotCases:XRM AI Copilot: Cases` +
    ' ' +
    $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.AI_FORMS_SUMMARY]:
    $localize`:@@zAiFormSummary:Z_AI: Form summary` + ' ' + $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.AI_USE_EXPERIMENTAL_MODELS]:
    $localize`:@@xrmAiCopilotUseExperimentalModels:XRM AI Copilot: Use experimental models` +
    ' ' +
    $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.AI_TICKET_INGESTION]:
    $localize`:@@xrmAiCopilotAiTicketIngestion:XRM AI Copilot: AI Ticket Ingestion` +
    ' ' +
    $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.CASES_SUMMARY_TEMPORARY]:
    $localize`:@@yTemporaryCasesSummary:Y_Temporary: Cases: Summary` +
    ' ' +
    $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.TICKETS_PDF_EXPORT]: $localize`:@@ticketsPdfExport:Tickets: PDF Export`,
  [FeatureFlagEnum.AI_ENFORCE_FEEDBACK_TEMPORARY]: $localize`:@@yTemporaryXrmAiCopilotEnforceAiFeedback:Y_Temporary: XRM AI Copilot: Enforce AI Feedback`,
  [FeatureFlagEnum.PORTAL]: $localize`:@@portal:Portal` + ' ' + $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.TICKETS_TEMPORARY]: $localize`:@@yTemporaryTickets2:Y_Temporary: Tickets2`,
  [FeatureFlagEnum.AI_ADD_FORMS_TO_KNOWLEDGE_BASE_TEMPORARY]:
    $localize`:@@yTemporaryXrmAiAddFormsToKnowledgeBase:Y_Temporary: XRM AI Copilot: Add forms with drive file supported email sections to knowledge base` +
    ' ' +
    $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.AI_USE_NEW_PROMPT_TEMPORARY]:
    $localize`:@@yTemporaryXrmAiUseNewPrompt:Y_Temporary: XRM AI Copilot: Use new prompt` +
    ' ' +
    $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.AI_REWRITE_QUERY_TEMPORARY]:
    $localize`:@@yTemporaryXrmAiRewriteUserQuery:Y_Temporary: XRM AI Copilot: Rewrite user query` +
    ' ' +
    $localize`:@@paidInBrackets:(Paid)`,
  [FeatureFlagEnum.MONGODB_DASHBOARD_TEMPORARY]: $localize`:@@yTemporaryMongoDBDashboard:Y_Temporary: MongoDB Dashboard`,
  [FeatureFlagEnum.ASSETS_ENABLE_CUSTOMIZATION]: $localize`:@@assetsCustomizableAssets:Assets: Customizable Assets`,
  [FeatureFlagEnum.AI_CONVERSATIONS_TEMPORARY]:
    $localize`:@@yTemporaryXrmAiConversations:Y_Temporary: XRM AI Copilot: Conversations` +
    ' ' +
    $localize`:@@paidInBrackets:(Paid)`,
};

@Pipe({
  name: 'translateFeatureFlagEnum',
  standalone: true,
})
export class TranslateFeatureFlagEnumPipe implements PipeTransform {
  public transform(value?: FeatureFlagEnum): string {
    return value ? FEATURE_FLAG_ENUM_TRANSLATIONS[value] : '';
  }
}
