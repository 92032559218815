import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { GlobalConfig, GlobalConfigUpdateBody } from '@remberg/tenants/common/main';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigService {
  private readonly globalConfigsUrl = `${API_URL_PLACEHOLDER}/tenants/v1/configuration`;

  constructor(private httpService: HttpClient) {}

  public getGlobalConfig(): Observable<GlobalConfig> {
    return this.httpService.get<GlobalConfig>(this.globalConfigsUrl);
  }

  public updateGlobalConfig(globalConfigUpdate: GlobalConfigUpdateBody): Observable<GlobalConfig> {
    return this.httpService.patch<GlobalConfig>(this.globalConfigsUrl, globalConfigUpdate);
  }
}
