<div class="rb-card card">
  <div class="header">
    @if (hasHeaderImage) {
      <div class="header-image">
        @if (icon && !thumbnailId) {
          @if (!isSvgIcon) {
            <mat-icon>{{ icon }}</mat-icon>
          } @else {
            <mat-icon [svgIcon]="icon" />
          }
        }
        @if (!icon && thumbnailId) {
          <img
            [src]="thumbnailId | fileUrl: thumbnailSource : 'small' : false | async"
            [src-fallback]="thumbnailFallback"
          />
        }
        @if (!icon && !thumbnailId) {
          <img [src]="thumbnailFallback" />
        }
      </div>
    }

    <div class="header-content">
      <div class="header-content-top-row">
        <div class="text">
          <ng-content select="[slot=header-text]"></ng-content>
        </div>
        <div class="icon">
          <ng-content select="[slot=header-icon]"></ng-content>
        </div>
      </div>
      <h3 class="title">
        <ng-content select="[slot=title]"></ng-content>
      </h3>
      <div class="sub-title-content">
        <ng-content select="[slot=sub-title-content]"> </ng-content>
      </div>
    </div>
  </div>

  <div class="top-content no-background" [class.no-main-content]="!hasMainContent">
    <ng-content select="[slot=top-content]"></ng-content>
  </div>

  <div class="main-content" [class.no-meta-data]="!hasMetaData">
    <ng-content select="[slot=content]"></ng-content>
  </div>

  @if (hasMetaData) {
    <div class="meta-data">
      <ng-content select="[slot=meta-data]"></ng-content>
    </div>
  }
</div>
