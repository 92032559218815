<button mat-stroked-button [matMenuTriggerFor]="simulationMenu">
  <mat-icon>{{ currentSimulationModeIcon$ | async }}</mat-icon>
  <span class="btn-label">{{ currentSimulationMode$ | async }}</span>
</button>

<mat-menu #simulationMenu="matMenu" class="" xPosition="before">
  @for (simMode of simulationModes; track simMode) {
    <button
      mat-menu-item
      [disabled]="simMode === (currentSimulationMode$ | async)"
      [class.selected-mode]="simMode === (currentSimulationMode$ | async)"
      (click)="onSelectMode(simMode)"
    >
      {{ simMode }}
    </button>
  }
</mat-menu>
