@if ((isServerPickerDisplayed$ | async) && availableServers?.length) {
  <div class="server-picker-wrapper flex-row justify-end">
    <button class="server-button" mat-stroked-button [matMenuTriggerFor]="serverMenu">
      <mat-icon>cloud</mat-icon>
      <span class="button-text">{{ currentServerName }}</span>
    </button>

    <mat-menu #serverMenu="matMenu" class="" xPosition="before">
      @for (serverOpt of availableServers; track serverOpt) {
        <button
          mat-menu-item
          [disabled]="serverOpt === currentServerName && currentServerName !== 'preview'"
          [ngClass]="{ 'selected-server': serverOpt === currentServerName }"
          (click)="onSelectServer(serverOpt)"
        >
          {{ serverOpt }}
        </button>
      }
    </mat-menu>
  </div>
}
