@if (browserInfoText) {
  <div class="banner">
    {{ (layout.isSmallView | async) ? browserInfoTextSmallView : browserInfoText }}
    <mat-icon class="close-banner-icon" (click)="closeBrowserInfo()">close</mat-icon>
  </div>
}

<!-- Workaround as mat-chip does not load mat-chip styles automatically -->
<!-- see: https://github.com/angular/components/issues/9964 -->
<mat-chip-listbox class="empty-chip-listbox"></mat-chip-listbox>

<router-outlet></router-outlet>
