<mat-form-field
  appearance="outline"
  floatLabel="always"
  class="my-mat-form-field phone-number-input-field"
  [class.my-mat-form-field-full-width]="fullWidth"
  [class.mat-form-field-invalid]="errorMessage"
>
  <mat-label>{{ label }}</mat-label>

  <input
    [type]="(isAndroidOrIos$ | async) ? 'tel' : 'text'"
    matInput
    [disabled]="numberControl.disabled"
    [required]="required"
    [minlength]="minLength ?? null"
    [maxlength]="maxLength ?? null"
    [pattern]="phoneNumberRegex"
    [formControl]="numberControl"
    [errorStateMatcher]="errorStateMatcher"
    data-test-id="phone-number-input"
  />

  @if (countryCodeControl.value) {
    <span
      matPrefix
      class="country-code-prefix"
      [class.disabled-color]="numberControl.disabled"
      data-test-id="phone-number-country-code-span"
      >+{{ countryCodeControl.value[2] }}</span
    >
  }

  <!-- Country button -->
  <button
    matSuffix
    mat-button
    class="country-code"
    [disabled]="numberControl.disabled"
    (click)="countryCodeSelect.open()"
    data-test-id="phone-number-country-code-button"
  >
    <div class="flex-row country-code-button" [class.disabled-color]="numberControl.disabled">
      @if (countryCodeControl.value) {
        <span>{{ countryCodeControl.value[1] | uppercase }}</span>
      } @else {
        <mat-icon>language</mat-icon>
      }

      <mat-icon class="country-code-icon" [class.disabled-color]="numberControl.disabled">
        arrow_drop_down
      </mat-icon>
    </div>
  </button>

  <!-- Errors -->
  @if (errorMessage) {
    <mat-error [@transitionMessages]="animationState">
      {{ errorMessage }}
    </mat-error>
  }
</mat-form-field>

<div class="select">
  <mat-select
    class="mat-mdc-option-force-primary"
    #countryCodeSelect="matSelect"
    disableOptionCentering
    [compareWith]="compareWith"
    [value]="countryCodeControl.value"
    (selectionChange)="countryCodeSelected($event)"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="translations.search"
        [noEntriesFoundLabel]="translations.noEntriesFound"
        [disableInitialFocus]="false"
        ngModel
        (ngModelChange)="filterCountryCodeOptions($event)"
      />
    </mat-option>
    @for (countryCode of filteredCountryCodeOptions; track countryCode[1]) {
      <mat-option [value]="countryCode" attr.data-test-id="option-key-{{ countryCode[2] }}">
        <div class="country-code-option">
          <span class="text-ellipsis"
            >{{ countryCode[0] }} ({{ countryCode[1].toUpperCase() }})</span
          >
          <span class="country-code-option-number">+{{ countryCode[2] }}</span>
        </div>
      </mat-option>
    }
  </mat-select>
</div>
