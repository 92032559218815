import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IsoLanguageCodesEnum, MultiLanguageText } from '@remberg/global/common/core';
import { MaterialModule } from '../../../material.module';
import { TranslateIsoLanguageCodesEnumPipe } from '../../../pipes/translate-iso-language-codes-enum.pipe';
import {
  MAP_ISO_LANGUAGE_TO_FLAG_SVG,
  MultiLanguageEditData,
  MultiLanguageEditDataForm,
} from './multi-language-edit-dialog.definitions';

@Component({
  selector: 'app-multi-language-edit-dialog',
  templateUrl: './multi-language-edit-dialog.component.html',
  styleUrls: ['./multi-language-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MaterialModule, TranslateIsoLanguageCodesEnumPipe],
})
export class MultiLanguageEditDialogComponent implements OnInit {
  protected readonly form = this.createFormGroup();

  protected filteredLanguages: IsoLanguageCodesEnum[] = [];
  protected readonly MAP_ISO_LANGUAGE_TO_FLAG_SVG = MAP_ISO_LANGUAGE_TO_FLAG_SVG;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<
      MultiLanguageEditDialogComponent,
      { values: MultiLanguageText }
    >,
    @Inject(MAT_DIALOG_DATA) public data: MultiLanguageEditData,
  ) {}

  public ngOnInit(): void {
    this.filteredLanguages = this.data.availableLanguages.filter(
      (l) => l !== this.data.currentLanguage,
    );
    this.form.patchValue({
      ...this.data.values,
    });
  }

  protected handleConfirmClick(): void {
    if (!this.form?.valid) {
      return;
    }

    const rawValue: MultiLanguageText = this.form.getRawValue();

    this.dialogRef.close({ values: rawValue });
  }

  private createFormGroup(): FormGroup<MultiLanguageEditDataForm> {
    return this.fb.nonNullable.group<MultiLanguageEditDataForm>(
      Object.entries(IsoLanguageCodesEnum).reduce((result, [, languageCode]) => {
        result[languageCode] = new FormControl<string>('', {
          nonNullable: true,
          validators: languageCode === this.data.currentLanguage ? [Validators.required] : [],
        });
        return result;
      }, {} as MultiLanguageEditDataForm),
    );
  }
}
