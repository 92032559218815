export enum MaintenancePlanTableColumnNameEnum {
  STATUS = 'status',
  COUNTER = 'counter',
  TITLE = 'title',
  ASSIGNED_ORGANIZATION = 'assignedOrganization',
  NUMBER_OF_ASSETS = 'numberOfAssets',
  REPEAT_EVERY = 'repeatEvery',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  GENERATION_PERIOD = 'generationPeriod',
  CREATED = 'created',
  UPDATED = 'updated',
  IS_TERMINATED = 'isTerminated',
  ASSETS = 'assets',
}
