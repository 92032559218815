/*
 * Convert bytes into largest possible unit unless a preferred unit is specified.
 * Also takes an optional precision argument that defaults to 2.
 *
 * Usage:
 *   bytes | fileSize: (unit or precision) : precision
 * Examples:
 *   {{ 1024 | fileSize }}
 *   formats to: 1.00 KB
 *
 *   {{ 524288 | fileSize: 'MB' }}
 *   formats to: 0.50 MB
 *
 *   {{ 524288 | fileSize: 'MB' : 1 }}
 *   formats to: 0.5 MB
 */

const FILE_SIZE_UNITS_SORTED = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'] as const;

export type FileSizeUnit = (typeof FILE_SIZE_UNITS_SORTED)[number];

const BYTES_IN_KB = 1024; // Math.pow(2, 10)

const WEIGHTS = FILE_SIZE_UNITS_SORTED.reduce(
  (all, unit, idx) => ({
    ...all,
    [unit]: Math.pow(BYTES_IN_KB, idx),
  }),
  {} as Record<FileSizeUnit, number>,
);

const DEFAULT_PRECISION = 2;

export function formatFileSize(
  bytes: number = 0,
  unitOrPrecision?: number | FileSizeUnit,
  precision?: number,
): string {
  if (isNaN(+bytes) || !isFinite(bytes)) {
    return '?';
  }

  const targetUnit =
    (typeof unitOrPrecision !== 'number' ? unitOrPrecision : undefined) ?? guessUnit(bytes);
  const targetPrecision =
    (typeof unitOrPrecision === 'number' ? unitOrPrecision : precision) ?? DEFAULT_PRECISION;

  return format(bytes, targetUnit, targetPrecision);
}

function format(bytes: number, unit: FileSizeUnit, precision: number): string {
  return `${(bytes / WEIGHTS[unit]).toFixed(precision)} ${unit}`;
}

function guessUnit(bytes: number): FileSizeUnit {
  for (const unit of FILE_SIZE_UNITS_SORTED) {
    if (bytes / WEIGHTS[unit] < BYTES_IN_KB) {
      return unit;
    }
  }

  // max unit of measure
  return 'PB';
}
