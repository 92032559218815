import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './components';
import { MaterialModule } from './material.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  imports: [
    // Angular
    AsyncPipe,
    MaterialModule,
    NgTemplateOutlet,
    FormsModule,
  ],
  declarations: [ConfirmationDialogComponent, SafeHtmlPipe],
  exports: [ConfirmationDialogComponent, SafeHtmlPipe],
  providers: [],
})
export class GlobalUIModule {}
