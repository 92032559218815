import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { FormInstanceTableColumnEnum } from '../offline/form-instance-offline-service.definitions';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';
import { addColumnIfMissing } from './columnHelpers';

export async function migrateToV30(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V30...');

  await addFormInstanceCreationTypeAndCreatedAtColumns(db, logger);

  await db.executeSql('PRAGMA user_version = 30;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V30.');
}

async function addFormInstanceCreationTypeAndCreatedAtColumns(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  try {
    const table = SyncDataTypesEnum.FORMINSTANCES;

    const tableExistsQuery = await db.executeSql(
      `SELECT name FROM sqlite_master WHERE type='table' AND name='${table}';`,
      [],
    );

    if (!tableExistsQuery.rows?.length) {
      logger.debug()(`${table} table does not exist. Skipping migration.`);
      return;
    }

    logger.debug()(`FOUND ${table} table`);

    await addColumnIfMissing(db, logger, table, FormInstanceTableColumnEnum.CREATION_TYPE, 'TEXT');
    await addColumnIfMissing(db, logger, table, FormInstanceTableColumnEnum.CREATED_AT, 'DATETIME');
  } catch (error) {
    logger.error()('Error adding columns!', error);
  }
}
