<div fxLayout="column">
  <div class="confirmation-text-wrapper">
    <ng-template #titleTemplate>
      <!-- Title -->
      @if (title) {
        <h2
          [ngClass]="{
            'text-title-center': title?.position === 'center',
            'text-title-left': title?.position === 'left',
          }"
        >
          {{ title?.text }}
        </h2>
      }
    </ng-template>

    <!-- Simple Spinner -->
    @if (hasAnimation) {
      <div>
        <div
          class="loader-container loader-container-relative"
          [class]="'top-icon ' + 'icon-' + icon?.color"
        >
          <app-loader [color]="icon?.color" [diameter]="60" [strokeWidth]="6"></app-loader>
        </div>
        <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
      </div>
    }

    <!-- Autosaving Spinner -->
    @if (showAutoSavingState) {
      <div class="header-wrapper">
        @if (autoSavingState === AutoSavingStateEnum.SAVING) {
          <div
            class="loader-container loader-container-relative"
            [class]="'top-icon ' + 'icon-' + icon?.color"
          >
            <app-loader [diameter]="60" [strokeWidth]="6"></app-loader>
          </div>
        }

        @if (autoSavingState === AutoSavingStateEnum.DONE) {
          <div>
            <mat-icon [class]="'top-icon ' + 'icon-' + icon?.color"> check </mat-icon>
          </div>
        }

        @if (autoSavingState === AutoSavingStateEnum.SAVING) {
          <h2
            i18n="@@savingChangesTripleDot"
            [ngClass]="{
              'text-title-center': title?.position === 'center',
              'text-title-left': title?.position === 'left',
            }"
          >
            Saving changes ...
          </h2>
        }

        @if (autoSavingState === AutoSavingStateEnum.DONE) {
          <h2
            i18n="@@allChangesSaved"
            [ngClass]="{
              'text-title-center': title?.position === 'center',
              'text-title-left': title?.position === 'left',
            }"
          >
            All changes saved
          </h2>
        }
      </div>
    }

    <!-- Icon + Header -->
    @if (!showAutoSavingState && !hasAnimation && (title || icon)) {
      <div class="header-wrapper">
        <!-- Icon -->
        @if (icon && icon.icon) {
          <div>
            <mat-icon [class]="'top-icon ' + 'icon-' + icon?.color">{{ icon.icon }}</mat-icon>
          </div>
        }
        <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
      </div>
    }

    <!-- Description -->
    @if (description && description.text && description.text.length > 0) {
      <div>
        @for (desc of description?.text; track desc) {
          <p
            class="mat-body-2"
            [ngClass]="{
              'text-description-center': description?.position === 'center',
              'text-description-left': description?.position === 'left',
              'text-description-justify': description?.position === 'justify',
              'text-description-red-color': description?.redColor,
            }"
          >
            {{ desc }}
          </p>
        }
      </div>
    }

    <!-- Progress Bars -->
    @if (progressBarConfig) {
      <div class="progress-bar-item">
        <div style="display: flex; justify-content: space-between; margin-bottom: 5px">
          <p class="progress-bar-text">{{ progressBarConfig.text }}</p>
          <p style="white-space: nowrap" class="progress-bar-text">
            {{ (progressBarConfig.progress | number: '1.0-0') + ' %' }}
          </p>
        </div>
        <mat-progress-bar
          matLine
          mode="determinate"
          [value]="progressBarConfig.progress"
        ></mat-progress-bar>
      </div>
    }

    <!-- Show again -->
    @if (showDoNotAskAgain) {
      <div
        [ngClass]="{ 'show-not-again-center': showDoNotAskAgainTextCentered }"
        [ngClass]="{
          'text-description-center': description?.position === 'center',
          'text-description-left': description?.position === 'left',
          'text-description-justify': description?.position === 'justify',
        }"
      >
        <mat-checkbox
          [(ngModel)]="checked"
          i18n="@@doNotShowThisMessageAgain"
          class="mat-checkbox-top-vertical-align"
        >
          Do not show this message again.
        </mat-checkbox>
      </div>
    }
  </div>

  <!-- Buttons (distinguish horizontal and vertical) -->
  <div
    [fxLayout]="buttonsDirection === 'horizontal' ? 'row wrap' : 'column wrap'"
    [ngStyle]="buttonsDirection === 'horizontal' ? { 'justify-content': 'space-between' } : {}"
  >
    @for (button of buttons; track button) {
      @if (
        !progressBarConfig ||
        (!progressBarCompleted && button.category !== 'success') ||
        (progressBarCompleted && button.category === 'success')
      ) {
        <button
          mat-raised-button
          [ngStyle]="buttonsDirection === 'vertical' ? { 'margin-top': '10px' } : {}"
          [color]="button?.color"
          (click)="buttonPressed(button)"
          attr.data-test-id="{{ button.dataTestId }}"
        >
          <ng-container>{{ button.text }}</ng-container>
        </button>
      }
    }
  </div>
</div>
