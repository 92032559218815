<div [ngClass]="{ 'time-inline': showInline, 'time-show-in-forms': showInForms }">
  <div class="label" [matTooltipDisabled]="!isTooltipVisible" [matTooltip]="label">
    @if (required) {
      {{ label }}<span class="required">*</span>
    } @else {
      {{ label }}
    }
  </div>
  @if (showInline) {
    <span class="fill-remaining-space"></span>
  }
  @if (value) {
    <div
      class="value"
      [matTooltipDisabled]="!isTooltipVisible"
      [matTooltip]="value | rembergTimeDisplay"
    >
      {{ value | rembergTimeDisplay }}
    </div>
  } @else {
    <div class="value">-</div>
  }
</div>
