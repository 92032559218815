import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Address } from '@remberg/global/common/core';

const GOOGLE_ESTABLISHMENT_TYPE = 'establishment';

@Directive({
  selector: '[rbGoogleAddress]',
  standalone: true,
})
export class GoogleAddressDirective implements OnInit {
  @Output() public onSelect = new EventEmitter<Address>();

  private element: HTMLInputElement;

  constructor(private readonly elRef: ElementRef) {
    this.element = this.elRef.nativeElement;
  }

  public ngOnInit(): void {
    if (google) {
      const autocomplete = new google.maps.places.Autocomplete(this.element);

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();

        const address = processPlaceToAddress(place);

        // set company
        if (place.types?.includes(GOOGLE_ESTABLISHMENT_TYPE)) {
          address.company = place.name;
        }

        // set coordinates
        address.latitude = place.geometry?.location?.lat();
        address.longitude = place.geometry?.location?.lng();

        this.onSelect.emit(address);
      });
    }
  }
}

export function processPlaceToAddress(place: google.maps.places.PlaceResult): Address {
  const address: Address = {};
  // extract street
  place.address_components?.filter((component) => {
    if (component.types.includes('route')) {
      address.street = component.long_name;
      return true;
    } else {
      return false;
    }
  });
  // extract street number
  place.address_components?.filter((component) => {
    if (component.types.includes('street_number')) {
      address.streetNumber = component.long_name;
      return true;
    } else {
      return false;
    }
  });
  // extract city
  place.address_components?.filter((component) => {
    if (component.types.includes('locality')) {
      address.city = component.long_name;
      return true;
    } else {
      return false;
    }
  });
  // extract zip post code
  place.address_components?.filter((component) => {
    if (component.types.includes('postal_code')) {
      address.zipPostCode = component.long_name;
      return true;
    } else {
      return false;
    }
  });
  // extract countryProvince
  place.address_components?.filter((component) => {
    if (component.types.includes('administrative_area_level_1')) {
      address.countryProvince = component.long_name;
      return true;
    } else {
      return false;
    }
  });
  // extract country
  place.address_components?.filter((component) => {
    if (component.types.includes('country')) {
      address.country = component.long_name;
      return true;
    } else {
      return false;
    }
  });

  return address;
}
