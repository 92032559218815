import { Pipe, PipeTransform } from '@angular/core';
import { UnknownOr, isKnown } from '@remberg/global/common/core';

@Pipe({
  name: 'asKnownObject',
  standalone: true,
})
export class AsKnownObjectPipe implements PipeTransform {
  public transform<T extends { _id: string }>(value?: UnknownOr<T>): T | undefined {
    return isKnown(value) ? value : undefined;
  }
}
