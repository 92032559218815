/** Allowed field to perform sorting*/

export enum MaintenancePlanSortFieldEnum {
  COUNTER = 'counter',
  TITLE = 'title',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  CREATED = 'created',
  UPDATED = 'updated',
}
