import { Inject, Injectable } from '@angular/core';
import { Intercom as IntercomIonic } from '@capacitor-community/intercom';
import { Store } from '@ngrx/store';
import {
  LanguageCodeEnum,
  getShortLanguageCode,
  isRembergSupportEmail,
} from '@remberg/global/common/core';
import {
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  INTERCOM_CONFIG,
  LocalStorageKeys,
  LogService,
  environment,
  getStringID,
} from '@remberg/global/ui';
import { IntercomHashes, UserRole } from '@remberg/users/common/main';
import { Intercom as IntercomWeb } from '@supy-io/ngx-intercom';
import { firstValueFrom } from 'rxjs';
import { RootGlobalState } from '../store/core-ui.definitions';
import { GlobalActions } from '../store/global/global.actions';
import { GlobalSelectors } from '../store/global/global.selectors';
import { IntercomApiService } from './api/intercom-api.service';
import { AppStateService } from './app-state.service';
import { DeviceInfoService } from './device-info.service';
import { LayoutService } from './layout.service';

export interface IntercomSetupOptions {
  intercomHashes?: IntercomHashes;
  userRole?: UserRole;
  firstName?: string;
  lastName?: string;
  organizationId?: string;
  tenantLabel?: string;
  subdomain?: string;
  prmVersion?: string;
  isSupportEnabled?: boolean;
  email?: string;
  contactId?: string;
  lang?: LanguageCodeEnum;
  isTenantOwner?: boolean;
}

@Injectable({ providedIn: 'root' })
export class IntercomService {
  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly logger: LogService,
    private readonly intercomWeb: IntercomWeb,
    private readonly layout: LayoutService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly appState: AppStateService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly intercomApiService: IntercomApiService,
  ) {}

  public shouldEnableIntercom(options: IntercomSetupOptions): boolean {
    if (!environment.intercomEnabled) {
      this.logger.debug()('[ Intercom ] Intercom is disabled for this environment.');
      return false;
    }

    if (!options.intercomHashes?.web) {
      this.logger.debug()('[ Intercom ] Did not activate intercom - missing intercomHash.');
      return false;
    }

    if (!options.isTenantOwner) {
      this.logger.debug()('[ Intercom ] Did not activate intercom for managed account user.');
      return false;
    }

    // This check should only be done at tenant level, in this case, the previous isOem condition guarantees it
    if (!options.isSupportEnabled) {
      this.logger.debug()('[ Intercom ] Intercom is disabled for this tenant.');
      return false;
    }

    const isSupportUser = isRembergSupportEmail(options.email);
    if (isSupportUser) {
      this.logger.debug()('[ Intercom ] Did not activate intercom for support user.');
      return false;
    }

    const isWhitelistDefined = !!environment.intercomWhitelistEmails.length;
    const isUserPartOfValidWhitelist = (environment.intercomWhitelistEmails as string[]).includes(
      options.email ?? '',
    );
    if (isWhitelistDefined && !isUserPartOfValidWhitelist) {
      this.logger.debug()(
        '[ Intercom ] Did not activate intercom because user email is not included in non empty whitelist.',
      );
      return false;
    }

    return true;
  }

  public async fetchIntercomHashes(): Promise<IntercomHashes> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('[ Intercom ] Loading hashes online.');
      try {
        const intercomHashes = await firstValueFrom(this.intercomApiService.fetchIntercomHashes());
        await this.appState.setValue(
          LocalStorageKeys.IONIC_INTERCOM_HASHES,
          JSON.stringify(intercomHashes),
        );
        return intercomHashes;
      } catch (error) {
        this.logger.error()('[ Intercom ] Failed to load intercomHashes from the backend.', error);
        return {};
      }
    } else {
      this.logger.debug()('[ Intercom ] Offline fallback to load hashes.');
      const rawValue = this.appState.getValue(LocalStorageKeys.IONIC_INTERCOM_HASHES);
      return rawValue ? JSON.parse(rawValue) : {};
    }
  }

  public async showIntercom(): Promise<void> {
    if (this.layout.isIonic) {
      await this.showIntercomMobile();
    } else {
      await this.showIntercomWeb();
    }
  }

  public async showIntercomMobile(): Promise<void> {
    const isEnabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsIntercomMobileInitialized),
    );
    if (isEnabled) {
      IntercomIonic.displayMessenger();
    } else {
      this.logger.debug()('[ Intercom ] Did not show intercom because it was not initialized.');
    }
  }

  public async showIntercomWeb(): Promise<void> {
    const isEnabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsIntercomWebInitialized),
    );
    if (isEnabled) {
      this.intercomWeb.show();
    } else {
      this.logger.debug()('[ Intercom ] Did not show intercom because it was not initialized.');
    }
  }

  public sendIntercomPingWeb(): void {
    this.intercomWeb.update();
  }

  public async shutdownIntercom(): Promise<void> {
    if (this.layout.isIonic && !this.layout.isSimulatedIonic) {
      await IntercomIonic.logout();
      this.logger.debug()('[ Intercom ] intercom shut down after logout');
    } else {
      /* this.intercom.update({
              hide_default_launcher: true
            }); */
      this.intercomWeb.shutdown();
    }
  }

  public async setupIntercom(options: IntercomSetupOptions): Promise<void> {
    if (!this.shouldEnableIntercom(options)) {
      return;
    }

    this.logger.debug()('[ Intercom ] Starting intercom in language: ' + options.lang);

    if (this.layout.isIonic && !this.layout.isSimulatedIonic) {
      await this.setupIntercomMobile(options);
    } else {
      this.setupIntercomWeb(options);
    }
  }

  private async setupIntercomMobile(options: IntercomSetupOptions): Promise<void> {
    this.logger.silly()('[ Intercom ] Getting device id...');
    const deviceId = await this.deviceInfoService.getDeviceIdAsync();

    this.logger.debug()('[ Intercom ] Starting ionic intercom plugin...');
    IntercomIonic.registerIdentifiedUser({
      userId: options.contactId,
      email: options?.email,
    });
    if (this.layout.isAndroid) {
      IntercomIonic.setUserHash({ hmac: options?.intercomHashes?.android ?? '' });
    } else if (this.layout.isIos) {
      IntercomIonic.setUserHash({ hmac: options?.intercomHashes?.iOS ?? '' });
    } else {
      IntercomIonic.setUserHash({ hmac: options?.intercomHashes?.web ?? '' });
    }
    // IntercomIonic.setLauncherVisibility(true);
    IntercomIonic.setBottomPadding({ value: '20' });
    // IntercomIonic.setInAppMessageVisibility(true);
    IntercomIonic.updateUser({
      userId: options.contactId,
      email: options?.email,
      customAttributes: {
        account: getStringID(options?.organizationId),
        accountName: options?.tenantLabel,
        domain: options?.subdomain,
        custom_firstName: options?.firstName,
        custom_lastName: options?.lastName,
        custom_system_language: options?.lang,
        custom_userRole: options.userRole?.name,
        custom_canCreateAssets: options.userRole?.permissions.assets.create,
        custom_canCreateCases: options.userRole?.permissions.tickets.create,
        custom_canCreateWorkOrders: options.userRole?.permissions.workOrdersTemporary.create,
        custom_canAddFiles: options.userRole?.permissions.files.upload,
        custom_canAddForms: options.userRole?.permissions.forms.fill,
        custom_canCreateCompany: options.userRole?.permissions.organizations.createExternal,
        custom_canEditCompany: options.userRole?.permissions.organizations.updateExternal,
        custom_canCreateInternalUser: options.userRole?.permissions.users.createOwn,
        custom_canEditInternalUser: options.userRole?.permissions.users.updateOwn,
        custom_canCreateExternalUser: options.userRole?.permissions.users.createExternal,
        custom_canEditExternalUser: options.userRole?.permissions.users.updateExternal,
        deviceId: deviceId,
        company: {
          id: options.organizationId,
          company_id: options.organizationId,
          name: options?.tenantLabel,
          domain: options?.subdomain,
          activeCustomer: options?.isSupportEnabled,
          prmVersion: options?.prmVersion,
        },
      },
    });
    IntercomIonic.hideMessenger();
    IntercomIonic.hideLauncher();
    this.store.dispatch(GlobalActions.intercomMobileInitialized());

    this.logger.debug()('[ Intercom ] Intercom initialized for mobile');
  }

  private setupIntercomWeb(options: IntercomSetupOptions): void {
    this.intercomWeb.boot({
      api_base: INTERCOM_CONFIG.api_base,
      app_id: INTERCOM_CONFIG.app_id,
      user_id: options.contactId,
      user_hash: options?.intercomHashes?.web,
      name: options?.firstName + ' ' + options?.lastName,
      company: {
        id: options.organizationId,
        // company_id: options.organizationId,
        name: options?.tenantLabel ?? '',
        domain: options?.subdomain ?? '',
        activeCustomer: options?.isSupportEnabled,
        prmVersion: options?.prmVersion,
      } as any,
      // We have to update it here as well since the intercom web dependency
      // does not support custom attributes as part of the company object
      email: options?.email,
      language_override: getShortLanguageCode(options.lang),
      hide_default_launcher: true,
      alignment: 'right',
      vertical_padding: 20,
      horizontal_padding: 20,
    });
    this.intercomWeb.update({
      account: options.organizationId,
      companyName: options?.tenantLabel,
      domain: options?.subdomain,
      custom_firstName: options?.firstName,
      custom_lastName: options?.lastName,
      custom_system_language: options?.lang,
      custom_userRole: options.userRole?.name,
      custom_canCreateAssets: options.userRole?.permissions.assets.create,
      custom_canCreateCases: options.userRole?.permissions.tickets.create,
      custom_canCreateWorkOrders: options.userRole?.permissions.workOrdersTemporary.create,
      custom_canAddFiles: options.userRole?.permissions.files.upload,
      custom_canAddForms: options.userRole?.permissions.forms.fill,
      custom_canCreateCompany: options.userRole?.permissions.organizations.createExternal,
      custom_canEditCompany: options.userRole?.permissions.organizations.updateExternal,
      custom_canCreateInternalUser: options.userRole?.permissions.users.createOwn,
      custom_canEditInternalUser: options.userRole?.permissions.users.updateOwn,
      custom_canCreateExternalUser: options.userRole?.permissions.users.createExternal,
      custom_canEditExternalUser: options.userRole?.permissions.users.updateExternal,
    });
    this.store.dispatch(GlobalActions.intercomWebInitialized());
    this.logger.debug()('[ Intercom ] Intercom initialized for web');
  }
}
