import { valuesOf } from '@remberg/global/common/core';
import { TicketActivityType } from '@remberg/tickets/common/main';
import { Ticket2StatusEnum, Ticket2StatusPortalFiltersEnum } from '@remberg/tickets2/common/base';

export const CONVERSATIONS_ACTIVITIES = [
  TicketActivityType.NOTE,
  TicketActivityType.OUTBOUND_EMAIL_MESSAGE,
  TicketActivityType.INBOUND_EMAIL_MESSAGE,
  TicketActivityType.PORTAL_MESSAGE,
  TicketActivityType.CASE_MOVED,
  TicketActivityType.CASE_MOVED_UNDO,
];

export const MESSAGE_ACTIVITIES = [
  TicketActivityType.OUTBOUND_EMAIL_MESSAGE,
  TicketActivityType.INBOUND_EMAIL_MESSAGE,
  TicketActivityType.PORTAL_MESSAGE,
];

export const CONVERSATION_ACTIVITIES_WITHOUT_MOVED_ACTIVITIES = [
  TicketActivityType.NOTE,
  TicketActivityType.OUTBOUND_EMAIL_MESSAGE,
  TicketActivityType.INBOUND_EMAIL_MESSAGE,
  TicketActivityType.PORTAL_MESSAGE,
];

export const COMPLETED_STATUSES = new Set<Ticket2StatusEnum>([
  Ticket2StatusEnum.CLOSED,
  Ticket2StatusEnum.SOLVED,
  Ticket2StatusEnum.MOVED,
]);

export const BEING_PROCESSED_STATUSES = new Set<Ticket2StatusEnum>([
  Ticket2StatusEnum.NEW,
  Ticket2StatusEnum.OPEN,
  Ticket2StatusEnum.PENDING_INTERNAL,
]);

export const CLOSED_PORTAL_STATUSES = new Set<Ticket2StatusEnum>([
  Ticket2StatusEnum.SOLVED,
  Ticket2StatusEnum.CLOSED,
]);

export const STATUS_TO_PORTAL_STATUS: Record<Ticket2StatusEnum, Ticket2StatusPortalFiltersEnum> = {
  [Ticket2StatusEnum.NEW]: Ticket2StatusPortalFiltersEnum.BEING_PROCESSED,
  [Ticket2StatusEnum.OPEN]: Ticket2StatusPortalFiltersEnum.BEING_PROCESSED,
  [Ticket2StatusEnum.PENDING_INTERNAL]: Ticket2StatusPortalFiltersEnum.BEING_PROCESSED,
  [Ticket2StatusEnum.PENDING_EXTERNAL]: Ticket2StatusPortalFiltersEnum.PENDING_EXTERNAL,
  [Ticket2StatusEnum.SOLVED]: Ticket2StatusPortalFiltersEnum.CLOSED_PORTAL,
  [Ticket2StatusEnum.CLOSED]: Ticket2StatusPortalFiltersEnum.CLOSED_PORTAL,
  [Ticket2StatusEnum.MOVED]: Ticket2StatusPortalFiltersEnum.MOVED,
};

export const STATUSES_EXCLUDED_FROM_RESOLUTION_TIME = new Set<Ticket2StatusEnum>([
  ...COMPLETED_STATUSES,
  Ticket2StatusEnum.PENDING_EXTERNAL,
]);

export const STATUSES_INCLUDED_IN_TIME_TO_RESOLUTION: Ticket2StatusEnum[] = valuesOf(
  Ticket2StatusEnum,
).filter((status) => !STATUSES_EXCLUDED_FROM_RESOLUTION_TIME.has(status));

export const TICKET_INDEX_SEARCH_LIMIT = 2000;
