<!-- ngClass not updating in selects -->
<mat-chip
  class="status-indicator {{ 'status-' + statusClass }}"
  color="primary"
  disableRipple
  [removable]="false"
  [class.displayflex]="displayFlex"
  [class.xsmall]="size === StatusIndicatorSizeEnum.XSMALL"
  [class.small]="size === StatusIndicatorSizeEnum.SMALL"
  [class.large]="size === StatusIndicatorSizeEnum.LARGE"
  [style.backgroundColor]="
    colorPaletteOption ? (colorPaletteOption | colorOption: 'background') : backgroundColor
  "
  [style.color]="colorPaletteOption ? (colorPaletteOption | colorOption: 'text') : color"
  [style.--mdc-chip-label-text-color]="
    colorPaletteOption ? (colorPaletteOption | colorOption: 'text') : color
  "
  [style.maxWidth]="maxWidth"
  [matTooltip]="tooltipText ?? ''"
  attr.data-test-id="status-indicator-{{ statusClass }}"
>
  @if ((icon?.length ?? 0) > 0) {
    <mat-icon
      matChipAvatar
      [style.color]="colorPaletteOption ? (colorPaletteOption | colorOption: 'text') : color"
    >
      {{ icon }}
    </mat-icon>
  }
  <div class="chip-text" attr.data-test-id="status-indicator-label-{{ label }}">{{ label }}</div>
</mat-chip>
