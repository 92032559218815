<!-- ngClass not updating in selects -->
<mat-chip
  class="priority-indicator {{ 'priority-' + priorityClass }}"
  color="primary"
  disableRipple
  [removable]="false"
  [class.displayflex]="displayFlex && 'flex'"
  [class.large]="size === PriorityIndicatorSizeEnum.LARGE"
  [class.small]="size === PriorityIndicatorSizeEnum.SMALL"
  [class.listpadding]="asList"
  attr.data-test-id="priority-{{ priorityClass }}"
>
  @if (!icon && (colorIndicator || priorityClass)) {
    <div matChipAvatar class="color-indicator" [style.backgroundColor]="colorIndicator">&nbsp;</div>
  }
  @if (icon) {
    <mat-icon
      matChipAvatar
      [style.color]="iconColor"
      [style.--mdc-chip-label-text-color]="textColor"
      [svgIcon]="isSvgIcon ? icon : ''"
      >{{ isSvgIcon ? '' : icon }}</mat-icon
    >
  }
  <div
    [style.color]="textColor"
    [style.--mdc-chip-label-text-color]="textColor"
    class="chip-text"
    [style.font-weight]="fontWeight"
    [attr.data-test-id]="dataTestId + '-priorityLabel'"
  >
    {{ label }}
  </div>
</mat-chip>
