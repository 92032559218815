import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'noComma',
  standalone: true,
})
export class NoCommaPipe implements PipeTransform {
  transform(val: number | string | null): string {
    if (val !== undefined && val !== null) {
      // here we just remove the commas from value
      return val.toString().split(',').join('').split('.').join('');
    } else {
      return '';
    }
  }
}
