import { Pipe, PipeTransform } from '@angular/core';
import { UnknownObject, isUnknownObject } from '@remberg/global/common/core';

@Pipe({
  name: 'isUnknownObject',
  standalone: true,
})
export class IsUnknownObjectPipe implements PipeTransform {
  public transform(value: unknown): value is UnknownObject {
    return isUnknownObject(value);
  }
}
