import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { FormTemplate } from '@remberg/forms/common/main';
import { IsoLanguageCodesEnum, as } from '@remberg/global/common/core';
import { DynamicProgressBarConfig, LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import {
  FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX,
  FormTemplateTableColumnName,
} from '../offline/form-template-offline-service.definitions';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';

export async function migrateToV20(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V20...');

  await addMissingColumns(db, logger);
  await insertColumnValues(db, logger);

  await db.executeSql('PRAGMA user_version = 20;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V20.');
  return;
}

async function addMissingColumns(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  try {
    const tableExistsQuery = await db.executeSql(
      `SELECT name FROM sqlite_master WHERE type='table' AND name='${SyncDataTypesEnum.FORMTEMPLATES}';`,
      [],
    );

    if (tableExistsQuery.rows?.item(0)?.name === SyncDataTypesEnum.FORMTEMPLATES) {
      logger.debug()(`FOUND ${SyncDataTypesEnum.FORMTEMPLATES} table`);
      // check if column already exists
      const tableColumns = await db.executeSql(
        `PRAGMA table_info(${SyncDataTypesEnum.FORMTEMPLATES});`,
        [],
      );

      logger.debug()(`Retrieved ${SyncDataTypesEnum.FORMTEMPLATES} table column names`);

      const addColumnIfMissing = async (columnName: FormTemplateTableColumnName): Promise<void> => {
        let columnExists: boolean | undefined;
        for (let i = 0; i < tableColumns.rows.length; i++) {
          if (tableColumns.rows.item(i).name === columnName) {
            columnExists = true;
          }
        }
        if (!columnExists) {
          try {
            await db.executeSql(
              `ALTER TABLE ${SyncDataTypesEnum.FORMTEMPLATES} ADD COLUMN ${columnName} TEXT;`,
              [],
            );
            logger.debug()(
              `Added ${columnName} column to ${SyncDataTypesEnum.FORMTEMPLATES} table`,
            );
          } catch (err) {
            logger.error()(
              `Error during adding ${SyncDataTypesEnum.FORMTEMPLATES} table ${columnName} column.`,
              err,
            );
          }
        }
      };

      for (const column of Object.values(IsoLanguageCodesEnum)) {
        addColumnIfMissing(`${FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${column}`);
      }
    } else {
      logger.debug()(
        `${SyncDataTypesEnum.FORMTEMPLATES} table does not exist. Skipping migration.`,
      );
    }
  } catch (error) {
    logger.error()(`Error during ${SyncDataTypesEnum.FORMTEMPLATES} table existence check`, error);
  }
}

async function insertColumnValues(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  try {
    const results = await db.executeSql(
      `SELECT name FROM sqlite_master WHERE type='table' AND name='${SyncDataTypesEnum.FORMTEMPLATES}';`,
      [],
    );

    if (results.rows?.item(0)?.name === SyncDataTypesEnum.FORMTEMPLATES) {
      // count how many formTemplates need to be processed
      const numFormTemplates =
        (
          await db.executeSql(
            `SELECT COUNT(_id) FROM ${SyncDataTypesEnum.FORMTEMPLATES} WHERE ${FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${IsoLanguageCodesEnum.DE} IS NULL`,
            [],
          )
        )?.rows.item(0)?.['COUNT(_id)'] ?? 0;
      logger.debug()('#of formTemplates to migrate: ' + numFormTemplates);

      // fetch unprocessed formTemplates and migrate in batches
      if (numFormTemplates > 0) {
        const batchSize = 1000;
        const numBatches = Math.ceil(numFormTemplates / batchSize);

        for (let i = 0; i < numBatches; i++) {
          try {
            const formTemplates = await db.executeSql(
              `SELECT * FROM ${SyncDataTypesEnum.FORMTEMPLATES} WHERE ${FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${IsoLanguageCodesEnum.DE} IS NULL LIMIT ${batchSize}`,
              [],
            );

            for (let j = 0; j < formTemplates.rows.length; j++) {
              const formTemplate: FormTemplate = JSON.parse(formTemplates.rows.item(j).instance);
              const keys = Object.values(IsoLanguageCodesEnum).map(
                (lang) => `${FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${lang}`,
              ) as FormTemplateTableColumnName[];
              const columnValueDict = Object.values(IsoLanguageCodesEnum).reduce(
                (column, locale) => ({
                  ...column,
                  [as<FormTemplateTableColumnName>(
                    `${FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${locale}`,
                  )]: (val: FormTemplate) => val.name[locale]?.trim() || '',
                }),
                {} as {
                  [key in FormTemplateTableColumnName]: (val: FormTemplate) => string;
                },
              );
              const query = `UPDATE ${SyncDataTypesEnum.FORMTEMPLATES} SET ${keys
                .map((key) => key + ' = ?')
                .join(', ')} WHERE _id = ?`;
              const tuple = [
                ...keys.map((key) => columnValueDict[key](formTemplate)),
                formTemplate._id,
              ];
              await db.executeSql(query, tuple);
            }
          } catch (error) {
            logger.error()(`Error migrating ${SyncDataTypesEnum.FORMTEMPLATES} batch: `, error);
            throw error;
          }
        }
      }
    }
  } catch (error) {
    logger.error()(`Error migrating ${SyncDataTypesEnum.FORMTEMPLATES}: `, error);
  }
}
