import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ConnectionStatusEnum,
  ConnectivityServiceInterface,
  LocalStorageKeys,
  LogService,
  SyncStateEnum,
} from '@remberg/global/ui';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  firstValueFrom,
  fromEvent,
  interval,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RootGlobalState } from '../store/core-ui.definitions';
import { GlobalActions } from '../store/global/global.actions';
import { DeviceTypeState } from '../store/global/global.definitions';
import { GlobalSelectors } from '../store/global/global.selectors';
import { AppStateService } from './app-state.service';
import { SqlDBMockService } from './sqlite-mock/sqlDBMock.service';

@Injectable()
export class ConnectivityService implements ConnectivityServiceInterface, OnDestroy {
  public online$: Subject<string>;
  public offline$: Subject<string>;
  public connection$ = new BehaviorSubject<boolean>(true);
  public externalConnection$ = new BehaviorSubject<boolean>(true);

  /** this is a shorthand Observable should be used to show/hide features that are online only */
  public readonly shouldDisplayOnlineUi$: Observable<boolean> = combineLatest([
    this.connection$,
    this.store.select(GlobalSelectors.selectSyncState),
  ]).pipe(
    map(([isOnline, syncState]) => isOnline && syncState != SyncStateEnum.PushingDataToServer),
  );

  public shouldRefreshView$: Subject<boolean> = new Subject<boolean>();

  private browserOnline$: Observable<string>;
  private browserOffline$: Observable<string>;

  private internalStatus: ConnectionStatusEnum = ConnectionStatusEnum.Online;
  private externalStatus: ConnectionStatusEnum = ConnectionStatusEnum.Online;

  private internalConnected: boolean = true;

  private subscriptions: Subscription = new Subscription();

  private deviceType?: DeviceTypeState;

  constructor(
    private logger: LogService,
    private _toastr: ToastrService,
    private _ngZone: NgZone,
    private appState: AppStateService,
    private store: Store<RootGlobalState>,
    private sqlDBMockService: SqlDBMockService,
  ) {
    this.online$ = new Subject<string>();
    this.offline$ = new Subject<string>();

    // TODO - Implement navigator.connection ....

    this.browserOnline$ = fromEvent(window, 'online') as any;

    this.subscriptions.add(
      this.browserOnline$.subscribe(() => {
        this.transitionExternalOnline();
      }),
    );
    this.browserOffline$ = fromEvent(window, 'offline') as any;
    this.subscriptions.add(
      this.browserOffline$.subscribe(() => {
        this.transitionExternalOffline;
      }),
    );
    // cyclically check connection status (if a browser event is missed)
    this.subscriptions.add(
      interval(3000)
        .pipe(map(() => this.checkExternalStatus()))
        .subscribe(),
    );

    // set the external status to the current online status
    this.externalStatus = window.navigator.onLine
      ? ConnectionStatusEnum.Online
      : ConnectionStatusEnum.Offline;
    this.externalConnection$.next(window.navigator.onLine);
  }

  public async initialize(): Promise<void> {
    // We have to wait for the app state to be ready for this to work.
    await this.appState.getReadyState();
    this.deviceType = await firstValueFrom(
      this.store.select(GlobalSelectors.selectDeviceType).pipe(filter(Boolean)),
    );
    // We only track the internal connectivity status when the offline feature is enabled.
    // To prevent data loss we need to check additionally if the last data push was interrupted during an error
    // so in case syncStatus still equals PushingDataToServer, set app to offline
    if (this.offlineCapabilitiesEnabled()) {
      const storedStatus = this.appState.getValue(LocalStorageKeys.INTERNAL_CONNECTIVITY_STATUS);
      const syncStatus = this.appState.getValue(LocalStorageKeys.IONIC_DATA_SYNC_STATUS);

      if (
        ((!storedStatus || storedStatus === ConnectionStatusEnum.Online) &&
          syncStatus !== SyncStateEnum.PushingDataToServer) || // test if the last attempt to go online, was interrupted (app terminated during sync)
        (!!this.deviceType.simulatedIonicType && !this.sqlDBMockService.dbPreloadingEnabled) // always go online when isSimulatedIonic and no db snapshot provided -> db will always be empty
      ) {
        this.goOnline();
      } else {
        this.goOffline();
      }

      // in any case clear the last sync state
      this.store.dispatch(GlobalActions.clearSyncState());
    }
  }

  // check external status and do a transition if necessary
  public checkExternalStatus(): void {
    const newStatus = window.navigator.onLine
      ? ConnectionStatusEnum.Online
      : ConnectionStatusEnum.Offline;
    if (newStatus === ConnectionStatusEnum.Online) {
      this.transitionExternalOnline();
    } else {
      this.transitionExternalOffline();
    }
  }

  // transition to external online if currently offline
  public transitionExternalOnline(): void {
    this._ngZone.run(() => {
      if (this.externalStatus !== ConnectionStatusEnum.Online) {
        this.externalConnection$.next(true);
        this.externalStatus = ConnectionStatusEnum.Online;
        if (this.internalConnected) {
          this.logger.warn()('Internet connection recovered toast...');
          this._toastr.info(
            $localize`:@@internetConnectionRecovered:Internet Connection recovered`,
            $localize`:@@info:Info`,
          );
        }
      }
    });
  }

  // transition to external offline if currently online
  public transitionExternalOffline(): void {
    this._ngZone.run(() => {
      if (this.externalStatus !== ConnectionStatusEnum.Offline) {
        this.externalConnection$.next(false);
        this.externalStatus = ConnectionStatusEnum.Offline;
        if (this.internalConnected) {
          this.logger.warn()('Internet connection lost toast...');
          this._toastr.warning(
            $localize`:@@internetConnectionLost:Internet Connection lost`,
            $localize`:@@warning:Warning`,
          );
        }
      }
    });
  }

  /** Returns the current internal ConnectionStatusEnum, will be either Online or Offline. */
  public getCurrentStatus(): ConnectionStatusEnum {
    return this.internalStatus;
  }

  /** Returns the current external ConnectionStatusEnum, will be either Online or Offline. */
  public getCurrentExternalStatus(): ConnectionStatusEnum {
    return this.externalStatus;
  }

  /** Returns true if we are online and false if we are offline. */
  public getConnected(): boolean {
    return this.internalConnected;
  }

  /** Manually enters the online mode. */
  public async goOnline(): Promise<void> {
    this.online$.next('');
    this.connection$.next(true);
    await this.setOnline();
  }

  /** resets the service (necessary e.g. when logging out) */
  public async reset(): Promise<void> {
    await this.goOnline();
  }

  /** Manually enters the offline mode.
   * This means certain parts of the code will default to the offline data cache. */
  public async goOffline(): Promise<void> {
    this.offline$.next('');
    this.connection$.next(false);
    await this.setOffline();
  }

  /**
   * Refresh the UI view to be up-to-date after changes happen. */
  public refreshView(): void {
    this.shouldRefreshView$.next(true);
  }

  // Helper

  public offlineCapabilitiesEnabled(): boolean {
    return !!this.deviceType?.isIonic;
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  // Private Helper Functions
  private async setOnline(): Promise<void> {
    this.logger.debug()('Connectivity Status set to: Online');
    this.internalStatus = ConnectionStatusEnum.Online;
    this.internalConnected = true;
    this.store.dispatch(
      GlobalActions.setConnectionStatus({ connectionStatus: ConnectionStatusEnum.Online }),
    );
    await this.appState.setValue(
      LocalStorageKeys.INTERNAL_CONNECTIVITY_STATUS,
      this.internalStatus,
    );
  }

  private async setOffline(): Promise<void> {
    this.logger.debug()('Connectivity Status set to: Offline');
    this.internalStatus = ConnectionStatusEnum.Offline;
    this.internalConnected = false;
    this.store.dispatch(
      GlobalActions.setConnectionStatus({ connectionStatus: ConnectionStatusEnum.Offline }),
    );
    await this.appState.setValue(
      LocalStorageKeys.INTERNAL_CONNECTIVITY_STATUS,
      this.internalStatus,
    );
  }
}
