import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureFlagKey, getHasAccess$, RootGlobalState } from '@remberg/ui-core/core';
import { Subscription } from 'rxjs';

/**
 * Structural directive that renders the template or not based on whether feature flag is on/off.
 *
 * Decision is made using current tenant from NGRX Store.
 * If not passed, the element will be rendered.
 * If tenant is missing in store, the element will not be rendered.
 *
 * Can use `; else tmplVar` just like in `ngIf` directive.
 *
 * For checking just permission or both permission and feature flag, use `ifHasAccess` directive.
 *
 * Example with just permission:
 * ```html
 * <p *ifHasFeature="FeatureFlagEnum.AI_COPILOT">content</p>
 * ```
 */
@Directive({ selector: '[ifHasFeature]', standalone: true })
export class IfHasFeatureDirective implements OnChanges, OnDestroy {
  @Input() public ifHasFeature?: FeatureFlagKey;
  @Input() public ifHasFeatureElse?: TemplateRef<unknown>;

  private thenViewRef?: EmbeddedViewRef<unknown>;
  private elseViewRef?: EmbeddedViewRef<unknown>;

  private hasAccessSubscription?: Subscription;

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['ifHasFeature']) {
      this.setupAccess();
    }
  }

  public ngOnDestroy(): void {
    this.hasAccessSubscription?.unsubscribe();
  }

  private setupAccess(): void {
    const featureFlag = this.ifHasFeature;

    if (this.hasAccessSubscription) {
      this.hasAccessSubscription.unsubscribe();
    }

    this.hasAccessSubscription = getHasAccess$(this.store, undefined, featureFlag).subscribe(
      (hasAccess) => this.updateView(hasAccess),
    );
  }

  private updateView(hasAccess: boolean): void {
    if (hasAccess) {
      if (!this.thenViewRef) {
        this.viewContainerRef.clear();
        this.elseViewRef = undefined;
        this.thenViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainerRef.clear();
        this.thenViewRef = undefined;
        if (this.ifHasFeatureElse) {
          this.elseViewRef = this.viewContainerRef.createEmbeddedView(this.ifHasFeatureElse);
        }
      }
    }
  }
}
