<mat-form-field appearance="outline" class="my-mat-form-field my-mat-form-field-outline">
  <mat-label>
    <span>{{ label }}</span>
    @if (currentLanguage) {
      <span class="user-language"> ({{ currentLanguage | translateIsoLanguageCodesEnum }}) </span>
    }
  </mat-label>
  <input
    matInput
    type="text"
    (focusout)="focusOut.next()"
    [formControl]="formGroup.controls.singleLanguageLabel"
    [attr.data-test-id]="dataTestId"
  />
  @if (formGroup.controls.singleLanguageLabel?.errors?.['required']) {
    <mat-error i18n="@@fieldIsRequiredDot"> Field is required. </mat-error>
  }
</mat-form-field>

@if (!hideTranslationsButton) {
  <button
    mat-icon-button
    class="translate-button"
    data-test-id="multi-language-translations-button"
    [matTooltip]="translations.editTranslations"
    [disabled]="formGroup.disabled"
    (click)="openTranslationsDialog()"
  >
    <mat-icon>translate</mat-icon>
  </button>
}
