import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BreadcrumbLink, LogService } from '@remberg/global/ui';
import { RootGlobalState } from '../store/core-ui.definitions';
import { GlobalActions } from '../store/global/global.actions';
import { LayoutService } from './layout.service';

/**
 * @deprecated should use NgRx navigational effects instead
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private readonly logger: LogService,
    private readonly router: Router,
    private readonly layout: LayoutService,
    private readonly store: Store<RootGlobalState>,
  ) {}

  /**
   * @deprecated use GlobalActions.breadcrumbsUpdated({ breadcrumbs }) instead
   */
  public setBreadcrumbLinks(breadcrumbs: BreadcrumbLink[]): void {
    this.store.dispatch(GlobalActions.breadcrumbsUpdated({ breadcrumbs }));
  }

  /**
   * @deprecated use GlobalActions.breadcrumbsUpdated({ breadcrumbs: [] }) instead
   */
  public resetBreadcrumbLinks(): void {
    this.logger.debug()('Reset breadcrumbs.');
    this.setBreadcrumbLinks([]);
  }

  public async navigateWithNewTabOption(
    event: MouseEvent,
    url: string,
    alwaysOpenInNewTab?: boolean,
  ): Promise<void> {
    // make sure, only either mouseup or click can be fired for one action
    // unfortunately, click cannot capture middle mouse for all browsers
    if (
      (event.type === 'click' && event.button !== 1) ||
      (event.type === 'mouseup' && event.button === 1)
    ) {
      if (
        // left click + key, except for middle mouse
        ((event.type === 'click' &&
          event.button !== 1 &&
          (event.ctrlKey || event.metaKey || event.shiftKey)) ||
          // middle mouse click
          (event.type === 'mouseup' && event.button === 1) ||
          alwaysOpenInNewTab) &&
        // not supported in ionic
        !this.layout.isIonic
      ) {
        window.open(url, '_blank', 'noopener');
      } else {
        await this.router.navigateByUrl(url);
      }
    }
  }
}
