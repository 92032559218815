export enum CreationTypeEnum {
  USER = 'user',
  PORTAL = 'portal',
  API_KEY = 'apiKey',
  SYSTEM = 'system',
  EMAIL = 'email',
  MAINTENANCE_PLAN = 'maintenancePlan',
  MAINTENANCE_PLAN_2 = 'maintenancePlan2',
  PUBLIC = 'public',
}
