import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Store } from '@ngrx/store';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { ProductType } from '@remberg/assets/common/base';
import {
  ASSETS_OFFLINE_SERVICE,
  ASSET_TYPES_OFFLINE_SERVICE,
  AssetTypesOfflineServiceInterface,
  AssetsOfflineServiceInterface,
} from '@remberg/assets/ui/clients';
import { ContactsFindManyBasicResponse } from '@remberg/crm/common/main';
import {
  CONTACTS_OFFLINE_SERVICE,
  ContactsOfflineServiceInterface,
  ORGANIZATIONS_OFFLINE_SERVICE,
  OrganizationOfflineServiceInterface,
} from '@remberg/crm/ui/clients';
import {
  CUSTOM_TAGS_OFFLINE_SERVICE,
  CustomTagsOfflineServiceInterface,
} from '@remberg/custom-tags/ui/clients';
import { FileSourceTypeEnum } from '@remberg/files/common/main';
import {
  FilesystemService,
  PLATFORM_FILES_OFFLINE_SERVICE,
  PlatformFilesOfflineServiceInterface,
  PlatformFilesService,
} from '@remberg/files/ui/clients';
import {
  FormEmailSectionData,
  FormInstanceRaw,
  FormSectionTypesEnum,
} from '@remberg/forms/common/main';
import {
  FORM_INSTANCE_OFFLINE_SERVICE,
  FORM_TEMPLATE_OFFLINE_SERVICE,
  FormInstanceOfflineServiceInterface,
  FormTemplateOfflineServiceInterface,
} from '@remberg/forms/ui/clients';
import { FeatureFlagEnum, assertDefined, isDefined } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  AbortToken,
  ActionEnum,
  ApiResponse,
  BaseModel,
  ChangedIDsPayload,
  ChangedIdsPayload2,
  FILE_SYNC_DIRECTORY,
  IDBPrefetchChunkSizeForms,
  LocalStorageKeys,
  LogService,
  OfflineService,
  PrefetchOnlyDataTypesEnum,
  PrefetchStatus,
  SyncDataTypesEnum,
  batchSizes,
  getStringID,
  timestampLocalStorageKeyMap,
} from '@remberg/global/ui';
import { PARTS_OFFLINE_SERVICE, PartsOfflineServiceInterface } from '@remberg/parts/ui/clients';
import { TenantService } from '@remberg/tenants/ui/clients';
import {
  SERVICE_CASE_OFFLINE_SERVICE,
  ServiceCaseOfflineServiceInterface,
} from '@remberg/tickets/ui/clients';
import {
  RembergUsersService,
  USER_GROUP_OFFLINE_SERVICE,
  UserGroupOfflineServiceInterface,
  UserRoleService,
} from '@remberg/users/ui/clients';
import {
  WORK_ORDER_2_OFFLINE_SERVICE,
  WORK_ORDER_STATUS_2_OFFLINE_SERVICE,
  WORK_ORDER_TYPE_2_OFFLINE_SERVICE,
  WorkOrder2OfflineServiceInterface,
  WorkOrderStatus2OfflineServiceInterface,
  WorkOrderType2OfflineServiceInterface,
} from '@remberg/work-orders/ui/clients';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { isAccountFeatureFlagEnabled } from '../../helpers/checkFeatureHelper';
import { RootGlobalState } from '../../store/core-ui.definitions';
import { GlobalActions } from '../../store/global/global.actions';
import { GlobalSelectors } from '../../store/global/global.selectors';
import { AppStateService } from '../app-state.service';
import { EmailStatusOfflineService } from './emailstatus.offline.service';
import { FormTemplateVersionOfflineService } from './formTemplateVersion.offline.service';

interface PrefetchForParameters {
  status: PrefetchStatus;
  dataType: SyncDataTypesEnum;
  fetchUrl: string;
  fetchParams: object;
  abortToken: AbortToken;
  fetchIdKey?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OfflinePrefetchService {
  public readonly apiUrl = API_URL_PLACEHOLDER;
  public prefetchStatus: BehaviorSubject<PrefetchStatus | undefined> = new BehaviorSubject<
    PrefetchStatus | undefined
  >(undefined);
  public prefetching = new BehaviorSubject<boolean>(false);

  private dataTypeToServiceMap: {
    [dataType in SyncDataTypesEnum]: OfflineService<
      BaseModel,
      AdvancedFilterQuery<string>,
      AdvancedFilter<string>
    >;
  };
  private abortToken?: AbortToken;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    private readonly rembergUsersService: RembergUsersService,
    private readonly userRoleService: UserRoleService,
    private readonly tenantService: TenantService,
    private readonly platformFilesService: PlatformFilesService,
    private readonly filesystemService: FilesystemService,
    private readonly appState: AppStateService,
    private readonly store: Store<RootGlobalState>,

    // Offline services
    @Inject(ORGANIZATIONS_OFFLINE_SERVICE)
    private readonly organizationOfflineService: OrganizationOfflineServiceInterface,
    @Inject(CONTACTS_OFFLINE_SERVICE)
    private readonly contactOfflineService: ContactsOfflineServiceInterface,
    @Inject(ASSETS_OFFLINE_SERVICE)
    private readonly assetsOfflineService: AssetsOfflineServiceInterface,
    @Inject(FORM_INSTANCE_OFFLINE_SERVICE)
    private readonly formInstanceOfflineService: FormInstanceOfflineServiceInterface,
    @Inject(FORM_TEMPLATE_OFFLINE_SERVICE)
    private readonly formTemplateOfflineService: FormTemplateOfflineServiceInterface,
    private readonly formTemplateVersionOfflineService: FormTemplateVersionOfflineService,
    private readonly emailStatusOfflineService: EmailStatusOfflineService,
    @Inject(WORK_ORDER_2_OFFLINE_SERVICE)
    private readonly workOrder2OfflineService: WorkOrder2OfflineServiceInterface,
    @Inject(WORK_ORDER_STATUS_2_OFFLINE_SERVICE)
    private readonly workOrderStatus2OfflineService: WorkOrderStatus2OfflineServiceInterface,
    @Inject(WORK_ORDER_TYPE_2_OFFLINE_SERVICE)
    private readonly workOrderType2OfflineService: WorkOrderType2OfflineServiceInterface,
    @Inject(ASSET_TYPES_OFFLINE_SERVICE)
    private readonly assetTypesOfflineService: AssetTypesOfflineServiceInterface,
    @Inject(USER_GROUP_OFFLINE_SERVICE)
    private readonly userGroupOfflineService: UserGroupOfflineServiceInterface,
    @Inject(SERVICE_CASE_OFFLINE_SERVICE)
    private readonly serviceCaseOfflineService: ServiceCaseOfflineServiceInterface,
    @Inject(PLATFORM_FILES_OFFLINE_SERVICE)
    private readonly platformFilesOfflineService: PlatformFilesOfflineServiceInterface,
    @Inject(PARTS_OFFLINE_SERVICE)
    private readonly partsOfflineService: PartsOfflineServiceInterface,
    @Inject(CUSTOM_TAGS_OFFLINE_SERVICE)
    private readonly customTagsOfflineService: CustomTagsOfflineServiceInterface,
  ) {
    this.dataTypeToServiceMap = {
      contacts: this.contactOfflineService,
      organizations: this.organizationOfflineService,
      assets2: this.assetsOfflineService,
      assetTypes2: this.assetTypesOfflineService,
      files: this.platformFilesOfflineService,
      workOrders2: this.workOrder2OfflineService,
      workOrderTypes2: this.workOrderType2OfflineService,
      workOrderStati2: this.workOrderStatus2OfflineService,
      serviceCases: this.serviceCaseOfflineService,
      userGroups: this.userGroupOfflineService,
      formInstances: this.formInstanceOfflineService,
      formTemplates: this.formTemplateOfflineService,
      formTemplateVersions: this.formTemplateVersionOfflineService,
      emailStatuses: this.emailStatusOfflineService,
      parts: this.partsOfflineService,
      customTags: this.customTagsOfflineService,
    };
  }

  public prefetchIfNeeded(): Promise<boolean> | undefined {
    if (this.prefetchNeededCheck()) {
      this.logger.debug()('Prefetching started since IDB Storage expired.');
      return this.prefetch();
    } else {
      this.logger.debug()('Prefetching skipped since IDB Storage already filled.');
      return undefined;
    }
  }

  /**
   * Only returns true if 1. no other
   * prefetch is currently in progress, and 2. the offline feature is accessible.
   */
  public prefetchNeededCheck(): boolean {
    const inProgressCheck = this.prefetching.getValue();
    return !inProgressCheck;
  }

  public async prefetch(): Promise<boolean> {
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    const isTickets2Enabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectHasFeature(FeatureFlagEnum.TICKETS_TEMPORARY)),
    );
    // only prefetch if the platform is the app
    if (!isIonic) {
      this.logger.debug()(
        'Will not prefetch because the offline feature is only available for native apps.',
      );
      return false;
    } else if (await firstValueFrom(this.store.select(GlobalSelectors.selectIsRembergAdmin))) {
      // this check is necessary to disable offline fetching for remberg admin users
      this.logger.debug()('Will not prefetch because remberg admin.');
      return false;
    } else {
      let success = true;
      this.prefetching.next(true);
      try {
        // setup abortToken
        this.abortToken = new AbortToken();
        // initialize status
        const status: PrefetchStatus = {
          assets2: {},
          assetTypes2: {},
          contacts: {},
          organizations: {},
          workOrders2: {},
          workOrderTypes2: {},
          workOrderStati2: {},
          files: {},
          serviceCases: {},
          userGroups: {},
          icons: {},
          formInstances: {},
          formTemplates: {},
          formTemplateVersions: {},
          emailStatuses: {},
          parts: {},
          customTags: {},
        };
        this.prefetchStatus.next(status);

        await this.prefetchCurrentTenantAndRembergUserAndUserRole();

        if (!isTickets2Enabled) {
          await this.prefetchHelper(
            status,
            SyncDataTypesEnum.SERVICECASES,
            '/tickets',
            {},
            this.abortToken,
          );
        } else {
          skipSteps(status, [SyncDataTypesEnum.SERVICECASES]);
          this.prefetchStatus.next(status);
        }
        await this.prefetchFor({
          status,
          dataType: SyncDataTypesEnum.USERGROUPS,
          fetchUrl: '/usergroups/v1/sync',
          fetchParams: {},
          abortToken: this.abortToken,
        });
        await this.prefetchFor({
          status,
          dataType: SyncDataTypesEnum.FILES,
          fetchUrl: '/files/v2/sync/platform',
          fetchParams: {},
          abortToken: this.abortToken,
        });

        await this.prefetchAssetAndAssetTypesSpecificItems(status);
        await this.prefetchFormsSpecificItems(status);
        await this.prefetchCRMSpecificItems(status);
        await this.prefetchPartsSpecificItems(status);
        await this.prefetchWorkOrders2SpecificItems(status);
        await this.prefetchCustomTagsSpecificItems(status);

        // prefetching icon files needs a non-generic handling because the IDs for downloaded files are determined by
        // the prefetched other data objects and also have to be fetched differently
        await this.prefetchFiles(status, this.abortToken);

        // end the prefetching routine
        this.abortToken.check();
        await this.appState.setValue(LocalStorageKeys.OFFLINE_LAST_UPDATED, String(new Date()));
      } catch (error: any) {
        success = false;
        // do not log errors for aborting the process
        if (!error.aborted) {
          this.logger.error()(error);
        }
      } finally {
        if (!this.abortToken?.check(true)) {
          this.logger.info()('Push Changes was aborted!');
          success = false;
        }
        this.abortToken?.complete();
        this.prefetching.next(false);
      }
      return success;
    }
  }

  public async terminateSync(): Promise<void> {
    await this.abortToken?.abort();
  }

  private async prefetchCurrentTenantAndRembergUserAndUserRole(): Promise<void> {
    const rembergUser = await firstValueFrom(
      this.store.select(GlobalSelectors.selectCurrentRembergUser),
    );
    const tenant = await firstValueFrom(this.store.select(GlobalSelectors.selectTenant));
    this.logger.debug()('Updating current Remberg User');
    if (rembergUser) {
      const newRembergUser = await firstValueFrom(
        this.rembergUsersService.findOne(rembergUser._id),
      );
      this.store.dispatch(GlobalActions.setRembergUser({ rembergUser: newRembergUser }));
    }

    this.logger.debug()('Updating current Tenant');
    if (tenant) {
      const newTenant = await firstValueFrom(this.tenantService.getOne(tenant._id));
      this.store.dispatch(GlobalActions.tenantUpdated({ tenant: newTenant }));
    }

    this.logger.debug()('Updating current User Role');
    if (rembergUser?.userRoleId) {
      const userRole = await firstValueFrom(this.userRoleService.getOne(rembergUser.userRoleId));
      this.store.dispatch(GlobalActions.setUserRole({ userRole }));
    }
  }

  private async prefetchAssetAndAssetTypesSpecificItems(status: PrefetchStatus): Promise<void> {
    assertDefined(this.abortToken, 'Missing abortToken');

    await this.prefetchFor({
      status,
      dataType: SyncDataTypesEnum.ASSETS2,
      fetchUrl: '/assets/v1/sync',
      fetchParams: {},
      abortToken: this.abortToken,
    });

    return await this.prefetchFor({
      status,
      dataType: SyncDataTypesEnum.ASSETTYPES2,
      fetchUrl: '/assets/v1/types/sync',
      fetchParams: {},
      abortToken: this.abortToken,
    });
  }

  private async prefetchFormsSpecificItems(status: PrefetchStatus): Promise<void> {
    assertDefined(this.abortToken, 'Missing abortToken');
    if (isAccountFeatureFlagEnabled(FeatureFlagEnum.FORMS, this.appState)) {
      // prefetch Forms objects
      await this.prefetchFor({
        status,
        dataType: SyncDataTypesEnum.FORMINSTANCES,
        fetchUrl: '/forms/v2/sync/instances',
        fetchParams: {},
        abortToken: this.abortToken,
      });
      await this.prefetchFor({
        status,
        dataType: SyncDataTypesEnum.FORMTEMPLATES,
        fetchUrl: '/forms/v2/sync/templates',
        fetchParams: {},
        abortToken: this.abortToken,
      });
      await this.prefetchFor({
        status,
        dataType: SyncDataTypesEnum.FORMTEMPLATEVERSIONS,
        fetchUrl: '/forms/v2/sync/templates/versions',
        fetchParams: {},
        abortToken: this.abortToken,
      });
      // prefetching formInstance email statuses happens based on referenced email statuses inside the synced formInstances
      await this.prefetchEmailStatuses(status, this.abortToken);
    } else {
      // Skipping Forms specific steps
      skipSteps(status, [
        SyncDataTypesEnum.FORMTEMPLATES,
        SyncDataTypesEnum.FORMTEMPLATEVERSIONS,
        SyncDataTypesEnum.FORMINSTANCES,
        SyncDataTypesEnum.EMAILSTATUSES,
      ]);
      this.prefetchStatus.next(status);
    }
  }

  private async prefetchWorkOrders2SpecificItems(status: PrefetchStatus): Promise<void> {
    assertDefined(this.abortToken, 'Missing abortToken');
    if (isAccountFeatureFlagEnabled(FeatureFlagEnum.WORK_ORDERS_TEMPORARY, this.appState)) {
      // prefetch Forms objects
      await this.prefetchFor({
        status,
        dataType: SyncDataTypesEnum.WORKORDERS2,
        fetchUrl: '/workorders/v2/sync',
        fetchParams: {},
        abortToken: this.abortToken,
        fetchIdKey: 'workOrderIds',
      });
      await this.prefetchFor({
        status,
        dataType: SyncDataTypesEnum.WORKORDERSTATI2,
        fetchUrl: '/workorders/v2/status/sync',
        fetchParams: {},
        abortToken: this.abortToken,
        fetchIdKey: 'workOrderStatusIds',
      });
      await this.prefetchFor({
        status,
        dataType: SyncDataTypesEnum.WORKORDERTYPES2,
        fetchUrl: '/workorders/v2/types/sync',
        fetchParams: {},
        abortToken: this.abortToken,
        fetchIdKey: 'workOrderTypeIds',
      });
    } else {
      // Skipping Work Order 2 specific steps
      skipSteps(status, [
        SyncDataTypesEnum.WORKORDERS2,
        SyncDataTypesEnum.WORKORDERSTATI2,
        SyncDataTypesEnum.WORKORDERTYPES2,
      ]);
      this.prefetchStatus.next(status);
    }
  }

  private async prefetchCRMSpecificItems(status: PrefetchStatus): Promise<void> {
    assertDefined(this.abortToken, 'Missing abortToken');
    await this.prefetchFor({
      status,
      dataType: SyncDataTypesEnum.CONTACTS,
      fetchUrl: '/contacts/v1/sync',
      fetchParams: {},
      abortToken: this.abortToken,
      fetchIdKey: 'ids',
    });
    await this.prefetchFor({
      status,
      dataType: SyncDataTypesEnum.ORGANIZATIONS,
      fetchUrl: '/organizations/v1/sync',
      fetchParams: {},
      abortToken: this.abortToken,
      fetchIdKey: 'ids',
    });
  }

  private async prefetchPartsSpecificItems(status: PrefetchStatus): Promise<void> {
    assertDefined(this.abortToken, 'Missing abortToken');
    if (isAccountFeatureFlagEnabled(FeatureFlagEnum.PARTS, this.appState)) {
      await this.prefetchFor({
        status,
        dataType: SyncDataTypesEnum.PARTS,
        fetchUrl: '/parts/v1/sync',
        fetchParams: {},
        abortToken: this.abortToken,
      });
    } else {
      // Skipping Parts specific steps
      skipSteps(status, [SyncDataTypesEnum.PARTS]);
      this.prefetchStatus.next(status);
    }
  }

  private async prefetchCustomTagsSpecificItems(status: PrefetchStatus): Promise<void> {
    assertDefined(this.abortToken, 'Missing abortToken');
    const hasFeature = await firstValueFrom(
      this.store.select(GlobalSelectors.selectHasFeature(FeatureFlagEnum.TAGS_TEMPORARY)),
    );
    if (hasFeature) {
      await this.prefetchFor({
        status,
        dataType: SyncDataTypesEnum.CUSTOMTAGS,
        fetchUrl: '/customtags/v1/sync',
        fetchParams: {},
        abortToken: this.abortToken,
      });
    } else {
      // Skipping Custom Tags specific steps
      skipSteps(status, [SyncDataTypesEnum.CUSTOMTAGS]);
      this.prefetchStatus.next(status);
    }
  }

  private async prefetchFiles(status: PrefetchStatus, abortToken: AbortToken): Promise<void> {
    this.logger.debug()('Prefetching dataType: Files');
    status[PrefetchOnlyDataTypesEnum.ICONS].prefetching = true;
    this.prefetchStatus.next(status);
    abortToken.check();

    // determine the list of locally available files from the device
    let locallyAvailableFileIDs: string[] | undefined;
    try {
      locallyAvailableFileIDs = (
        await Filesystem.readdir({
          path: FILE_SYNC_DIRECTORY,
          directory: Directory.Data,
        })
      )?.files.map((item) => item.name);
    } catch (err) {
      this.logger.warn()('could not access file-sync directory');
    }

    // if the directory did not exist, create it now since
    // storing new files further down this method will need it
    abortToken.check();
    if (!locallyAvailableFileIDs) {
      try {
        await Filesystem.mkdir({
          path: FILE_SYNC_DIRECTORY,
          directory: Directory.Data,
        });
      } catch (err) {
        throw { message: 'Error creating file-sync directory', error: err };
      }
      locallyAvailableFileIDs = [];
    }
    this.logger.debug()('Locally available fileIDs: ', locallyAvailableFileIDs);

    // determine all ids of required files from the local data
    const requiredFileIDs: string[] = [];

    // A) own account logo
    const theme = await firstValueFrom(this.store.select(GlobalSelectors.selectTheme));
    if (theme?.logoFileId) {
      requiredFileIDs.push(theme?.logoFileId);
    }
    // B) user profile images
    let contactsWithCount: ContactsFindManyBasicResponse | undefined;
    let sum = 0;
    let page: number = 0;
    do {
      abortToken.check();
      contactsWithCount = await this.contactOfflineService.getManyWithCountBasic({
        limit: 100,
        page,
      });
      sum += contactsWithCount.contacts.length;
      page++;
      for (const contact of contactsWithCount.contacts) {
        if (contact.profilePictureId) {
          requiredFileIDs.push(getStringID(contact.profilePictureId));
        }
      }
    } while (contactsWithCount?.count && +contactsWithCount.count > sum);
    // B) asset type images
    let assetTypes: ApiResponse<ProductType[]>;
    sum = 0;
    page = 0;
    do {
      abortToken.check();
      assetTypes = await this.assetTypesOfflineService.getAssetTypesWithCount({
        limit: 100,
        offset: page,
      });
      sum += assetTypes.data.length;
      page++;
      for (const assetType of assetTypes.data) {
        if (assetType.image) {
          requiredFileIDs.push(getStringID(assetType.image));
        }
      }
    } while (assetTypes.count && +assetTypes.count > sum);
    // C) rich text editor images
    const richTextEditorFiles = await firstValueFrom(
      this.platformFilesService.getFileIds(FileSourceTypeEnum.RICH_TEXT_FILE),
    );
    // contains all rich text files for the current account - enough for WO and Form files
    for (const fileId of richTextEditorFiles) {
      requiredFileIDs.push(getStringID(fileId));
    }

    // D) upload form component files => Not synced due to performance and storage capacity

    status[PrefetchOnlyDataTypesEnum.ICONS].idCheck = 1;
    this.prefetchStatus.next(status);
    abortToken.check();
    this.logger.debug()('Required fileIDs: ', requiredFileIDs);

    // fetch and store online files
    this.logger.debug()('fetching files');
    status[PrefetchOnlyDataTypesEnum.ICONS].add = 0;
    this.prefetchStatus.next(status);
    abortToken.check();

    // remove all locally available fileIDs to determine which files to download
    const filesToDownloadIDs = [
      ...new Set(requiredFileIDs.filter((id) => !locallyAvailableFileIDs?.includes(id))),
    ];
    for (const fileID of filesToDownloadIDs) {
      abortToken.check();
      try {
        this.logger.debug()('downloading file for offline mode: ' + fileID);
        await this.filesystemService.downloadFileToFilesystem(fileID);
      } catch (error) {
        this.logger.error()('Error downloading file ' + fileID, error); // just log error and skip failed file
      }
      status[PrefetchOnlyDataTypesEnum.ICONS].add =
        (status[PrefetchOnlyDataTypesEnum.ICONS]?.add ?? 0) + 1 / filesToDownloadIDs.length;
      this.prefetchStatus.next(status);
    }
    status[PrefetchOnlyDataTypesEnum.ICONS].add = 1;
    this.prefetchStatus.next(status);

    // add updateID elements in batches (nothing todo for now)
    status[PrefetchOnlyDataTypesEnum.ICONS].update = 1;
    this.prefetchStatus.next(status);
    abortToken.check();

    // delete all locally available files that are not in requiredIDs
    this.logger.debug()('deleting files');
    status[PrefetchOnlyDataTypesEnum.ICONS].deleting = 0;
    this.prefetchStatus.next(status);
    const filesToDeleteIDs = locallyAvailableFileIDs.filter((id) => !requiredFileIDs.includes(id));
    this.logger.debug()('fileIDs to delete: ', filesToDeleteIDs);
    for (const fileID of filesToDeleteIDs) {
      abortToken.check();
      try {
        await Filesystem.deleteFile({
          path: FILE_SYNC_DIRECTORY + '/' + fileID,
          directory: Directory.Data,
        });
      } catch (error) {
        throw { message: 'Error deleting unneeded files!', error: error };
      }
    }
    status[PrefetchOnlyDataTypesEnum.ICONS].deleting = 1;
    status[PrefetchOnlyDataTypesEnum.ICONS].prefetching = false;
    this.prefetchStatus.next(status);

    // finalize
    // store lastUpdate timestamp in local storage
    abortToken.check();
    await this.appState.setValue(
      timestampLocalStorageKeyMap[PrefetchOnlyDataTypesEnum.ICONS],
      String(new Date()),
    );
  }

  private async prefetchHelper(
    status: PrefetchStatus,
    dataType: SyncDataTypesEnum,
    fetchUrl: string,
    fetchParams: any,
    abortToken: AbortToken,
  ): Promise<void> {
    this.logger.debug()('Prefetching dataType: ' + dataType);
    // check if there was already a last update
    const body: any = {};
    const timeStamp = this.appState.getValue(timestampLocalStorageKeyMap[dataType]);
    if (timeStamp) {
      body.changedSince = timeStamp;
    }

    // determine all local IDs
    abortToken.check();
    body.currentClientIDs = await this.dataTypeToServiceMap[dataType].getIDs();

    // fetch changed IDs
    status[dataType].prefetching = true;
    this.prefetchStatus.next(status);

    abortToken.check();
    const changedIDs: ChangedIDsPayload = await this.http
      .put<any>(this.apiUrl + '/sync/pull/' + dataType, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(map((res) => res.data))
      .toPromise();

    status[dataType].idCheck = 1;
    this.prefetchStatus.next(status);

    // debug output:
    if (changedIDs.createIDs?.length > 100) {
      this.logger.debug()('CreateIDs: ' + changedIDs.createIDs?.length + '(amount)');
    } else {
      this.logger.debug()('CreateIDs: ', changedIDs.createIDs);
    }
    if (changedIDs.updateIDs?.length > 100) {
      this.logger.debug()('UpdateIDs: ' + changedIDs.updateIDs?.length + '(amount)');
    } else {
      this.logger.debug()('UpdateIDs: ', changedIDs.updateIDs);
    }
    if (changedIDs.deleteIDs?.length > 100) {
      this.logger.debug()('DeleteIDs: ' + changedIDs.deleteIDs?.length + '(amount)');
    } else {
      this.logger.debug()('DeleteIDs: ', changedIDs.deleteIDs);
    }

    // remove deleteIDs
    status[dataType].deleting = 0;
    this.prefetchStatus.next(status);
    abortToken.check();
    // we only delete local instances that are not offline creations
    await this.dataTypeToServiceMap[dataType].bulkDeleteInstances(changedIDs.deleteIDs, true);
    status[dataType].deleting = 1;
    this.prefetchStatus.next(status);

    // add createID elements in batches
    status[dataType].add = 0;
    this.prefetchStatus.next(status);
    abortToken.check();
    await this.batchAddToDBByIDs(
      abortToken,
      status,
      fetchUrl,
      fetchParams,
      dataType,
      changedIDs.createIDs,
      ActionEnum.ADD,
    );
    status[dataType].add = 1;
    this.prefetchStatus.next(status);

    // add updateID elements in batches
    status[dataType].update = 0;
    this.prefetchStatus.next(status);
    abortToken.check();
    await this.batchAddToDBByIDs(
      abortToken,
      status,
      fetchUrl,
      fetchParams,
      dataType,
      changedIDs.updateIDs,
      ActionEnum.UPDATE,
    );
    status[dataType].update = 1;
    status[dataType].prefetching = false;
    this.prefetchStatus.next(status);

    // finalize
    // store lastUpdate timestamp in local storage
    abortToken.check();
    await this.appState.setValue(
      timestampLocalStorageKeyMap[dataType],
      String(changedIDs.timestamp),
    );
  }

  private async prefetchFor({
    status,
    dataType,
    fetchUrl,
    fetchParams,
    abortToken,
    fetchIdKey = 'ids',
  }: PrefetchForParameters): Promise<void> {
    this.logger.debug()('Prefetching dataType: ' + dataType);

    // check if there was already a last update
    const body: Record<string, unknown> = {};
    const timeStamp = this.appState.getValue(timestampLocalStorageKeyMap[dataType]);
    if (timeStamp) {
      body['lastSyncTimestamp'] = timeStamp;
    }

    // determine all local IDs
    abortToken.check();
    body['clientIds'] = await this.dataTypeToServiceMap[dataType].getIDs();

    // fetch changed IDs
    status[dataType].prefetching = true;
    this.prefetchStatus.next(status);

    abortToken.check();
    const changedIds: ChangedIdsPayload2 = await this.http
      .put<any>(this.apiUrl + fetchUrl + '/ids', body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .toPromise();

    status[dataType].idCheck = 1;
    this.prefetchStatus.next(status);

    // debug output:
    if (changedIds.createIds?.length > 100) {
      this.logger.debug()('CreateIds: ' + changedIds.createIds?.length + '(amount)');
    } else {
      this.logger.debug()('CreateIds: ', changedIds.createIds);
    }
    if (changedIds.updateIds?.length > 100) {
      this.logger.debug()('UpdateIds: ' + changedIds.updateIds?.length + '(amount)');
    } else {
      this.logger.debug()('UpdateIds: ', changedIds.updateIds);
    }
    if (changedIds.deleteIds?.length > 100) {
      this.logger.debug()('DeleteIds: ' + changedIds.deleteIds?.length + '(amount)');
    } else {
      this.logger.debug()('DeleteIds: ', changedIds.deleteIds);
    }

    // remove deleteIDs
    status[dataType].deleting = 0;
    this.prefetchStatus.next(status);
    abortToken.check();
    // we only delete local instances that are not offline creations
    await this.dataTypeToServiceMap[dataType].bulkDeleteInstances(changedIds.deleteIds, true);
    status[dataType].deleting = 1;
    this.prefetchStatus.next(status);

    // add createID elements in batches
    status[dataType].add = 0;
    this.prefetchStatus.next(status);
    abortToken.check();
    await this.batchAddToDBByIDs(
      abortToken,
      status,
      fetchUrl,
      fetchParams,
      dataType,
      changedIds.createIds,
      ActionEnum.ADD,
      1,
      fetchIdKey,
    );
    status[dataType].add = 1;
    this.prefetchStatus.next(status);

    // add updateID elements in batches
    status[dataType].update = 0;
    this.prefetchStatus.next(status);
    abortToken.check();
    await this.batchAddToDBByIDs(
      abortToken,
      status,
      fetchUrl,
      fetchParams,
      dataType,
      changedIds.updateIds,
      ActionEnum.UPDATE,
      1,
      fetchIdKey,
    );
    status[dataType].update = 1;
    status[dataType].prefetching = false;
    this.prefetchStatus.next(status);

    // finalize
    // store lastUpdate timestamp in local storage
    abortToken.check();
    await this.appState.setValue(
      timestampLocalStorageKeyMap[dataType],
      String(changedIds.timestamp),
    );
  }

  private async batchAddToDBByIDs(
    abortToken: AbortToken,
    status: PrefetchStatus,
    url: string,
    params: any,
    dataType: SyncDataTypesEnum,
    data: any[],
    action: ActionEnum,
    statusMultiplier: number = 1,
    bodyIdsKeyName: string = 'instance_ids',
  ): Promise<void> {
    if (data && data.length > 0) {
      let page = 0;
      const pageSize = batchSizes[dataType];
      let results: any[];
      const totalCount = data.length;
      do {
        // HTTP request to fetch instances from the backend
        const httpParams = Object.entries(params || {}).reduce((result, [key, value]) => {
          if (isDefined(value)) {
            return result.set(key, String(value));
          }
          return result;
        }, new HttpParams());

        abortToken.check();
        const body = { [bodyIdsKeyName]: data.slice(page * pageSize, (page + 1) * pageSize) };
        const headers = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          params: httpParams,
        };
        results = await this.http
          .put<any>(this.apiUrl + url, body, headers)
          .pipe(map((res) => res.data ?? res))
          .toPromise();
        abortToken.check();
        // store instances in the DB
        await this.dataTypeToServiceMap[dataType].bulkAddUpdateInstances(results);
        status[dataType][action] =
          (status[dataType][action] ?? 0) + (results.length / totalCount) * statusMultiplier;
        this.prefetchStatus.next(status);
        page++;
        // repeat until all instances were fetched
      } while (pageSize * page < totalCount);
    }
  }

  private async prefetchEmailStatuses(
    status: PrefetchStatus,
    abortToken: AbortToken,
  ): Promise<void> {
    this.logger.debug()('Prefetching dataType: EmailStatuses');
    status[SyncDataTypesEnum.EMAILSTATUSES].prefetching = true;
    this.prefetchStatus.next(status);
    abortToken.check();

    // determine the list of locally available email statuses from the SQLiteDB
    const locallyAvailableEmailStatusIds: string[] = await this.emailStatusOfflineService.getIDs();
    this.logger.debug()('Locally available emailStatusIds: ', locallyAvailableEmailStatusIds);

    const requiredEmailStatusIds: string[] = [];
    let formInstances: ApiResponse<FormInstanceRaw[]>;
    let sum = 0;
    let page = 0;
    do {
      abortToken.check();
      formInstances = await this.formInstanceOfflineService.getInstancesWithCount(
        IDBPrefetchChunkSizeForms,
        page,
      );
      sum += formInstances.data.length;
      page++;
      // get formTemplateVersionIds
      const formTemplateVersionIds: string[] = formInstances.data.map(
        (instance) => instance.formTemplateVersionId,
      );

      // getFormTemplateVersions
      if (formTemplateVersionIds.length > 0) {
        const formTemplateVersionDict =
          await this.formTemplateVersionOfflineService.getManyByIds(formTemplateVersionIds);

        // get email statuses for each formInstance
        for (const formInstance of formInstances.data) {
          const formTemplateConfig =
            formTemplateVersionDict[formInstance.formTemplateVersionId]?.formTemplateConfig;
          if (formTemplateConfig?.sections?.length) {
            for (let i = 0; i < formTemplateConfig?.sections?.length; i++) {
              const section = formTemplateConfig?.sections[i];
              if (section.type === FormSectionTypesEnum.EMAIL_SECTION) {
                const emailStatusIds = (formInstance.data[i] as FormEmailSectionData)?.emailList
                  ?.map((email) => email.id)
                  .filter(Boolean);
                if (emailStatusIds?.length > 0) {
                  requiredEmailStatusIds.push(...emailStatusIds.filter(isDefined));
                }
              }
            }
          }
        }
      }
    } while (formInstances?.count && +formInstances.count > sum);

    status[SyncDataTypesEnum.EMAILSTATUSES].idCheck = 1;
    this.prefetchStatus.next(status);
    abortToken.check();
    this.logger.debug()('Required emailStatusIds: ', requiredEmailStatusIds);

    // fetch missing email statuses
    this.logger.debug()('fetching missing email statuses');
    status[SyncDataTypesEnum.EMAILSTATUSES].add = 0;
    this.prefetchStatus.next(status);
    abortToken.check();
    const missingEmailStatusIds = [
      ...new Set(
        requiredEmailStatusIds.filter((id) => !locallyAvailableEmailStatusIds.includes(id)),
      ),
    ];

    await this.batchAddToDBByIDs(
      abortToken,
      status,
      '/forms/v2/sync/emailstatuses',
      {},
      SyncDataTypesEnum.EMAILSTATUSES,
      missingEmailStatusIds,
      ActionEnum.ADD,
      1,
      'ids',
    );
    status[SyncDataTypesEnum.EMAILSTATUSES].add = 1;
    this.prefetchStatus.next(status);

    // fetching emailStatusUpdates in batches
    this.logger.debug()('fetching email status updates');
    status[SyncDataTypesEnum.EMAILSTATUSES].update = 0;
    this.prefetchStatus.next(status);
    abortToken.check();
    const emailStatusUpdatesIds = [
      ...new Set(
        requiredEmailStatusIds.filter((id) => locallyAvailableEmailStatusIds.includes(id)),
      ),
    ];
    if (emailStatusUpdatesIds.length > 0) {
      const lastSyncTimestamp = this.appState.getValue(
        timestampLocalStorageKeyMap[SyncDataTypesEnum.EMAILSTATUSES],
      );
      await this.batchAddToDBByIDs(
        abortToken,
        status,
        '/forms/v2/sync/emailstatuses',
        { lastSyncTimestamp },
        SyncDataTypesEnum.EMAILSTATUSES,
        emailStatusUpdatesIds,
        ActionEnum.UPDATE,
        1,
        'ids',
      );
    }
    status[SyncDataTypesEnum.EMAILSTATUSES].update = 1;
    this.prefetchStatus.next(status);
    abortToken.check();

    // delete all locally available files that are not in requiredIDs
    this.logger.debug()('deleting emailStatuses');
    status[SyncDataTypesEnum.EMAILSTATUSES].deleting = 0;
    this.prefetchStatus.next(status);
    const emailStatusDeleteIds = locallyAvailableEmailStatusIds.filter(
      (id) => !requiredEmailStatusIds.includes(id),
    );
    this.logger.debug()('emailStatuses to delete: ', emailStatusDeleteIds);
    if (emailStatusDeleteIds.length > 0) {
      abortToken.check();
      await this.emailStatusOfflineService.bulkDeleteInstances(emailStatusDeleteIds);
    }
    status[SyncDataTypesEnum.EMAILSTATUSES].deleting = 1;
    status[SyncDataTypesEnum.EMAILSTATUSES].prefetching = false;
    this.prefetchStatus.next(status);

    // finalize
    // store lastUpdate timestamp in local storage
    abortToken.check();
    await this.appState.setValue(
      timestampLocalStorageKeyMap[SyncDataTypesEnum.EMAILSTATUSES],
      new Date().toISOString(),
    );
  }
}

function skipSteps(status: PrefetchStatus, steps: SyncDataTypesEnum[]): PrefetchStatus {
  for (const step of steps) {
    status[step].prefetching = false;
    status[step].idCheck = 1;
    status[step].add = 1;
    status[step].deleting = 1;
    status[step].update = 1;
  }

  return status;
}
