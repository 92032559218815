import { TrackingCategoryEnum } from './tracking-event-category.enum';
import { TrackingEventEnum } from './tracking-event.enum';

export enum ApplicationTypeEnum {
  WEBAPP = 'webapp',
  MOBILE = 'mobile',
  UNKNOWN = 'unknown',
}

// These come from ionic getPlatforms + unknown for now
export enum DeviceTypeEnum {
  IOS = 'ios',
  IPAD = 'ipad',
  IPHONE = 'iphone',
  ANDROID = 'android',
  PHABLET = 'phablet',
  TABLET = 'tablet',
  CORDOVA = 'cordova',
  CAPACITOR = 'capacitor',
  ELECTRON = 'electron',
  PWA = 'pwa',
  MOBILE = 'mobile',
  MOBILEWEB = 'mobileweb',
  DESKTOP = 'desktop',
  HYBRID = 'hybrid',
  UNKNOWN = 'unknown',
}

export type TrackingInfoOptions = { category: TrackingCategoryEnum } & Record<
  string,
  string | boolean | number | string[]
>;

export interface TrackingInfo {
  eventName: TrackingEventEnum;
  eventOptions?: TrackingInfoOptions;
  deviceType?: DeviceTypeEnum[];
  browserType?: string;
  deviceId?: string;
  applicationVersion?: string;
  applicationType?: ApplicationTypeEnum;
}

export interface TrackingEventPayload {
  eventName: TrackingEventEnum;
  eventOptions?: TrackingInfoOptions;
}
