import { AssetBasic } from '@remberg/assets/common/base';
import { Address, UnknownOr } from '@remberg/global/common/core';
import { Asset } from '../../assets';
import { AssetStatusEnum } from '../../definitions';

export function isProductCompatibility(
  argument?: string | { _id: string } | UnknownOr<ProductCompatibility>,
): argument is ProductCompatibility {
  return !!argument && !!(argument as ProductCompatibility).productType;
}

/**
 * Maps an asset to a product compatibility object.
 *
 * @param {Asset} asset - The asset to map.
 * @param {string | undefined} customerOrganizationId - The customer organization ID. To be provided as an argument to handle portal users
 * @returns {object} The mapped product compatibility object.
 */
export const mapAssetToProductCompatibility = (
  asset: Asset,
  customerOrganizationId: string | undefined,
): ProductCompatibility => ({
  _id: asset._id,
  tenantId: asset.tenantId,
  serialNumber: asset.serialNumber,
  name: asset.name,
  productTypeName: asset.assetTypeLabel,
  customerOrganization: customerOrganizationId ?? asset.relatedOrganizationIds[0],
  location: asset.location,
  productType: {
    _id: asset.assetTypeId,
    label: asset.assetTypeLabel,
    image: asset.assetTypeImageId,
  },
  status: asset.status,
  userGroups: asset.userGroupIds,
  imageId: asset.imageId,
});

/**
 * @deprecated Use non-compatibility interfaces instead
 * This interface is only used in the frontend and represents the object that is returned from the old backend.
 * */
export interface ProductCompatibility {
  _id: string;
  tenantId: string;
  serialNumber: string;
  name?: string;
  productType: { _id: string; label: string; image?: string };
  productTypeName: string;
  manufacturerOrganization?: string;
  customerOrganization?: string;
  location?: Address;
  status?: AssetStatusEnum;
  userGroups?: string[];
  imageId?: string;
}

export function mapProductCompatibilityToAssetBasic(product: ProductCompatibility): AssetBasic {
  return {
    _id: product._id,
    name: product.name,
    serialNumber: product.serialNumber,
    assetTypeId: product.productType._id,
    assetTypeName: product.productType.label,
    assetTypeImageId: product.productType.image,
    imageId: product.imageId,
    customerId: product.customerOrganization,
  };
}
