import { Directive, HostBinding } from '@angular/core';

// inspired from https://dev.to/azrizhaziq/secure-your-apps-with-angular-directive-for-target-blank-fi9

@Directive({
  // select every <a target="_blank">...<a>
  selector: 'a[target=_blank]',
  standalone: true,
})
export class NoopenerDirective {
  // will add <a ... rel='noopener noreferrer'>...</a>
  @HostBinding('attr.rel') rel = 'noopener noreferrer';
}
