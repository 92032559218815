import { RembergDate } from '@remberg/global/common/core';

/**
 * Converts a RembergDate to a Date object without affecting the original date by using the midnight local time.
 * @param date date in RembergDate format: 'YYYY-MM-DD'
 */
export function convertRembergDateToDateWithCurrentTimezone(date: RembergDate): Date {
  const dateObj = new Date(date);

  return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
}
