import {
  AdvancedFilterOperatorEnum,
  AdvancedFilterWithinPeriodEnum,
  getAdvancedFilterDateRangeForPeriod,
} from '@remberg/advanced-filters/common/main';
import {
  assertEnum,
  dateToRembergDate,
  parseRembergDateTime,
  RembergDateTime,
} from '@remberg/global/common/core';
import { WorkOrderAdvancedFilter } from '@remberg/work-orders/common/main';
import { addMilliseconds, endOfDay, isDate, startOfDay } from 'date-fns';
import {
  generateIsEmptySQLFilter,
  generateNotEmptySQLFilter,
} from '../../../helpers/sqlFiltersHelper';
import { WorkOrderOfflineColumnNamesEnum } from './work-order-2-offline.definitions';

export function generatePlanningDateSqlQuery(
  tableName: string,
  columnName:
    | WorkOrderOfflineColumnNamesEnum.PLANNING_START_DATE
    | WorkOrderOfflineColumnNamesEnum.PLANNING_END_DATE,
  { operator, value }: WorkOrderAdvancedFilter,
): string {
  const fullColumnName = `${tableName}.${columnName}`;

  if (operator === AdvancedFilterOperatorEnum.IS_EMPTY) {
    return generateIsEmptySQLFilter(fullColumnName);
  }

  if (operator === AdvancedFilterOperatorEnum.IS_NOT_EMPTY) {
    return generateNotEmptySQLFilter(fullColumnName);
  }

  if (operator === AdvancedFilterOperatorEnum.IS_WITHIN) {
    assertEnum(value, AdvancedFilterWithinPeriodEnum);

    const { start, end: conventionalEnd } = getAdvancedFilterDateRangeForPeriod(value);
    const end = addMilliseconds(conventionalEnd, 1);
    const fullDayStart = `${dateToRembergDate(start)}T00:00:00.000Z`;
    const fullDayEnd = `${dateToRembergDate(end)}T00:00:00.000Z`;

    // as far as RembergDateTime is stored as a string in our SQLite DB
    // we need to keep in mind that the comparison will be done in the lexical order
    // so to match the end date properly we need to exclusivly compare it with the next day,
    // i.e the logic should be "columnName ∈ [start, end + 1ms)" indstead of "columnName ∈ [start, end]"
    return `(
        ${fullColumnName} >= '${start.toISOString()}' AND ${fullColumnName} < '${end.toISOString()}' AND ${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_IS_FULL_DAY} = 0 OR
        ${fullColumnName} >= '${fullDayStart}' AND ${fullColumnName} < '${fullDayEnd}' AND ${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_IS_FULL_DAY} = 1
      )`;
  }

  // we assume that the request came from the advanced filter dialog
  // that has a date picker only, without the time & timezone
  const [timestamp] = parseRembergDateTime(value as RembergDateTime);
  const localDate = new Date(timestamp);

  if (!isDate(localDate)) {
    throw new Error(`Invalid date: ${value}`);
  }

  const start = startOfDay(localDate);
  const end = addMilliseconds(endOfDay(localDate), 1);
  const fullDayStart = `${dateToRembergDate(start)}T00:00:00.000Z`;
  const fullDayEnd = `${dateToRembergDate(end)}T00:00:00.000Z`;

  if (
    operator === AdvancedFilterOperatorEnum.FROM ||
    operator === AdvancedFilterOperatorEnum.UNTIL
  ) {
    const comparison = operator === AdvancedFilterOperatorEnum.FROM ? '>=' : '<';
    const dateTimeValue = operator === AdvancedFilterOperatorEnum.FROM ? start : end;
    const fullDateValue = operator === AdvancedFilterOperatorEnum.FROM ? fullDayStart : fullDayEnd;

    return `(
        ${fullColumnName} ${comparison} '${dateTimeValue.toISOString()}' AND ${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_IS_FULL_DAY} = 0 OR
        ${fullColumnName} ${comparison} '${fullDateValue}' AND ${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_IS_FULL_DAY} = 1
      )`;
  }

  if (operator === AdvancedFilterOperatorEnum.IS) {
    return `(
        ${fullColumnName} >= '${start.toISOString()}' AND ${fullColumnName} < '${end.toISOString()}' AND ${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_IS_FULL_DAY} = 0 OR
        ${fullColumnName} >= '${fullDayStart}' AND ${fullColumnName} < '${fullDayEnd}' AND ${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_IS_FULL_DAY} = 1
      )`;
  }

  throw new Error(`Unreachable case: ${fullColumnName}:${operator}`);
}
