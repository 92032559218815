import { CustomTag, CustomTagRaw } from '@remberg/custom-tags/common/main';
import { Complete, createMapper } from '@remberg/global/common/core';

// This is kept purely for consistency. Normally the offline model also incorporates
// population hence a different interface is warranted as opposed to the pure raw
// object retrieved from the backend
export type CustomTagRawOffline = CustomTagRaw;

export function mapOneCustomTagRawOfflineToCustomTag(
  customTag: CustomTagRawOffline,
): Complete<CustomTag> {
  return {
    _id: customTag._id,
    tenantId: customTag.tenantId,
    createdAt: customTag.createdAt,
    updatedAt: customTag.updatedAt,
    createdById: customTag.createdById,
    updatedById: customTag.updatedById,
    createdByType: customTag.createdByType,
    reference: customTag.reference,
    order: customTag.order,
    label: customTag.label,
    description: customTag.description,
    model: customTag.model,
    color: customTag.color,
    isPredefined: customTag.isPredefined,
  };
}

export const mapCustomTagRawOfflineToCustomTag = createMapper(mapOneCustomTagRawOfflineToCustomTag);
