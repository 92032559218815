import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toString',
  standalone: true,
})
export class ToStringPipe implements PipeTransform {
  transform(value: unknown): string {
    const toString = (value as Record<string, () => string>)?.toString?.bind(value);
    return toString ? toString() : String(value);
  }
}
