import { OrganizationBasic } from '@remberg/crm/common/base';
import { Complete } from '@remberg/global/common/core';
import { Organization, OrganizationCompatibility, OrganizationRaw } from '../definitions';

export function organizationCompatibilityToOrganizationBasic(
  organization: OrganizationCompatibility,
): OrganizationBasic;
export function organizationCompatibilityToOrganizationBasic(
  organization: OrganizationCompatibility | undefined,
): OrganizationBasic | undefined;
export function organizationCompatibilityToOrganizationBasic(
  organization: OrganizationCompatibility | undefined,
): OrganizationBasic | undefined {
  if (!organization?.crmData) {
    return undefined;
  }

  return {
    _id: organization._id,
    name: organization.crmData.name,
    logoFileId: organization.crmData.logoFileId,
    externalReference: organization.crmData.externalReference,
    phoneNumbers: organization.crmData.phoneNumbers,
    shippingAddress: organization.crmData.shippingAddress,
    customTagIds: [], // Wojciech: we did not add tags to OrganizationCompatibility
  };
}

export function organizationRawToOrganizationBasic(
  organization: OrganizationRaw,
): OrganizationBasic;
export function organizationRawToOrganizationBasic(
  organization: OrganizationRaw | undefined,
): OrganizationBasic | undefined;
export function organizationRawToOrganizationBasic(
  organization: OrganizationRaw | undefined,
): OrganizationBasic | undefined {
  return (
    organization && {
      _id: organization._id,
      name: organization.crmData.name,
      externalReference: organization.crmData.externalReference,
      logoFileId: organization.crmData.logoFileId,
      phoneNumbers: organization.crmData.phoneNumbers,
      shippingAddress: organization.crmData.shippingAddress,
      customTagIds: organization.customTagIds,
    }
  );
}

export function organizationRawToOrganizationCompatibility(
  organization: OrganizationRaw,
): Complete<OrganizationCompatibility>;
export function organizationRawToOrganizationCompatibility(
  organization: OrganizationRaw | undefined,
): Complete<OrganizationCompatibility> | undefined;
export function organizationRawToOrganizationCompatibility(
  organization: OrganizationRaw | undefined,
): Complete<OrganizationCompatibility> | undefined {
  return (
    organization && {
      _id: organization._id,
      creatorId: organization.creatorId,
      tenantId: organization.tenantId,
      class: organization.class,
      tenantOrganizationId: organization.tenantOrganizationId,
      isTenantOwner: organization.isTenantOwner,
      crmData: organization.crmData,
    }
  );
}

export function organizationToOrganizationBasic(
  organization: Organization,
): Complete<OrganizationBasic>;
export function organizationToOrganizationBasic(
  organization: Organization | undefined,
): Complete<OrganizationBasic> | undefined;
export function organizationToOrganizationBasic(
  organization: Organization | undefined,
): Complete<OrganizationBasic> | undefined {
  return (
    organization && {
      _id: organization._id,
      name: organization.name,
      externalReference: organization.externalReference,
      logoFileId: organization.logoFileId,
      phoneNumbers: organization.phoneNumbers,
      shippingAddress: organization.shippingAddress,
      customTagIds: organization.customTagIds,
    }
  );
}
