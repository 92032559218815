import { EnvironmentInjector, Injectable } from '@angular/core';
import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import {
  DEFAULT_DIALOG_WIDTH,
  DynamicProgressBarConfig,
  LogService,
  StorageService,
} from '@remberg/global/ui';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { DialogOptions } from '../../dialogs/dialogs';
import { DynamicPopUpComponent } from '../../dialogs/dynamic-pop-up/dynamic-pop-up.component';
import { ModalDialogWrapper } from '../../dialogs/modalDialogWrapper';
import { LATEST_DB_VERSION } from '../../helpers/sqlDbHelper';
import { AppStateService } from '../app-state.service';
import { DialogService } from '../dialog.service';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';
import { migrateToV18 } from './sqlite-db-v18.migration';
import { migrateToV19 } from './sqlite-db-v19.migration';
import { migrateToV20 } from './sqlite-db-v20.migration';
import { migrateToV21 } from './sqlite-db-v21.migration';
import { migrateToV22 } from './sqlite-db-v22.migration';
import { migrateToV23 } from './sqlite-db-v23.migration';
import { migrateToV24 } from './sqlite-db-v24.migration';
import { migrateToV25 } from './sqlite-db-v25.migration';
import { migrateToV26 } from './sqlite-db-v26.migration';
import { migrateToV27 } from './sqlite-db-v27.migration';
import { migrateToV28 } from './sqlite-db-v28.migration';
import { migrateToV29 } from './sqlite-db-v29.migration';
import { migrateToV30 } from './sqlite-db-v30.migration';
import { migrateToV31 } from './sqlite-db-v31.migration';
import { migrateToV32 } from './sqlite-db-v32.migration';

@Injectable({
  providedIn: 'root',
})
export class MigrationService {
  public migrationDone$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private logger: LogService,
    private _dialog: DialogService,
    private appState: AppStateService,
    private readonly storage: StorageService,
    private injector: EnvironmentInjector,
  ) {}

  public async migrateSQLiteDB(db: SQLiteObject | SQLiteObjectMock): Promise<void> {
    // just a helper to force migration again:
    // try {
    //     const name = 'c17'
    //     await db.executeSql(`PRAGMA user_version = 2;`, []);
    //     await db.executeSql(`ALTER TABLE assets RENAME COLUMN productTypeName TO ${name};`);
    //     // await db.executeSql(`ALTER TABLE workOrders RENAME COLUMN attendants TO ${name};`);
    //     // await db.executeSql('ALTER TABLE workOrders RENAME COLUMN assets TO asset;');
    //     await db.executeSql('ALTER TABLE assets RENAME COLUMN customer TO customer16;');
    // } catch (err) {
    //     console.log(err)
    // }

    // Determine the DB version and migrate the DB if necessary
    this.logger.debug()('Determining DB version...');
    let currentDBVersion: number;
    // For some reason, this sql query returns the result as an error.
    // Query strings that work normally elsewhere have the same issue.
    try {
      const result = await db.executeSql('PRAGMA user_version;', []);
      this.logger.debug()('Retrieve DB version as result');
      currentDBVersion = result?.rows?.item(0)?.user_version;
    } catch (err) {
      this.logger.error()('Retrieve DB version failed', err);
      throw err;
    }
    this.logger.info()('DB version: ' + currentDBVersion + ' (latest: ' + LATEST_DB_VERSION + ')');

    // Check if migration is necessary:
    if (currentDBVersion > -1 && currentDBVersion < LATEST_DB_VERSION) {
      // set the initial progress state:
      const migrationProgressSubject = new BehaviorSubject<DynamicProgressBarConfig>({
        text: $localize`:@@preparingTheUpdate:Preparing the update...`,
        progress: 0,
        confirmOnFinish: true,
      });
      // open a popup to show the migration progress
      const migrationStatusPopup = this.showMigrationStatusPopup(migrationProgressSubject);

      // do the migrations
      const numMigrations = LATEST_DB_VERSION - currentDBVersion;
      try {
        // Future migration steps to be added here

        // === Migrations for mobile release v2.0.0 ===
        // Migration V17 --> V18
        if (currentDBVersion < 18) {
          await migrateToV18(
            db,
            this.logger,
            migrationProgressSubject,
            100 / numMigrations,
            this.appState,
          );
          currentDBVersion = 18;
        }

        // === Migrations for mobile release v2.10.0 ===
        // Migration V18 --> V19
        if (currentDBVersion < 19) {
          await migrateToV19(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 19;
        }
        // Migration V19 --> V20
        if (currentDBVersion < 20) {
          await migrateToV20(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 20;
        }

        // === Migrations for mobile release v2.18.0 ===
        // Migration V20 --> V21
        if (currentDBVersion < 21) {
          await migrateToV21(
            db,
            this.logger,
            migrationProgressSubject,
            100 / numMigrations,
            this.storage,
          );
          currentDBVersion = 21;
        }

        // === Migrations for mobile release v2.18.3 ===
        // Migration V21 --> V22
        if (currentDBVersion < 22) {
          await migrateToV22(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 22;
        }

        // === Migrations for mobile release v2.26.0 ===
        // Migration V22 --> V23
        if (currentDBVersion < 23) {
          await migrateToV23(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 23;
        }

        // === Migrations for mobile release v2.34.0 to be added here ===
        // Migration V23 --> V24
        if (currentDBVersion < 24) {
          await migrateToV24(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 24;
        }

        // === Migrations for mobile release v2.46.0 to be added here ===
        // Migration V24 --> V25
        if (currentDBVersion < 25) {
          await migrateToV25(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 25;
        }

        // === Migrations for mobile release v2.50.0 to be added here ===
        // Migration V25 --> V26
        if (currentDBVersion < 26) {
          await migrateToV26(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 26;
        }

        // === Migrations for mobile release v2.54.1 to be added here ===
        // Migration V26 --> V27
        if (currentDBVersion < 27) {
          await migrateToV27(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 27;
        }

        // === Migrations for mobile release v2.58.0 to be added here ===
        // Migration V27 --> V28
        if (currentDBVersion < 28) {
          await migrateToV28(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 28;
        }

        // === Migrations for mobile release v2.66.0 to be added here ===
        // Migration V28 --> V29
        if (currentDBVersion < 29) {
          await migrateToV29(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 29;
        }

        // Migration V29 --> V30
        if (currentDBVersion < 30) {
          await migrateToV30(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 30;
        }

        // === Migrations for mobile release v2.75.0 to be added here ===
        // Migration V30 --> V31
        if (currentDBVersion < 31) {
          await migrateToV31(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 31;
        }

        // === Migrations for mobile release v2.79.0 to be added here ===
        // Migration V31 --> V32
        if (currentDBVersion < 32) {
          await migrateToV32(db, this.logger, migrationProgressSubject, 100 / numMigrations);
          currentDBVersion = 32;
        }

        // === Migrations for mobile release v2.83.0 to be added here ===
        // Migration V32 --> V33

        // TODO

        // Here we can add more migrations if necessary

        // set the migration progress to 100%
        migrationProgressSubject.next({
          text: $localize`:@@updateComplete:Update complete!`,
          progress: 100,
          confirmOnFinish: true,
        });

        if (currentDBVersion !== LATEST_DB_VERSION) {
          this.logger.error()(
            `Migration failed. DB version is not up to date. ` +
              `Current version: ${currentDBVersion}, latest version: ${LATEST_DB_VERSION}`,
          );
          throw new Error('Migration failed. DB version is not up to date.');
        }

        // wait 3s and then close the popup
        await new Promise((resolve) => setTimeout(resolve, 3000));
        migrationStatusPopup.closeDialogModal();
      } catch (error: any) {
        this.logger.error()('Migration failed for version ' + (currentDBVersion + 1), error);
        // close the progress popup and show error popup instead
        migrationStatusPopup.closeDialogModal().then(() => {
          this.showMigrationErrorPopup(error);
          throw error;
        });
      }
    }

    // set the migration done status to true
    this.migrationDone$.next(true);
  }

  private showMigrationStatusPopup(
    progressBarConfigSubject: Subject<DynamicProgressBarConfig>,
  ): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@theAppHasBeenUpdatedDotPleaseWaitUntilWeHaveConfiguredYourLocalData:
                    The app has been updated. Please wait until we have configured your local data.`,
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogBackdropCloseDisabled: true,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          headerTitle: '',
          headerCloseActionShow: false,
          styleNoMargin: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'system_update',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@appUpdate:App Update`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons: [] },
          {
            progressBarConfig$: progressBarConfigSubject,
          },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  private showMigrationErrorPopup(error: Error): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@weAreSorryForTheInconvenience:
                We are sorry for the inconvenience!`,
      $localize`:@@pleaseNoteDownTheErrorBelowAndContactTheRembergSupport:
                Please note down the error below and contact the remberg support.`,
      // eslint-disable-next-line max-len
      $localize`:@@alternativelyYouCanResetYourLocalAppDataViaAppSettingsOfYourDeviceButYouWillLooseYourUnsynchedLocalChangesAndWillNeedToPerformAnotherSynchronizationBeforeBeingAbleToWorkOfflineAgain:Alternatively you can reset your local app data via the app settings of your device but you will loose your un-synchronized local changes and will need to perform another synchronization before being able to work offline again.`,
      error.message,
    ];
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogBackdropCloseDisabled: true,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          headerTitle: '',
          headerCloseActionShow: false,
          styleNoMargin: false,
          styleWidth: DEFAULT_DIALOG_WIDTH,
          styleHeight: 'auto',
        },
        factoryInput: [
          {
            icon: {
              icon: 'report',
              color: 'primary',
            },
          },
          {
            title: {
              text: $localize`:@@updateError:Update Error`,
              position: 'center',
            },
          },
          {
            description: {
              text: descriptionTexts,
              position: 'center',
            },
          },
          { showDoNotAskAgain: false },
          { hideAbortButton: true },
          { buttons: [] },
        ],
      },
    };
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }
}
