import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';

/**
 * Migrates data in the specified table by adding default values to specified properties if they do not exist.
 *
 * @param {SQLiteObject | SQLiteObjectMock} db - The database connection object.
 * @param {SyncDataTypesEnum} dataType - The type of data to migrate (table name).
 * @param {Record<string, unknown>} propertyValuePairs - An object where keys are properties to be modified and values are the default values to add if they do not exist.
 * @returns {Promise<void>} - A promise that resolves when the migration is complete.
 */
export async function updateRecordsWithSpecifiedProperties(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  dataType: SyncDataTypesEnum,
  propertyValuePairs: Record<string, unknown>,
): Promise<void> {
  const tableExistsQuery = await db.executeSql(
    `SELECT name FROM sqlite_master WHERE type='table' AND name='${dataType}';`,
    [],
  );
  if (tableExistsQuery.rows?.item(0)?.name !== dataType) {
    logger.error()(
      `[updateRecordsWithSpecifiedProperties]: Specified SyncDataTypesEnum: ${dataType} not found`,
    );
    return;
  }

  logger.debug()(`[updateRecordsWithSpecifiedProperties]: Found ${dataType} table`);
  const numOfRecordsToMigrate =
    (await db.executeSql(`SELECT COUNT(_id) FROM ${dataType}`, []))?.rows.item(0)?.['COUNT(_id)'] ??
    0;

  if (numOfRecordsToMigrate === 0) {
    logger.warn()(
      `[updateRecordsWithSpecifiedProperties]: No records found for migration; Aborting!`,
    );
    return;
  }

  logger.debug()(
    `[updateRecordsWithSpecifiedProperties]: Number of records to migrate: ${numOfRecordsToMigrate}`,
  );
  const batchSize = 100;
  const numBatches = Math.ceil(numOfRecordsToMigrate / batchSize);

  for (let i = 0; i < numBatches; i++) {
    const records = await db.executeSql(
      `SELECT * FROM ${dataType} LIMIT ${batchSize} OFFSET ${i * batchSize}`,
      [],
    );

    const updatePromises = [];
    for (let x = 0; x < records.rows.length; x++) {
      const record = JSON.parse(records.rows.item(x).instance);
      let hasChanged = false;

      for (const [property, defaultValue] of Object.entries(propertyValuePairs)) {
        if (!(property in record)) {
          record[property] = defaultValue;
          hasChanged = true;
        }
      }

      if (hasChanged) {
        updatePromises.push(
          db.executeSql(`UPDATE ${dataType} SET instance = ? WHERE _id = ?`, [
            JSON.stringify(record),
            record._id,
          ]),
        );
      }
    }
    await Promise.all(updatePromises);
  }
}
