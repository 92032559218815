import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsDurationString',
  standalone: true,
})
export class SecondsDurationStringPipe implements PipeTransform {
  public transform(val: number): string {
    const secondText = $localize`:@@secondsSmall:s`;

    if (val !== undefined && val !== null && !Number.isNaN(val)) {
      const seconds = Math.floor(val / 1000); // convert diff to seconds
      if (seconds < 60) {
        return `${seconds}${secondText}`;
      }

      const minuteText = $localize`:@@minutesShort:m`;
      const hourText = $localize`:@@hoursShort:h`;
      const dayText = $localize`:@@daysShort:d`;

      const minutes = Math.floor(seconds / 60); // convert diff to seconds
      if (minutes < 60) {
        const newVal = val % (60 * 1000);
        return (
          `${minutes}${minuteText}` + (newVal > 0 ? ' ' + this.transform(val % (60 * 1000)) : '')
        ); // ${seconds - minutes * 60}s
      }

      const hours = Math.floor(minutes / 60); // convert diff to seconds
      if (hours < 24) {
        const newVal = val % (60 * 60 * 1000);
        return (
          `${hours}${hourText}` + (newVal > 0 ? ' ' + this.transform(val % (60 * 60 * 1000)) : '')
        );
      }

      const days = Math.floor(hours / 24); // convert diff to seconds
      const newVal = val % (24 * 60 * 60 * 1000);
      return (
        `${days}${dayText}` + (newVal > 0 ? ' ' + this.transform(val % (24 * 60 * 60 * 1000)) : '')
      );
    } else {
      return `0${secondText}`;
    }
  }
}
