import { AssetsPermissionEnum } from '@remberg/assets/common/main';
import { DashboardsPermissionsEnum } from '@remberg/dashboards/common/main';
import { FilesPermissionEnum } from '@remberg/files/common/main';
import { FormInstancePermissionsEnum } from '@remberg/forms/common/main';
import { FeatureFlagEnum, InternalFeatureFlagValues } from '@remberg/global/common/core';
import {
  ASSETS_CHILD_NAVLINK,
  ASSETS_NAVLINK,
  ASSETS_TYPES_NAVLINK,
  CASES_NAVLINK,
  COMPANIES_NAVLINK,
  CONTACTS_NAVLINK,
  DASHBOARDS_NAVLINK,
  DIVIDER_NAVLINK,
  FILES_NAVLINK,
  FORMS_NAVLINK,
  GLOBAL_MAP_NAVLINK,
  MAINTENANCE_PLANS_2_NAVLINK,
  MAINTENANCE_PLANS_NAVLINK,
  NavLink,
  PARTS_NAVLINK,
  REMBERG_ADMIN_NAVLINKS,
  SCHEDULING_BOARD_2_NAVLINK,
  SETTINGS_LINK,
  TASKS_NAVLINK,
  TICKETS2_NAVLINK,
  WORKORDERS_2_CHILD_NAVLINK,
  WORKORDERS_2_NAVLINK,
} from '@remberg/global/ui';
import { MaintenancePlanRightsEnum } from '@remberg/maintenance-plans/common/main';
import { PartPermissionsEnum } from '@remberg/parts/common/main';
import { TasksPermissionEnum } from '@remberg/tasks/common/main';
import { TicketsPermissionEnum } from '@remberg/tickets2/common/main';
import { Permissions, RembergUser, SettingsPermissionsEnum } from '@remberg/users/common/main';
import {
  MaintenancePlanPermissionsEnum,
  WorkOrderPermissionsEnum,
} from '@remberg/work-orders/common/main';
import { checkFeatureFlag, checkUserPermission } from '../../helpers/permission-check-helpers';

export function generateNavlinksForUser(
  rembergUser: RembergUser | undefined,
  tenantFeatures: InternalFeatureFlagValues | undefined,
  permissions: Permissions | undefined,
  isIonic: boolean,
  isSmallOrXSmallView: boolean,
): NavLink[] {
  if (rembergUser?.isRembergAdmin) {
    return REMBERG_ADMIN_NAVLINKS;
  }
  if (!rembergUser || !tenantFeatures || !permissions) {
    return [];
  }
  if (rembergUser.isTenantOwner) {
    return generateTenantOwnerUserNavLinks(
      permissions,
      tenantFeatures,
      isIonic,
      isSmallOrXSmallView,
    );
  }
  return generateManagedUserNavLinks(permissions, tenantFeatures, isIonic);
}

function generateManagedUserNavLinks(
  permissions: Permissions,
  tenantFeatures: InternalFeatureFlagValues,
  isIonic: boolean,
): NavLink[] {
  if (isIonic) {
    return [];
  }

  const checkPermission = (
    action: Parameters<typeof checkUserPermission>[0],
    viewCheck?: boolean,
  ): boolean => checkUserPermission(action, permissions, tenantFeatures, viewCheck);

  const isAssetsNavLinkEnabled = checkPermission(AssetsPermissionEnum.ASSETS_ENABLED, true);
  const isCasesNavLinkEnabled =
    !checkFeatureFlag(FeatureFlagEnum.TICKETS_TEMPORARY, tenantFeatures) &&
    checkPermission(TicketsPermissionEnum.TICKETS_ENABLED);
  const isTickets2NavLinkEnabled =
    checkFeatureFlag(FeatureFlagEnum.TICKETS_TEMPORARY, tenantFeatures) &&
    checkPermission(TicketsPermissionEnum.TICKETS_ENABLED);
  return [
    ...(isAssetsNavLinkEnabled ? [{ ...ASSETS_NAVLINK, children: [ASSETS_CHILD_NAVLINK] }] : []),
    ...(isCasesNavLinkEnabled ? [CASES_NAVLINK] : []),
    ...(isTickets2NavLinkEnabled ? [TICKETS2_NAVLINK] : []),
  ];
}

function generateTenantOwnerUserNavLinks(
  permissions: Permissions,
  tenantFeatures: InternalFeatureFlagValues,
  isIonic: boolean,
  isSmallOrXSmallView: boolean,
): NavLink[] {
  const checkPermission = (
    action: Parameters<typeof checkUserPermission>[0],
    viewCheck?: boolean,
  ): boolean => checkUserPermission(action, permissions, tenantFeatures, viewCheck);

  const isTasksNavLinkEnabled = checkPermission(TasksPermissionEnum.TASKS_ENABLED);
  const isWorkorders2NavLinkEnabled = checkPermission(WorkOrderPermissionsEnum.WORK_ORDERS_ENABLED);
  const isFormsNavLinkEnabled = checkPermission(FormInstancePermissionsEnum.FORMS_ENABLED);

  if (isIonic) {
    return [
      ...(isTasksNavLinkEnabled ? [TASKS_NAVLINK] : []),

      ...(isWorkorders2NavLinkEnabled
        ? [
            {
              ...WORKORDERS_2_NAVLINK,
              children: [WORKORDERS_2_CHILD_NAVLINK],
            },
          ]
        : []),
      ...(isFormsNavLinkEnabled ? [FORMS_NAVLINK] : []),
    ];
  }
  const isDashboardsNavLinkEnabled =
    checkPermission(DashboardsPermissionsEnum.DASHBOARDS_ENABLED, true) && !isSmallOrXSmallView;
  const isAssetsNavLinkEnabled = checkPermission(AssetsPermissionEnum.ASSETS_ENABLED, true);
  const isGlobalMapNavLinkEnabled = checkFeatureFlag(FeatureFlagEnum.GLOBAL_MAP, tenantFeatures);
  const isPartsNavLinkEnabled = checkPermission(PartPermissionsEnum.PARTS_VIEW_IN_OVERVIEW);
  const isFilesNavLinkEnabled =
    checkPermission(FilesPermissionEnum.FILES_ENABLED) &&
    !checkPermission(FilesPermissionEnum.FILES_SHOW_ONLY_IN_ASSET_DETAILS);
  const isCasesNavLinkEnabled =
    !checkFeatureFlag(FeatureFlagEnum.TICKETS_TEMPORARY, tenantFeatures) &&
    checkPermission(TicketsPermissionEnum.TICKETS_ENABLED);
  const isTickets2NavLinkEnabled =
    checkFeatureFlag(FeatureFlagEnum.TICKETS_TEMPORARY, tenantFeatures) &&
    checkPermission(TicketsPermissionEnum.TICKETS_ENABLED);
  const isScheduling2NavLinkEnabled =
    checkPermission(WorkOrderPermissionsEnum.WORK_ORDERS_ENABLED) &&
    checkFeatureFlag(FeatureFlagEnum.WORKORDERS_SCHEDULING, tenantFeatures);
  const isMaintenancePlansNavLinkEnabled =
    checkPermission(MaintenancePlanRightsEnum.MAINTENANCE_PLAN_ENABLED) && !isSmallOrXSmallView;
  const isMaintenancePlans2NavLinkEnabled = checkPermission(
    MaintenancePlanPermissionsEnum.MAINTENANCE_PLANS_ENABLED,
  );
  const isSettingsEnabled =
    checkPermission(SettingsPermissionsEnum.SETTINGS_ENABLED, true) && !isSmallOrXSmallView;

  return [
    ...(isDashboardsNavLinkEnabled ? [DASHBOARDS_NAVLINK] : []),
    ...(isGlobalMapNavLinkEnabled ? [GLOBAL_MAP_NAVLINK] : []),
    ...(isGlobalMapNavLinkEnabled && isDashboardsNavLinkEnabled ? [DIVIDER_NAVLINK] : []),
    ...(isAssetsNavLinkEnabled
      ? [
          {
            ...ASSETS_NAVLINK,
            children: [ASSETS_CHILD_NAVLINK, DIVIDER_NAVLINK, ASSETS_TYPES_NAVLINK],
          },
        ]
      : []),
    COMPANIES_NAVLINK,
    CONTACTS_NAVLINK,
    ...(isPartsNavLinkEnabled ? [PARTS_NAVLINK] : []),
    ...(isFilesNavLinkEnabled ? [FILES_NAVLINK] : []),
    ...(isTasksNavLinkEnabled ? [TASKS_NAVLINK] : []),
    ...(isCasesNavLinkEnabled ? [CASES_NAVLINK] : []),
    ...(isTickets2NavLinkEnabled ? [TICKETS2_NAVLINK] : []),
    ...(isWorkorders2NavLinkEnabled
      ? [
          {
            ...WORKORDERS_2_NAVLINK,
            children: [
              WORKORDERS_2_CHILD_NAVLINK,
              ...(isScheduling2NavLinkEnabled || isMaintenancePlansNavLinkEnabled
                ? [DIVIDER_NAVLINK]
                : []),
              ...(isScheduling2NavLinkEnabled ? [SCHEDULING_BOARD_2_NAVLINK] : []),
              ...(isMaintenancePlansNavLinkEnabled ? [MAINTENANCE_PLANS_NAVLINK] : []),
              ...(isMaintenancePlans2NavLinkEnabled ? [MAINTENANCE_PLANS_2_NAVLINK] : []),
            ],
          },
        ]
      : []),
    ...(isFormsNavLinkEnabled ? [FORMS_NAVLINK] : []),
    ...(isSettingsEnabled ? [DIVIDER_NAVLINK, SETTINGS_LINK] : []),
  ];
}
