import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FeatureFlagKey,
  PermissionAction,
  RootGlobalState,
  getHasAccess$,
} from '@remberg/ui-core/core';
import { Subscription } from 'rxjs';

/**
 * Structural directive that renders the template or not based on passed permission action and
 * optionally feature flag key.
 *
 * Decision is made using current tenant and user role from NGRX Store.
 * If neither is passed, the element will be rendered.
 * If tenant/role are missing in store, the element will not be rendered.
 *
 * Can use `; else tmplVar` just like in `ngIf` directive.
 * Can use `; viewCheck: true`
 *
 * For checking just feature flag, use `ifHasFeature` directive.
 *
 * Example with just permission:
 * ```html
 * <p *ifHasAccess="AiPermissionsEnum.AI_COPILOT_ENABLED">content</p>
 * ```
 *
 * Example with both:
 * ```html
 * <p
 *   *ifHasAccess="
       AiPermissionsEnum.AI_COPILOT_ENABLED;
       feature: FeatureFlagEnum.AI_COPILOT
     "
   >
     content
   </p>
 * ```
 */
@Directive({ selector: '[ifHasAccess]', standalone: true })
export class IfHasAccessDirective implements OnChanges, OnDestroy {
  @Input() public ifHasAccess?: PermissionAction;
  @Input() public ifHasAccessFeature?: FeatureFlagKey;
  @Input() public ifHasAccessElse?: TemplateRef<unknown>;
  @Input() public ifHasAccessViewCheck?: boolean;

  private thenViewRef?: EmbeddedViewRef<unknown>;
  private elseViewRef?: EmbeddedViewRef<unknown>;

  private hasAccessSubscription?: Subscription;

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['ifHasAccess'] || changes?.['featureFlag']) {
      this.setupAccess();
    }
  }

  public ngOnDestroy(): void {
    this.hasAccessSubscription?.unsubscribe();
  }

  private setupAccess(): void {
    const action = this.ifHasAccess;
    const featureFlag = this.ifHasAccessFeature;

    if (this.hasAccessSubscription) {
      this.hasAccessSubscription.unsubscribe();
    }

    this.hasAccessSubscription = getHasAccess$(
      this.store,
      action,
      featureFlag,
      this.ifHasAccessViewCheck,
    ).subscribe((hasAccess) => this.updateView(hasAccess));
  }

  private updateView(hasAccess: boolean): void {
    if (hasAccess) {
      if (!this.thenViewRef) {
        this.viewContainerRef.clear();
        this.elseViewRef = undefined;
        this.thenViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainerRef.clear();
        this.thenViewRef = undefined;
        if (this.ifHasAccessElse) {
          this.elseViewRef = this.viewContainerRef.createEmbeddedView(this.ifHasAccessElse);
        }
      }
    }
  }
}
