<div [ngClass]="{ 'date-inline': showInline, 'date-show-in-forms': showInForms }">
  <div class="label" [matTooltipDisabled]="!isTooltipVisible" [matTooltip]="label">
    {{ label }}
    @if (required) {
      <span class="required">*</span>
    }
  </div>
  @if (showInline) {
    <span class="fill-remaining-space"></span>
  }
  @if (value) {
    <div
      class="value"
      [matTooltipDisabled]="!isTooltipVisible"
      [matTooltip]="value | rembergDateDisplay"
    >
      {{ value | rembergDateDisplay }}
    </div>
  } @else {
    <div class="value">-</div>
  }
</div>
