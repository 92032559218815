import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { IsPageActiveEnum } from '@remberg/global/ui';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RouterSelectors } from '../store';

/**
 * Allow flow only if the current route has data object with relevant key
 *
 * @param store Reference to a Store that has ngrx router store enabled
 * @param isPageActiveFlag route data property name to check
 */
export function ofPage<T>(
  store: Store,
  isPageActiveFlag: IsPageActiveEnum,
): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      concatLatestFrom(() => store.select(RouterSelectors.selectRouteData)),
      filter(([, data]) => data?.[isPageActiveFlag]),
      map(([payload]) => payload),
    );
}

/**
 * Allow flow only if the current route does not have data object with relevant key
 *
 * @param store Reference to a Store that has ngrx router store enabled
 * @param isPageActiveFlag route data property name to check
 */
export function ofNotPage<T>(
  store: Store,
  isPageActiveFlag: IsPageActiveEnum,
): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      concatLatestFrom(() => store.select(RouterSelectors.selectRouteData)),
      filter(([, data]) => !data?.[isPageActiveFlag]),
      map(([payload]) => payload),
    );
}
