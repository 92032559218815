import { as } from '@remberg/global/common/core';
import * as forms from '../form-sections';
import { KnownDataTypeEnum } from './known-data-types';
import { ConfigWithPrefilling } from './prefilling';

// Returns TargetEnum type for the field if it supports prefilling
type GetPrefillableFieldTargetEnum<F, FieldType extends forms.FormFieldTypesEnum> =
  F extends forms.Field<FieldType, ConfigWithPrefilling<infer V>> ? V : never;

// Map of all field types and TargetEnums for prefilling
type PrefillableFieldTargetEnums = {
  [FieldType in forms.FormFieldTypesEnum]: GetPrefillableFieldTargetEnum<
    forms.FormField,
    FieldType
  >;
};

// Map of maps for the field types that support prefilling (extend ConfigWithPrefilling)
// and the corresponding property names in the field data
type PrefillableFieldTargetPropertyMap = {
  [Key in forms.FormFieldTypesEnum as PrefillableFieldTargetEnums[Key] extends never
    ? never
    : Key]: {
    [TargetPropertyName in PrefillableFieldTargetEnums[Key]]: DataPropertyInfo;
  };
};

export interface DataPropertyInfo {
  name: string | undefined;
  type: KnownDataTypeEnum;
}

// Field types that support prefilling
export type PrefillableFieldType = keyof PrefillableFieldTargetPropertyMap;

// Returns TargetEnum type by PrefillableFieldType
export type PrefillableFieldTargetEnum<T extends PrefillableFieldType> =
  PrefillableFieldTargetEnums[T];

// Returns TargetEnum type for the section if it supports prefilling
type GetPrefillableSectionTargetEnum<
  S,
  SectionType extends forms.FormSectionTypesEnum,
> = S extends { type: SectionType; config: ConfigWithPrefilling<infer V> } ? V : never;

// Map of all sections types and TargetEnums for prefilling
type PrefillableSectionTargetEnums = {
  [SectionType in forms.FormSectionTypesEnum]: GetPrefillableSectionTargetEnum<
    forms.FormSection,
    SectionType
  >;
};

// Map of maps for the section types that support prefilling (extend ConfigWithPrefilling)
// and the corresponding property names in the section data
type PrefillableSectionTargetPropertyMap = {
  [Key in forms.FormSectionTypesEnum as PrefillableSectionTargetEnums[Key] extends never
    ? never
    : Key]: {
    [TargetPropertyName in PrefillableSectionTargetEnums[Key]]: DataPropertyInfo;
  };
};

export type PrefillableTargetDataPropertyNameMap = PrefillableFieldTargetPropertyMap &
  PrefillableSectionTargetPropertyMap;

export type PrefillableTargetType = keyof PrefillableTargetDataPropertyNameMap;

export type PrefillableTargetPropertyEnum =
  | PrefillableFieldTargetEnums[forms.FormFieldTypesEnum]
  | PrefillableSectionTargetEnums[forms.FormSectionTypesEnum];

export const PREFILLABLE_TARGET_DATA_PROPERTY_INFO: PrefillableTargetDataPropertyNameMap = {
  addressInput: {
    value: {
      name: as<keyof forms.AddressInputFieldData>('address'),
      type: KnownDataTypeEnum.ADDRESS,
    },
  },
  assetMultiSelect: {
    selectedAssets: {
      name: as<keyof forms.AssetMultiSelectFieldData>('entries'),
      type: KnownDataTypeEnum.ASSET_IDS,
    },
  },
  personListInput: {
    entries: {
      name: as<keyof forms.PersonListInputFieldData>('entries'),
      type: KnownDataTypeEnum.PERSON_LIST_ENTRIES,
    },
  },
  assetSingleSelect: {
    selectedAsset: {
      name: as<keyof forms.AssetSingleSelectFieldData>('selectedAsset'),
      type: KnownDataTypeEnum.ASSET_ID,
    },
  },
  booleanInput: {
    value: {
      name: as<keyof forms.BooleanInputFieldData>('checked'),
      type: KnownDataTypeEnum.BOOLEAN,
    },
  },
  companySingleSelect: {
    selectedCompany: {
      name: as<keyof forms.OrganizationSingleSelectFieldData>('selectedCompany'),
      type: KnownDataTypeEnum.ORGANIZATION_ID,
    },
  },
  dateInput: {
    value: {
      name: as<keyof forms.DateInputFieldData>('date'),
      type: KnownDataTypeEnum.REMBERG_DATE,
    },
  },
  dateTimeInput: {
    value: {
      name: as<keyof forms.DateTimeInputFieldData>('dateTime'),
      type: KnownDataTypeEnum.REMBERG_DATETIME,
    },
  },
  multiLineTextInput: {
    value: {
      name: as<keyof forms.MultiLineTextInputFieldData>('text'),
      type: KnownDataTypeEnum.STRING,
    },
  },
  phoneNumberInput: {
    value: {
      // for phoneNumberInput we shouldn't specify anything because
      // we want to replace the whole data with the new value from prefilling
      name: undefined,
      type: KnownDataTypeEnum.PHONE_NUMBER,
    },
  },
  richTextInput: {
    value: {
      name: as<keyof forms.RichTextInputFieldData>('html'),
      type: KnownDataTypeEnum.STRING,
    },
  },
  singleLineTextInput: {
    value: {
      name: as<keyof forms.SingleLineTextInputFieldData>('text'),
      type: KnownDataTypeEnum.STRING,
    },
  },
  staticMultiSelect: {
    value: {
      name: as<keyof forms.StaticMultiSelectFieldData>('selectedValues'),
      type: KnownDataTypeEnum.ARRAY_OF_STRINGS,
    },
  },
  staticSingleSelect: {
    value: {
      name: as<keyof forms.StaticSingleSelectFieldData>('selectedValue'),
      type: KnownDataTypeEnum.STRING,
    },
  },
  timeInput: {
    value: {
      name: as<keyof forms.TimeInputFieldData>('time'),
      type: KnownDataTypeEnum.REMBERG_TIME,
    },
  },
  taskListInput: {
    entries: {
      name: as<keyof forms.TaskListInputFieldData>('entries'),
      type: KnownDataTypeEnum.TASKS,
    },
  },
  userSingleSelect: {
    value: {
      name: as<keyof forms.ContactSingleSelectFieldData>('user'),
      type: KnownDataTypeEnum.CONTACT_ID,
    },
  },
  signatureSection: {
    date: {
      name: as<keyof forms.FormSignatureSectionData>('date'),
      type: KnownDataTypeEnum.REMBERG_DATE,
    },
    location: {
      name: as<keyof forms.FormSignatureSectionData>('location'),
      type: KnownDataTypeEnum.STRING,
    },
    name: {
      name: as<keyof forms.FormSignatureSectionData>('name'),
      type: KnownDataTypeEnum.STRING,
    },
  },
};
