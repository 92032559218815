import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormInstanceEmailStatus } from '@remberg/forms/common/dtos';
import { FormInstanceEmailStatusEnum } from '@remberg/forms/common/main';
import { LogService, SQLQueryParams, SyncDataTypesEnum } from '@remberg/global/ui';
import { RootGlobalState } from '../../store/core-ui.definitions';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

const params: SQLQueryParams = {
  idString: '_id',
  tableName: SyncDataTypesEnum.EMAILSTATUSES,
  columns: {},
};

@Injectable({
  providedIn: 'root',
})
export class EmailStatusOfflineService extends BaseOfflineService<FormInstanceEmailStatus, never> {
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  public async getManyByIds(ids: string[]): Promise<Record<string, FormInstanceEmailStatusEnum>> {
    if (!ids.length) {
      return {};
    }

    const filterString = `_id IN (${ids.map((id) => `'${id}'`).join(',')})`;
    const emailStatuses = await this.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      filterString,
    );

    return emailStatuses.reduce(
      (result, dto) => ({
        ...result,
        [dto._id]: dto.status,
      }),
      {},
    );
  }
}
