import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AutoSavingStateEnum,
  ButtonActions,
  DynamicButtonConfig,
  DynamicProgressBarConfig,
} from '@remberg/global/ui';
import { Observable, Subscription } from 'rxjs';
import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';
import { ModalDialogWrapperInterface } from '../dialogs';

@Component({
  selector: 'app-dynamic-pop-up',
  templateUrl: './dynamic-pop-up.component.html',
  styleUrls: ['./dynamic-pop-up.component.scss'],
})
export class DynamicPopUpComponent implements OnInit, OnDestroy {
  // Main content configurations
  @Input() public icon?: {
    icon: string;
    color: string;
  };

  @Input() public title?: {
    text: string;
    position: 'center' | 'left';
  };

  @Input() public description?: {
    text: string[];
    position: 'center' | 'left' | 'justify';
    redColor?: boolean;
  };

  // Other content configurations
  @Input() public showDoNotAskAgain: boolean = false;

  @Input() public showDoNotAskAgainTextCentered: boolean = false;

  @Input() public checked: boolean = false;

  @Input() public hasAnimation: boolean = false;

  // Buttons
  @Input() public hideAbortButton: boolean = false;

  @Input() public buttonsDirection: 'vertical' | 'horizontal' = 'horizontal';

  @Input() public buttons: DynamicButtonConfig[] = [
    {
      text: $localize`:@@cancel:Cancel`,
      category: 'danger',
      action: ButtonActions.ABORT,
      dataTestId: 'button-abort',
    },
    {
      text: $localize`:@@confirm:Confirm`,
      category: 'success',
      color: 'primary',
      action: ButtonActions.CONFIRM,
      dataTestId: 'button-confirm',
    },
  ];

  // Progress bar
  /** configuration object for displaying text, current status and confirmation at end */
  @Input() public progressBarConfig?: DynamicProgressBarConfig;

  /** input the respective observable that is responsible for the progress status */
  @Input() public progressBarConfig$?: Observable<DynamicProgressBarConfig>;

  // Autosaving config
  /** general flag to enable the autosaving animation with icon and header */
  @Input() public showAutoSavingState: boolean = false;

  /** input the respective observable that is responsible for the saving status */
  @Input() public savingInProgress$?: Observable<boolean>;

  public progressBarCompleted: boolean = false;
  public autoSavingState: AutoSavingStateEnum = AutoSavingStateEnum.SAVING;
  public AutoSavingStateEnum = AutoSavingStateEnum;
  public subscriptions: Subscription = new Subscription();

  // General
  @Input() public dialogRef?: MatDialogRef<DialogWrapperComponent, any>;
  @Input() public dialogModalRef?: ModalDialogWrapperInterface;

  constructor(private cDRef: ChangeDetectorRef) {
    // empty
  }

  public ngOnInit(): void {
    if (this.progressBarConfig$) {
      this.subscriptions.add(
        this.progressBarConfig$.subscribe((data) => {
          this.progressBarConfig = data;
          this._updateIfProgressCompleted();

          if (this.progressBarCompleted && !this.progressBarConfig.confirmOnFinish) {
            this.dialogRef?.close({
              confirmation: true,
              action: ButtonActions.CONFIRM,
              doNotAskAgain: this.checked,
            });
            this._updateIfProgressCompleted();
          }
        }),
      );
    }
    this._updateIfProgressCompleted();

    if (this.showAutoSavingState && this.savingInProgress$) {
      let autosaveTimeout: NodeJS.Timeout;

      this.subscriptions.add(
        this.savingInProgress$.subscribe((state) => {
          if (state) {
            this.autoSavingState = AutoSavingStateEnum.SAVING;
            if (autosaveTimeout) {
              clearTimeout(autosaveTimeout);
            }
            this.cDRef.detectChanges();
          } else {
            autosaveTimeout = setTimeout(() => {
              this.autoSavingState = AutoSavingStateEnum.DONE;
              this.cDRef.detectChanges();
            }, 1000);
          }
        }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public buttonPressed(button: DynamicButtonConfig): void {
    if (this.showDoNotAskAgain) {
      if (this.dialogRef) {
        this.dialogRef.close({
          confirmation: button.category === 'success',
          action: button.action,
          doNotAskAgain: this.checked,
        });
      } else {
        this.dialogModalRef?.closeDialogModalFromChildComponent?.({
          confirmation: button.category === 'success',
          action: button.action,
          doNotAskAgain: this.checked,
        });
      }
    } else {
      if (this.dialogRef) {
        this.dialogRef.close({
          confirmation: button.category === 'success',
          action: button.action,
        });
      } else {
        this.dialogModalRef?.closeDialogModalFromChildComponent?.({
          confirmation: button.category === 'success',
          action: button.action,
        });
      }
    }
  }

  private _updateIfProgressCompleted(): void {
    if (this.progressBarConfig && this.progressBarConfig.progress >= 100) {
      this.progressBarCompleted = true;
      if (this.hasAnimation) {
        this.hasAnimation = false;
      }
    }
  }
}
