import { InjectionToken } from '@angular/core';
import { CustomTag, CustomTagModelEnum } from '@remberg/custom-tags/common/main';
import { OfflineService } from '@remberg/global/ui';
import { CustomTagRawOffline } from './custom-tags-offline';

export interface CustomTagsOfflineServiceInterface
  extends OfflineService<CustomTagRawOffline, never, never> {
  findAll(model: CustomTagModelEnum): Promise<CustomTag[]>;
}

export const CUSTOM_TAGS_OFFLINE_SERVICE = new InjectionToken<CustomTagsOfflineServiceInterface>(
  'Token for injecting the custom tags offline service without circular dependencies',
);
