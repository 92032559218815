import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../store';

/**
 * Prevent access to tickets2 and cases at the same time.
 */
export const useCasesOrTickets2Guard =
  (config: { isPublic: boolean; isTickets2: boolean }): CanMatchFn =>
  async (): Promise<boolean> => {
    const store = inject(Store);
    const logger = inject(LogService);

    logger.debug()('useCasesTickets2Guard - canActivate');

    const isIonic = await firstValueFrom(store.select(GlobalSelectors.selectIsIonic));
    const hasCasesFeatureFlagEnabled = await firstValueFrom(
      store.select(GlobalSelectors.selectHasFeature(FeatureFlagEnum.CASES)),
    );
    const hasTickets2FeatureFlagEnabled = await firstValueFrom(
      store.select(GlobalSelectors.selectHasFeature(FeatureFlagEnum.TICKETS_TEMPORARY)),
    );

    return (
      !isIonic && hasCasesFeatureFlagEnabled && config.isTickets2 === hasTickets2FeatureFlagEnabled
    );
  };
